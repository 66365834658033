import Papa from "papaparse";

export const parseEmailCSV = async (file: File): Promise<string | null> => {
  return new Promise<string | null>((resolve) => {
    Papa.parse(file, {
      complete: (results) => {
        const mappedResults = results.data?.map((row: any) => row[0]);
        const joinedEmails = mappedResults?.join(", ");

        resolve(joinedEmails);
      },
    });
  });
};
