import React from "react";
import { Route } from "react-router";

import { AccountType } from "@Core/constants/ENUMS";

import {
  ArticlesDetailPage,
  ArticlesPage,
  DashboardPage,
  EmployeeQuestionnarePage,
  FilePageAdvice,
  FilePageGuidelines,
  FilePageInsight,
  FilePageReport,
  FileQuestionnairePage,
  OmissionPage,
  ProfilePage,
} from "@PNI/pages";

const getPrivateRoleRoutes = (permissions: any) => {
  const PobRoutes = [
    <Route
      key="/digitale-medewerkervragenlijst"
      path="/digitale-medewerkervragenlijst"
      exact
      component={EmployeeQuestionnarePage}
    />,
    <Route key="FPI" path="/dossiers/:file" exact component={FilePageInsight} />,
    <Route key="FPA" path="/dossiers/:file/advies" exact component={FilePageAdvice} />,
    <Route key="FPR" path="/dossiers/:file/rapportage" exact component={FilePageReport} />,
    <Route key="FPG" path="/dossiers/:file/richtlijnen" exact component={FilePageGuidelines} />,

    <Route key="FPQ" path="/dossiers/:file/vragenlijst/:questionnaire" exact component={FileQuestionnairePage} />,
  ];

  if (permissions.role === AccountType.Pob) {
    return PobRoutes;
  }

  return [];
};

export const getPrivateRoutes = (permissions: any) => {
  const routes = [
    <Route key="DP" path="/" exact component={DashboardPage} />,
    <Route key="PP" path="/mijn-profiel" exact component={ProfilePage} />,

    <Route key="AP" path="/artikelen" exact component={ArticlesPage} />,
    <Route key="ASP" path="/artikelen/:id" exact component={ArticlesDetailPage} />,

    <Route key="OP" path="/verzuim-oorzaak-scan" exact component={OmissionPage} />,
  ].concat(getPrivateRoleRoutes(permissions));

  return routes;
};
