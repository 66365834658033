import cn from "classnames";
import React from "react";

import styles from "./FileQuestions.module.scss";
import { FileQuestionsProps } from "./FileQuestions.props";

export const FileQuestions = ({ value }: FileQuestionsProps) => {
  return (
    <div className={styles.FileQuestions}>
      {value.map((question, idx) => {
        return (
          <div key={idx} className={styles.Question}>
            <h3>{question.title}</h3>

            <div className={styles.Answers}>
              {question.answers.map((answer, idx) => {
                return (
                  <div key={idx} className={styles.Answer}>
                    <i className={cn("fas fa-comment-plus", styles.Icon)} />
                    {answer}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
