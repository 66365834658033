import React from "react";
import { useTranslation } from "react-i18next";

import image_omission from "@Core/assets/images/illustrations/omission.png";
import { Block, Button } from "@Core/components/UI";

export const DashboardOmission = () => {
  const { t } = useTranslation();

  return (
    <Block name={t("DASHBOARD.VOS.TITLE")}>
      <div className="row">
        <div className="col-xl-9 col-lg-8">
          <p>{t("DASHBOARD.VOS.INTRO")}</p>

          <Button to="/verzuim-oorzaak-scan" primary>
            {t("DASHBOARD.VOS.START")}
          </Button>
        </div>

        <div className="col-xl-3 col-lg-4 hidden-md-down">
          <img src={image_omission} alt={t("DASHBOARD.VOS.TITLE")} />
        </div>
      </div>
    </Block>
  );
};
