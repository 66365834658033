import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import { Advisor, Questionnaire as CoreQuestionnaire, QuestionnaireCompleted } from "@Core/components/Components";
import { Modal } from "@Core/components/Layouts";
import { Button } from "@Core/components/UI";
import { QuestionnaireFor } from "@Core/constants/ENUMS";
import { useDialog } from "@Core/hooks";
import { QuestionnaireTokenService } from "@Core/services";
import { parseHTML } from "@Core/utilities";

import { usePNIStores } from "@PNI/stores";

import styles from "./DigitalQuestionnaireQuestionnaire.module.scss";

interface QuestionnaireProps {
  type: QuestionnaireFor;
}

export const DigitalQuestionnaireQuestionnaire = observer(({ type }: QuestionnaireProps) => {
  const { t } = useTranslation();
  const { EmployeeStore } = usePNIStores();
  const [isCompleted, setIsCompleted] = useState(false);
  const { isVisible, toggle } = useDialog();

  const onCompletion = () => {
    setIsCompleted(true);
    toast.success(t(`DIGITAL_QUESTIONNAIRE.QUESTIONNAIRE.SUCCESS`));

    QuestionnaireTokenService.logout();
  };

  if (EmployeeStore.user) {
    const employeeName = EmployeeStore.user.employee_name;

    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="mt-md mb-md">
              <h2 className="color-primary">
                {employeeName && `${employeeName} - `}
                {t(`DIGITAL_QUESTIONNAIRE.${type}.TITLE`)}
              </h2>

              {parseHTML(EmployeeStore.user.user_questionnaire?.questionnaire?.information)}

              {EmployeeStore.user.pob && (
                <>
                  <Button primary link small iconRight="fa-long-arrow-right" onClick={toggle}>
                    {t(`DIGITAL_QUESTIONNAIRE.QUESTIONNAIRE.INFORMATION_CONTACT`)}
                  </Button>

                  <Modal
                    title={t(`DIGITAL_QUESTIONNAIRE.QUESTIONNAIRE.INFORMATION_CONTACT`)}
                    isVisible={isVisible}
                    hide={toggle}
                  >
                    <Advisor card advisor={EmployeeStore.user.pob} />
                  </Modal>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">
            {isCompleted && (
              <>
                <QuestionnaireCompleted title={t(`DIGITAL_QUESTIONNAIRE.QUESTIONNAIRE.COMPLETED.INFORMATION`)} />

                <div className={styles.Description}>
                  {t(`DIGITAL_QUESTIONNAIRE.QUESTIONNAIRE.COMPLETED.INFORMATION_DESCRIPTION`)}{" "}
                  <a onClick={toggle}>{t(`DIGITAL_QUESTIONNAIRE.QUESTIONNAIRE.COMPLETED.INFORMATION_POB`)}</a>
                </div>
              </>
            )}

            <CoreQuestionnaire
              id={EmployeeStore.user.user_questionnaire.id}
              onCompletion={() => onCompletion()}
              block
              expire
            />
          </div>
        </div>
      </div>
    );
  }

  return <></>;
});
