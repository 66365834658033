import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";

import { Modal } from "@Core/components/Layouts";
import { Button, Card, Circle } from "@Core/components/UI";
import { KENNISBANK_URL } from "@Core/constants/DEFAULT";
import { useDialog } from "@Core/hooks";
import { ArticleInstance, TagInstance } from "@Core/models";
import { getKennisbankToken } from "@Core/utilities";

import styles from "./CardArticle.module.scss";

type Props = {
  article: ArticleInstance;
  readmore?: boolean;
};

const CardArticle = ({ article, readmore }: Props) => {
  const { isVisible, toggle } = useDialog();
  const { t } = useTranslation();

  const [yPos, setYPos] = useState(50);

  useEffect(() => {
    setYPos(Math.random() * (100 - 40) + 0);
  }, [setYPos]);

  const role = t(`SHARED.ACCOUNT_TYPE.${article.recommendation?.recommended_by?.account_type}`);

  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  const articleDate = new Date(article.created_on);

  const isNew = articleDate.getTime() > currentDate.getTime();

  return (
    <>
      <a
        href={`${KENNISBANK_URL}kennis/${article.theme.slug}/${article.slug}?token=${getKennisbankToken()}`}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.ArticleWrapper}
      >
        <Card className={cn([styles.Article], { [styles.WithoutReadMore]: readmore })}>
          <Circle secondary dashed x={95} y={yPos} r={150} />
          <Circle primary x={5} y={100 - yPos} r={65} />

          <h5 className={styles.Theme}>
            {isNew && <div className={styles.New}>{t("ARTICLES.NEW")}</div>}
            {article.theme.body}
            {article.exclusive && <i className="fas fa-star"></i>}
          </h5>

          {article.recommendation && (
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                toggle();
              }}
              className={styles.Recommend}
            >
              {role}
            </div>
          )}

          <h4 className={styles.Title}>{article.title}</h4>

          {!readmore && (
            <Button link secondary hover className={styles.ReadMore} iconRight="fa-long-arrow-right">
              {t("ARTICLES.READ_MORE")}
            </Button>
          )}

          <div className={styles.Tags}>
            {article.keywords &&
              article.keywords.map((keyword: TagInstance) => (
                <div key={keyword.id} className={styles.Tag}>
                  {keyword.body}
                </div>
              ))}
          </div>
        </Card>
      </a>

      {article.recommendation && (
        <Modal
          title={t("ARTICLES.RECOMMENDED_TITLE", {
            name: role,
          })}
          isVisible={isVisible}
          hide={toggle}
        >
          {article.recommendation.message
            ? article.recommendation.message
            : t("ARTICLES.RECOMMENDED_CONTENT", {
                name: role,
              })}
        </Modal>
      )}
    </>
  );
};

export default CardArticle;
