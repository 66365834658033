import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, Card, Circle, Tag } from "@Core/components/UI";

import styles from "./PreprArticleCard.module.scss";
import { PreprArticleCardProps } from "./PreprArticleCard.props";

export const PreprArticleCard = ({ article, large, small, target, className }: PreprArticleCardProps) => {
  const { t } = useTranslation();

  const [yPos, setYPos] = useState(50);

  useEffect(() => {
    setYPos(Math.random() * (100 - 40) + 0);
  }, [setYPos]);

  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  const articleDate = new Date(article._created_on);

  const isNew = articleDate.getTime() > currentDate.getTime();

  if (small) {
    return (
      <Link className={styles.ArticleSmall} to={`/artikelen/${article._slug}`} target={target}>
        <h4 className={`${styles.Title} h6`}>{article.title}</h4>

        <div className={styles.Arrow}>
          <i className="far fa-long-arrow-right" />
        </div>

        <Circle tertiary dashed x={5} y={-20} r={75} />
        <Circle primary dashed x={75} y={110} opacity={1} r={40} />
      </Link>
    );
  }

  return (
    <Card
      to={`/artikelen/${article._slug}`}
      target={target}
      className={cn(styles.Article, className, {
        [styles.isLarge]: large,
      })}
    >
      <Circle secondary={!large} dashed={!large} tertiary={large} x={0} y={20} r={100} />
      <Circle primary={!large} dashed={!large} tertiary={large} x={85} y={100 - yPos} r={40} />

      {large ? (
        <Tag className={styles.ThemeTag} large name={article.theme_pni[0].body} />
      ) : (
        <h5 className={styles.Theme}>
          {isNew && <div className={styles.New}>{t("ARTICLES.NEW")}</div>}
          {article.theme_pni[0].body}
        </h5>
      )}

      {large ? <h2 className={styles.Title}>{article.title}</h2> : <h4 className={styles.Title}>{article.title}</h4>}

      {large && <p className={styles.Intro}>{article.intro}</p>}

      <div className={styles.Footer}>
        <Button link secondary small hover className={styles.ReadMore} iconRight="fa-long-arrow-right">
          {t("ARTICLES.READ_MORE")}
        </Button>

        {article.keywords_pni && (
          <div className={styles.Tags}>
            {article.keywords_pni.map((keyword) => (
              <div key={keyword._id} className={styles.Tag}>
                {keyword.body}
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
};
