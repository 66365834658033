import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import Form from "@Core/components/Form";
import { ArticleList } from "@Core/components/HOC";
import { Modal } from "@Core/components/Layouts";
import { Button, CardArticle, Circle, Frame } from "@Core/components/UI";
import { useDialog, useMatomo } from "@Core/hooks";
import { IFieldCheckbox, MatomoCategory } from "@Core/interfaces";
import { ArticleInstance } from "@Core/models";
import { ArticleService } from "@Core/services";
import { TAG_GROUP } from "@Core/services/ArticleService";

import { usePNIStores } from "@PNI/stores";

import styles from "./RecommendedArticles.module.scss";
import { RecommendedArticlesProps } from "./RecommendedArticles.props";

export const RecommendedArticles = observer(({ className }: RecommendedArticlesProps) => {
  const { t } = useTranslation();
  const { FileStore } = usePNIStores();
  const { isVisible, toggle } = useDialog();
  const trackEvent = useMatomo();

  const [filter, setFilter] = useState(false);
  const [hasResults, setHasResults] = useState(0);
  const [article, setArticle] = useState<ArticleInstance>();
  const [articles, setArticles] = useState<ArticleInstance[]>([]);
  const [themes, setThemes] = useState<Record<string, string>>();
  const [subjects, setSubjects] = useState<IFieldCheckbox[]>();

  useEffect(() => {
    (async () => {
      const _themes = await ArticleService.getTagGroup(TAG_GROUP.THEMES);
      const _subjects = await ArticleService.getTagGroup(TAG_GROUP.SUBJECTS);

      if (_themes) {
        const _themesFormatted =
          _themes.reduce((total: Record<string, string>, theme: any) => {
            total[theme.id] = theme.body;
            return total;
          }, {}) || {};

        setThemes(_themesFormatted);
      }

      if (_subjects) {
        const _subjectsFormatted = _subjects.map((subject) => {
          return {
            id: subject.id,
            name: subject.body,
          };
        });

        setSubjects(_subjectsFormatted);
      }
    })();
  }, []);

  // Reset everything back to normal for a new initialize
  const reset = () => {
    setFilter(false);
    setArticle(undefined);
    setArticles([]);
    setHasResults(0);
  };

  // Opens/closes the popup
  const onTogglePopup = () => {
    if (!FileStore.file?.isClosed) {
      trackEvent(MatomoCategory.FileAdvice, t("FILES.RECOMMENDED_ARTICLES.ADD_ARTICLES"));

      toggle();

      setTimeout(() => {
        reset();
      }, 100);
    }
  };

  // When user selects an article
  const onArticle = (article: ArticleInstance) => {
    trackEvent(MatomoCategory.FileAdvice, t("FILES.RECOMMENDED_ARTICLES.RECOMMEND"), article.title);
    setArticle(article);
  };

  const getSearch = () => {
    let oldValues: any = undefined;

    const onChange = async (values: any) => {
      setArticles([]);
      setHasResults(0);

      if (values.searchPhrase && values.searchPhrase !== oldValues?.searchPhrase) {
        trackEvent(MatomoCategory.FileAdvice, "Artikel filteren (Zoeken)", values.searchPhrase);
      }

      if (values.tags && values.tags !== oldValues?.tags && subjects) {
        trackEvent(
          MatomoCategory.FileAdvice,
          "Artikel filteren (Onderwerpen)",
          values.tags.map((tag: string) => subjects.find((subject) => subject.id === tag)?.name).join(", "),
        );
      }

      if (values.theme && values.theme !== oldValues?.theme && themes) {
        trackEvent(MatomoCategory.FileAdvice, "Artikel filteren (Thema)", themes[values.theme]);
      }

      const response = await ArticleService.searchArticles(values, !!FileStore.file?.supervisor.has_account);

      if (response && response.length > 0) {
        setArticles(response);
        setHasResults(2);
      } else {
        setArticles([]);
        setHasResults(1);
      }

      oldValues = values;
    };

    return (
      <>
        <p>{t("FILES.RECOMMENDED_ARTICLES.RECOMMEND_ARTICLE")}</p>

        <Form.Form identifier="ARTICLE_SEARCH_FORM" onChange={onChange} submit="false">
          <Form.Item
            id="searchPhrase"
            name={t("FILES.RECOMMENDED_ARTICLES.SEARCH")}
            render={(props) => <Form.Text {...props} />}
          />

          {filter && (
            <>
              {themes && (
                <Form.Item
                  id="theme"
                  name={t("FILES.RECOMMENDED_ARTICLES.THEMES")}
                  render={(props) => (
                    <Form.Select
                      placeholder={t("FILES.RECOMMENDED_ARTICLES.SELECT_THEMES")}
                      options={themes}
                      {...props}
                    />
                  )}
                />
              )}

              {subjects && (
                <Form.Item
                  id="tags"
                  name={t("FILES.RECOMMENDED_ARTICLES.SUBJECTS")}
                  render={(props) => <Form.Checkbox options={subjects} {...props} />}
                />
              )}
            </>
          )}

          <Button
            primary
            block
            onClick={() => setFilter((old) => !old)}
            data-matomo-category={MatomoCategory.FileAdvice}
          >
            {t(`FILES.RECOMMENDED_ARTICLES.${!filter ? "SHOW_FILTER" : "HIDE_FILTER"}`)}
          </Button>
        </Form.Form>

        {hasResults === 1 && (
          <div className={styles.ArticlesSearch}>
            <strong>{t("FILES.RECOMMENDED_ARTICLES.SEARCH_NONE")}</strong>
          </div>
        )}

        {hasResults === 2 && (
          <div className={styles.ArticlesSearch}>
            <ArticleList articles={articles} items={2} pagination={false} onClick={onArticle} />
          </div>
        )}
      </>
    );
  };

  const getArticle = () => {
    const onSubmit = async (values: any) => {
      if (article && FileStore.file) {
        await ArticleService.recommendArticle(article?.id, FileStore.file?.id, values.message);
        await FileStore.getFile(FileStore.file.id.toString(), true);

        trackEvent(MatomoCategory.FileAdvice, t("FILES.RECOMMENDED_ARTICLES.ADDED"), article.title);
      }
    };

    const onSuccess = () => {
      onTogglePopup();
      toast.success(t("FILES.RECOMMENDED_ARTICLES.ADDED"));
    };

    if (article) {
      return (
        <div>
          <CardArticle article={article} readmore />

          <Form.Form
            className="mt-md"
            identifier="ARTICLE_FORM"
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            submit={t("FILES.RECOMMENDED_ARTICLES.ADD_ARTICLE")}
          >
            <Form.Item
              id="message"
              name={t("FILES.RECOMMENDED_ARTICLES.MESSAGE")}
              render={(props) => <Form.Textarea {...props} />}
            />
          </Form.Form>

          <Button
            className="mt-sm"
            block
            primary
            onClick={() => setArticle(undefined)}
            data-matomo-category={MatomoCategory.FileAdvice}
          >
            {t("FILES.RECOMMENDED_ARTICLES.OHTER_ARTICLE")}
          </Button>
        </div>
      );
    }
  };

  if (FileStore.file) {
    return (
      <>
        <Frame title={t("FILES.RECOMMENDED_ARTICLES.TITLE")} className={cn(styles.Articles, className)}>
          <Frame.Body padding="3">
            {FileStore.file.article_recommendations.length > 0 && FileStore.file.article_recommendation_enabled ? (
              <ArticleList articles={FileStore.file.article_recommendations} items={4} />
            ) : (
              <div
                className={cn([styles.Placeholder], {
                  [styles.PlaceholderClosed]: FileStore.file.isClosed,
                })}
                onClick={onTogglePopup}
              >
                <Circle secondary dashed x={20} y={10} r={100} />
                <Circle primary dashed x={80} y={80} r={40} />

                <div className={styles.PlaceholderIcon}>
                  <i className="far fa-plus"></i>
                </div>

                {t("FILES.RECOMMENDED_ARTICLES.SELECT_FIRST")}
              </div>
            )}

            {!FileStore.file.article_recommendation_enabled && (
              <div className={styles.Overlay}>
                <Frame elevated="large">
                  <Frame.Body padding="3">
                    <h3>{t("FILES.RECOMMENDED_ARTICLES.EMPTY.TITLE")}</h3>

                    <Frame className="my-smd" variant="light">
                      <Frame.Body padding="2">
                        <p className="small mb-0 color-black-light">
                          {t("FILES.RECOMMENDED_ARTICLES.EMPTY.DESCRIPTION")}
                        </p>
                      </Frame.Body>
                    </Frame>
                  </Frame.Body>
                </Frame>
              </div>
            )}
          </Frame.Body>

          {!FileStore.file?.isClosed && FileStore.file.article_recommendation_enabled && (
            <>
              <Frame.Expand onClick={onTogglePopup}>
                <Button tertiary className="ml-auto" onClick={onTogglePopup}>
                  {t("FILES.RECOMMENDED_ARTICLES.ADD_ARTICLES")}
                </Button>
              </Frame.Expand>

              <Modal
                title={t("FILES.RECOMMENDED_ARTICLES.ADD_ARTICLES")}
                large
                isVisible={isVisible}
                hide={toggle}
                data-matomo-category={MatomoCategory.FileAdvice}
              >
                {article ? getArticle() : getSearch()}
              </Modal>
            </>
          )}
        </Frame>
      </>
    );
  }

  return <></>;
});
