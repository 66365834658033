import { flow, Instance, SnapshotIn, types } from "mobx-state-tree";

import { FileStepType } from "@Core/constants/ENUMS";
import { FileService } from "@Core/services";

import { FileQuestionnairesModel } from "./FileQuestionnairesModel";

export const FileStepModel = types
  .model("FileStepModel", {
    id: types.identifierNumber,
    type: types.enumeration<FileStepType>(Object.values(FileStepType)),
    title: types.string,
    description: types.maybeNull(types.string),
    selected: types.boolean,
    selected_at: types.maybeNull(types.string),
    recommended: types.boolean,
    file_questionnaire: types.maybeNull(FileQuestionnairesModel),
    aom_file_id: types.number,
  })
  .actions((self) => ({
    remind: flow(function* () {
      try {
        yield FileService.remindStep(self.aom_file_id, self.id);

        if (self.file_questionnaire) {
          self.file_questionnaire.mail_id = "UNKNOWN";
        }
      } catch (e) {
        throw new Error(e);
      }
    }),
    toggle: flow(function* () {
      try {
        self.selected = !self.selected;
        self.selected_at = self.selected ? new Date().toISOString() : null;

        yield FileService.checkStep(self.aom_file_id, self.id);
      } catch (e) {
        throw new Error(e);
      }
    }),
  }));

export interface FileStepInstance extends Instance<typeof FileStepModel> {}
export type FileStepSnapshot = SnapshotIn<typeof FileStepModel>;
