export enum MODEL {
  ARTICLE = "Artikel",
  ARTICLES = "Artikels",
}

export interface GraphqlRequest<T> {
  data: T;
}

export type PreprSingleResponse<T> = T;

export interface PreprCollectionResponse<T> {
  total: number;
  items: T[];
}

export interface IPreprTag {
  _id: string;
  _type: string;
  body: string;
  slug: string;
}

export interface IPreprModel {
  _id: string;
  _slug: string;
  __typename: MODEL;
  _created_on: string;
}

export interface IPreprAsset {
  name: string;
  author?: string;
  description?: string;
  _type: "Audio" | "Photo" | "Video";
  cdn_files: {
    _id: string;
    url: string;
    full: string;
  }[];
}

export interface IPreprElement {
  _id: string;
  _type: "Text" | "SpotifyPlaylist" | "Quote" | "Assets" | "Text met bestand";
  format?: string;
}

export interface IPreprElementText extends IPreprElement {
  body: string;
}

export interface IPreprElementTextWithFile extends IPreprElement {
  text: string;
  url: {
    _id: string;
    _type: string;
    body: string;
    url: string;
    type: string;
  };
}

export interface IPreprElementSpotify extends IPreprElement {
  spotifyUrl: string;
}

export interface IPreprElementQuote extends IPreprElement {
  body: string;
  author: string;
}

export interface IPreprElementAssets extends IPreprElement {
  items: IPreprAsset[];
}

export type IPreprDragDrop = Array<
  IPreprElementText | IPreprElementTextWithFile | IPreprElementSpotify | IPreprElementQuote | IPreprElementAssets
>;
