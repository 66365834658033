import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { ArticleModel } from "@Core/models";

export const PollAnswerModel = types.model({
  id: types.number,
  contents: types.string,
  results: types.number,
  selected: types.boolean,
  articles: types.maybeNull(types.array(ArticleModel)),
});

export interface PollAnswerInstance extends Instance<typeof PollAnswerModel> {}
export interface PollAnswerSnapshot extends SnapshotIn<typeof PollAnswerModel> {}
