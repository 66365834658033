import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Modal } from "@Core/components/Layouts";
import { useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";

import { ConnectSupervisorForm, ConnectSupervisorFormValues } from "@PNI/forms";
import { usePNIStores } from "@PNI/stores";

import { FilePageHeaderSupervisorModalProps } from "./FilePageHeaderSupervisorModal.props";

export const FilePageHeaderSupervisorModal = ({ isVisible, toggle }: FilePageHeaderSupervisorModalProps) => {
  const { t } = useTranslation();
  const { FileStore } = usePNIStores();
  const trackEvent = useMatomo();

  if (FileStore.file && FileStore.file.employee) {
    const handleSubmit = async (values: ConnectSupervisorFormValues) => {
      try {
        await FileStore.connectSupervisor(values);
        await FileStore.load();

        trackEvent(MatomoCategory.File, t("FILES.MODALS.CONNECT_SUPERVISOR.SUCCESS"));

        toggle();

        toast.success(t("FILES.MODALS.CONNECT_SUPERVISOR.SUCCESS"));
      } catch (e) {
        toast.error(t("FILES.MODALS.CONNECT_SUPERVISOR.ERROR"));
      }
    };

    return (
      <Modal
        title={t("FILES.MODALS.CONNECT_SUPERVISOR.POPUP_TITLE")}
        isVisible={isVisible}
        hide={toggle}
        data-matomo-category={MatomoCategory.File}
      >
        <ConnectSupervisorForm initialValues={FileStore.file.supervisor_data} onSubmit={handleSubmit} />
      </Modal>
    );
  }

  return <></>;
};
