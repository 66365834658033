import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";

import { Block } from "@Core/components/UI";
import { ProfileForm } from "@Core/forms";
import { ProfileFormValues } from "@Core/forms/ProfileForm/ProfileForm.props";
import { TagSnapshot } from "@Core/models";
import { ArticleService, UserService } from "@Core/services";
import { useCoreStores } from "@Core/stores";

import { Page } from "@LNI/components/Layouts";
import { ChallengesForm, MomentsForm, TagsForm } from "@LNI/forms";
import { MomentsFormValues } from "@LNI/forms/MomentsForm/MomentsForm.props";
import { OnboardingService } from "@LNI/services";
import { useLNIStores } from "@LNI/stores";

const ProfilePageProfileForm = () => {
  const { t } = useTranslation();
  const { UserStore } = useCoreStores();

  if (UserStore.user) {
    const initialValues: ProfileFormValues = {
      email: UserStore.user.email,
      first_name: UserStore.user.first_name,
      last_name: UserStore.user.last_name,
      password: "",
      password_confirmation: "",
      phone_number: UserStore.user.phone_number,
    };
    const onSubmit = async (values: ProfileFormValues) => {
      const response = await UserService.save(values);
      UserStore?.update(values);

      return response;
    };

    return (
      <Block name={t("SHARED.MY_PROFILE")}>
        <ProfileForm initialValues={initialValues} onSubmit={onSubmit} />
      </Block>
    );
  }

  return <></>;
};

const ProfilePageChallengesForm = () => {
  const { t } = useTranslation();
  const { OnboardingStore, ChallengeStore } = useLNIStores();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    OnboardingStore.getChallenges().then(() => {
      setLoaded(true);
    });
  }, [OnboardingStore]);

  if (loaded && OnboardingStore.challenges) {
    const challenges = OnboardingStore.challenges.map((challenge: any): any => {
      return { id: String(challenge.id), name: challenge.title, checked: challenge.selected };
    });

    const initialValues = {
      challenges: challenges
        .map((challenge: any) => (challenge.checked ? challenge.id.toString() : undefined))
        .filter((value) => value),
    };

    const onSubmit = async (values: any) => {
      await OnboardingService.setChallenges(values.challenges);
      ChallengeStore.load();
    };

    return (
      <Block name={t("PROFILE.MY_CHALLENGES")}>
        {OnboardingStore.challenges && (
          <ChallengesForm initialValues={initialValues} challenges={challenges} onSubmit={onSubmit} />
        )}
      </Block>
    );
  }

  return <></>;
};

const ProfilePageTagsForm = () => {
  const { t } = useTranslation();
  const { ArticleStore } = useLNIStores();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    ArticleStore.getTags().then(() => {
      setLoaded(true);
    });
  }, [ArticleStore]);

  if (loaded && ArticleStore.hasTags) {
    const tags = ArticleStore.tags.map((tag: TagSnapshot) => {
      return { id: tag.id, name: tag.body, checked: tag.selected };
    });

    const initialValues = {
      tags: tags.map((tag: any) => (tag.checked ? tag.id.toString() : undefined)).filter((value: any) => value),
    };

    const onSubmit = async (values: any) => {
      await ArticleService.setTags(values.tags);
      ArticleStore.load();
    };

    return (
      <Block name={t("PROFILE.MY_SUBJECTS")}>
        {ArticleStore.hasTags && <TagsForm initialValues={initialValues} tags={tags} onSubmit={onSubmit} />}
      </Block>
    );
  }

  return <></>;
};

const ProfilePageMomentsForm = () => {
  const { t } = useTranslation();
  const { MomentStore } = useLNIStores();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    MomentStore.getPreferences().then(() => {
      setLoaded(true);
    });
  }, [MomentStore]);

  if (loaded && MomentStore.hasPreferences) {
    const initialValues = {
      moment_interval: MomentStore.preferences?.moment_interval ? MomentStore.preferences?.moment_interval : "",
    };

    const onSubmit = async (values: MomentsFormValues) => {
      await MomentStore.setPreferences(values.moment_interval);
    };

    return (
      <Block name={t("PROFILE.MY_MOMENT")}>
        {MomentStore.hasPreferences && <MomentsForm initialValues={initialValues} onSubmit={onSubmit} />}
      </Block>
    );
  }

  return <></>;
};

const ProfilePage = () => {
  const { t } = useTranslation();
  const { MomentStore, OnboardingStore } = useLNIStores();

  const breadcrumbs = [{ name: t("SHARED.MY_PROFILE") }];

  return (
    <Page breadcrumbs={breadcrumbs}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <ProfilePageProfileForm />
          </div>

          <div className="col-lg-4">
            <div className="row">
              {MomentStore.hasPreferences && (
                <div className="col-lg-12 col-md-6">
                  <ProfilePageMomentsForm />
                </div>
              )}

              {OnboardingStore.progress?.didOnboarding && (
                <div className="col-lg-12 col-md-6">
                  <ProfilePageChallengesForm />
                </div>
              )}

              {OnboardingStore.progress?.didOnboarding && (
                <div className="col-lg-12 col-md-6">
                  <ProfilePageTagsForm />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default observer(ProfilePage);
