import React from "react";
import ReactPlayer from "react-player";
import { BaseReactPlayerProps } from "react-player/base";

import styles from "./Video.module.scss";

const Video = ({ ...props }: BaseReactPlayerProps): JSX.Element => {
  return (
    <div className={styles.Wrapper}>
      <ReactPlayer {...props} />
    </div>
  );
};

export default Video;
