import axios from "@Core/axios";

import BaseService from "./BaseService";

class ValidationService extends BaseService<void> {
  validateEmployeeEmail = async (email: string): Promise<any | null> => {
    const data = {
      value: email,
    };

    const response = await axios.post(`${this.endpoint}/employee/email`, data);

    if (response) {
      return response.data;
    }
  };

  validateUserEmail = async (email: string): Promise<any | null> => {
    const data = {
      value: email,
    };

    const response = await axios.post(`${this.endpoint}/user/email`, data);

    if (response) {
      return response.data;
    }
  };
}

export default new ValidationService("validation");
