import { useMatomo } from "@datapunt/matomo-tracker-react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next/";
import LazyLoad from "react-lazyload";

import { Block, Button, Circle } from "@Core/components/UI";
import { DataLayer } from "@Core/constants/ENUMS";
import { UserInstance } from "@Core/models/BaseUserModel";
import { ContactInstance } from "@Core/models/ContactModel";
import { useCoreStores } from "@Core/stores";
import { getImage, openLink } from "@Core/utilities";

import styles from "./Advisor.module.scss";

type Props = {
  title?: string;
  advisor: UserInstance | ContactInstance;
  imageLarge?: boolean;
  buttonColor?: string;
  showBackgroundCircle?: boolean;
  card?: boolean;
};

const Advisor = ({ title, advisor, imageLarge, showBackgroundCircle, card }: Props) => {
  const { t } = useTranslation();
  const { UserStore } = useCoreStores();
  const { trackEvent } = useMatomo();

  const isContact = (input: UserInstance | ContactInstance): input is ContactInstance => {
    return "function" in input;
  };

  const handlePhone = useCallback(
    (phoneNumber: string) => () => {
      trackEvent({
        name: `${advisor.getName} (${advisor.email}) gemaild`,
        action: DataLayer.ADVISOR,
        category: String(UserStore.getCompanyId),
      });

      openLink(`tel:${phoneNumber}`);
    },
    [],
  );

  const handleEmail = useCallback(
    (email: string) => () => {
      trackEvent({
        name: `${advisor.getName} (${advisor.phone_number}) gebeld`,
        action: DataLayer.ADVISOR,
        category: String(UserStore.getCompanyId),
      });

      openLink(`mailto:${email}`);
    },
    [],
  );

  return (
    <Block
      stretch
      name={isContact(advisor) ? advisor.function : title || t(`SHARED.ACCOUNT_TYPE.${advisor.account_type}`)}
      card={card}
      className={styles.Block}
    >
      {showBackgroundCircle && <Circle dashed x={90} y={95} r={200} secondary />}

      <div className={styles.Wrapper}>
        <div
          className={cn([styles.Profile], {
            [styles.ProfileLarge]: imageLarge,
          })}
        >
          <LazyLoad height={imageLarge ? 130 : 64}>
            <div className={styles.Image}>
              {advisor.avatar && <img src={getImage(advisor.avatar)} alt={advisor.getName} className={styles.Icon} />}

              {!advisor.avatar && (
                <div className={styles.Icon}>
                  <i className="far fa-user"></i>
                </div>
              )}

              <Circle quaternary dashed={imageLarge} r={imageLarge ? 108 : 64} className={styles.Circle} />
            </div>
          </LazyLoad>

          <h4>{advisor.getName}</h4>
        </div>

        <div className={styles.Buttons}>
          {advisor.phone_number && (
            <Button
              iconLeft="fa-phone"
              onClick={handlePhone(advisor.phone_number)}
              tertiary
              className="justify-content-center flex-fill mr-xs mt-xs"
            >
              {`${t("ADVISOR.CALL")} ${advisor.phone_number}`}
            </Button>
          )}

          {advisor.email && (
            <Button
              iconLeft="fa-envelope"
              onClick={handleEmail(advisor.email)}
              tertiary
              className="justify-content-center flex-fill mr-xs mt-xs"
            >
              {t("ADVISOR.MAIL")}
            </Button>
          )}
        </div>
      </div>
    </Block>
  );
};

export default observer(Advisor);
