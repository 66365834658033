import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Frame } from "@Core/components/UI";
import { FileStepType } from "@Core/constants/ENUMS";
import { useBoolean } from "@Core/hooks";

import { StepList } from "@PNI/components/HOC";
import { usePNIStores } from "@PNI/stores";

import { OtherStepsProps } from "./OtherSteps.props";

export const OtherSteps = observer(({ className }: OtherStepsProps) => {
  const { t } = useTranslation();
  const { FileStore } = usePNIStores();
  const limit = 6;
  const { value, toggle } = useBoolean();

  const steps = FileStore.file?.getAdviceSteps.filter((step) => step.recommended === false) || [];

  const stepsQuestionaires = steps
    .filter((step) => step.type === FileStepType.QUESTIONNAIRE)
    .slice(0, value === true ? undefined : limit);

  const stepsOther = steps
    .filter((step) => step.type === FileStepType.STEP)
    .slice(0, value === true ? undefined : limit - stepsQuestionaires.length);

  return (
    <Frame
      title={t("FILES.OTHER_STEPS.TITLE")}
      className={className}
      variant={steps.length === 0 ? "light" : undefined}
    >
      <Frame.Body padding="3">
        {steps.length === 0 ? (
          <p className="small mb-0 color-black-light">{t("FILES.OTHER_STEPS.EMPTY")}</p>
        ) : (
          <>
            {stepsQuestionaires.length > 0 && (
              <div className={stepsOther.length > 0 ? "mb-md" : undefined}>
                <h5 className="subtitle mb-0">{t("FILES.OTHER_STEPS.QUESTIONNAIRES")}</h5>
                <StepList steps={stepsQuestionaires} hasToggle />
              </div>
            )}

            {stepsOther.length > 0 && (
              <div>
                <h5 className="subtitle mb-0">{t("FILES.OTHER_STEPS.OTHER")}</h5>
                <StepList steps={stepsOther} hasToggle />
              </div>
            )}
          </>
        )}
      </Frame.Body>

      {steps.length > limit && (
        <Frame.Expand onClick={toggle}>
          {value ? t("FILES.OTHER_STEPS.VIEW_LESS") : t("FILES.OTHER_STEPS.VIEW_ALL", { count: steps.length })}
        </Frame.Expand>
      )}
    </Frame>
  );
});
