import { flow, Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel } from "@Core/models";

import { InboxService } from "@PNI/services";

export const MessageModel = BaseModel.props({
  title: types.string,
  message: types.string,
  is_read: types.boolean,
}).actions((self) => ({
  read: flow(function* () {
    yield InboxService.readMessage(self.id);
    self.is_read = true;
  }),
}));

export interface MessageInstance extends Instance<typeof MessageModel> {}
export type MessageSnapshot = SnapshotIn<typeof MessageModel>;
