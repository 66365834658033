import cn from "classnames";
import React from "react";

import { Video } from "@Core/components/Components";

import styles from "./PreprRendererAsset.module.scss";
import { PreprRendererAssetProps } from "./PreprRendererAsset.props";

export const PreprRendererAsset = ({ element }: PreprRendererAssetProps): JSX.Element => {
  const asset = element.items[0];

  if (!asset) return <></>;

  return (
    <div className={cn(styles.PreprRendererAsset, "row")}>
      <div className="col-xl-3 hidden-lg-down"></div>

      <div className="col-xl-6 col-lg-9 col-md-12">
        {asset._type === "Audio" && (
          <audio controls>
            <source src={asset.cdn_files[0].url} type="audio/mpeg" />
          </audio>
        )}

        {asset._type === "Photo" && <img src={asset.cdn_files[0].url} />}

        {asset._type === "Video" && <Video url={asset.cdn_files[0].url} controls />}
      </div>
    </div>
  );
};
