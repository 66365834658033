import React, { useState } from "react";
import { useTranslation } from "react-i18next/";

import Form from "@Core/components/Form";
import { Button } from "@Core/components/UI";
import { MatomoCategory } from "@Core/interfaces";
import Yup from "@Core/validation";

import Props, { ProfileFormValues } from "./ProfileForm.props";

const ProfileForm = ({ initialValues, onSubmit, onSuccess }: Props) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    // Accountgegevens
    email: Yup.string().required().email(),
    password: password ? Yup.string().password().required() : Yup.string(),
    password_confirmation: password ? Yup.string().password("password").required() : Yup.string(),

    // Persoonlijke gegevens
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    phone_number: Yup.string().phone().required(),
  });

  const onTogglePassword = () => {
    const oldPassword = password;
    setPassword(!oldPassword);
  };

  const onProfileSubmit = (values: ProfileFormValues) => {
    const data = { ...values };

    if (!password) {
      delete data["password"];
    }

    return onSubmit(data);
  };

  return (
    <Form.Form
      identifier="PROFILE_FORM"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onProfileSubmit}
      onSuccess={onSuccess}
      submit={t("FORMS.PROFILE.CHANGE")}
      data-matomo-category={MatomoCategory.Profile}
    >
      <Form.Group name={t("FORMS.PROFILE.ACCOUNT_DATA")}>
        <Form.Item
          id="email"
          name={t("FORMS.PROFILE.EMAIL")}
          description={t("FORMS.PROFILE.EMAIL_DESCRIPTION")}
          render={(props) => <Form.Text disabled {...props} icon="envelope" />}
        />

        <Form.Item
          id="phone_number"
          name={t("FORMS.PROFILE.PHONE")}
          description={t("FORMS.PROFILE.PHONE_DESCRIPTION")}
          render={(props) => <Form.Text {...props} placeholder="0612345678" icon="phone" />}
        />

        <Button primary onClick={onTogglePassword} data-matomo-category={MatomoCategory.Profile}>
          {t("FORMS.PROFILE.CHANGE_PASSWORD")}
        </Button>

        {password && (
          <div className="mt-sm">
            <Form.Item
              id="password"
              name={t("FORMS.PROFILE.PASSWORD")}
              description={t("FORMS.STRING_PASSWORD_DESCRIPTION")}
              render={(props) => <Form.Password {...props} />}
            />

            <Form.Item
              id="password_confirmation"
              name={t("FORMS.PROFILE.CONFIRM_PASSWORD")}
              render={(props) => <Form.Password {...props} />}
            />
          </div>
        )}
      </Form.Group>

      <Form.Group name={t("FORMS.PROFILE.PERSONAL_DATA")}>
        <Form.Item
          id="first_name"
          name={t("FORMS.PROFILE.FIRST_NAME")}
          render={(props) => <Form.Text {...props} icon="user-alt" />}
        />

        <Form.Item
          id="last_name"
          name={t("FORMS.PROFILE.LAST_NAME")}
          render={(props) => <Form.Text {...props} icon="user-alt" />}
        />
      </Form.Group>
    </Form.Form>
  );
};

export default ProfileForm;
