import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const TagModel = types.model({
  id: types.identifier,
  body: types.string,
  slug: types.string,
  selected: types.maybe(types.boolean),
});

export interface TagInstance extends Instance<typeof TagModel> {}
export type TagSnapshot = SnapshotIn<typeof TagModel>;
