import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Login } from "@Core/components/Layouts";
import { Button } from "@Core/components/UI";
import { RegisterForm, RegisterFormValues } from "@Core/forms";
import { useQueryParams } from "@Core/hooks";
import { RegistrationService } from "@Core/services";
import { ValidateTokenResponse } from "@Core/services/RegistrationService";
import { getImage } from "@Core/utilities";

const Register = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const params = useQueryParams();
  const token = params.get("token");

  const [tokenResponse, setTokenResponse] = useState<ValidateTokenResponse>();

  if (!token) {
    history.push("/");
    return null;
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await RegistrationService.validateToken(token);
        setTokenResponse(response);
      } catch (e) {
        history.push("/");
      }
    })();
  }, [token, history]);

  const handleSubmit = useCallback((values: RegisterFormValues) => {
    return RegistrationService.registerComplete(values);
  }, []);

  const handleSuccess = useCallback(() => {
    toast.success(t("REGISTER.REGISTER.SUCCESS"));
    history.push("/inloggen");
  }, []);

  const handleResend = useCallback(() => {
    history.push("/uitnodiging-aanvragen");
  }, []);

  if (tokenResponse) {
    const isValid = tokenResponse.is_valid && tokenResponse.payload;
    const companyLogo = tokenResponse.payload?.company?.logo_path
      ? getImage(tokenResponse.payload.company.logo_path)
      : undefined;

    return (
      <Login
        title={isValid ? t("REGISTER.REGISTER.TITLE") : t("REGISTER.EXPIRED.TITLE")}
        description={
          isValid
            ? t("REGISTER.REGISTER.SUBTITLE", {
                platform: t("DEFAULT.SITE_NAME"),
              })
            : t("REGISTER.EXPIRED.SUBTITLE", {
                platform: t("DEFAULT.SITE_NAME"),
              })
        }
        logo={companyLogo}
      >
        {isValid && tokenResponse.payload ? (
          <RegisterForm
            token={token}
            email={tokenResponse.payload.email}
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
          />
        ) : (
          <div>
            <p>{t("REGISTER.EXPIRED.DESCRIPTION")}</p>

            <Button onClick={handleResend} primary>
              {t("REGISTER.EXPIRED.REQUEST_NEW_INVITE")}
            </Button>
          </div>
        )}
      </Login>
    );
  }

  return null;
};

export default Register;
