import { flow, Instance, types } from "mobx-state-tree";

import { UserQuestionnaireStatus } from "@Core/constants/ENUMS";
import { QuestionnaireTokenService } from "@Core/services";
import { ValidateUserRequest } from "@Core/services/QuestionnaireTokenService";

import { UserModel } from "@PNI/models";

const EmployeeStore = types
  .model({
    token: types.maybeNull(types.string),
    introduction: false,
    user: types.maybeNull(UserModel),
  })
  .actions((self) => ({
    login: flow(function* (request: ValidateUserRequest) {
      yield QuestionnaireTokenService.validateUser(request);
    }),

    load: flow(function* () {
      const response = yield QuestionnaireTokenService.me();

      if (response) {
        self.user = response;
      }
    }),

    clear: () => {
      self.user = null;
    },
  }))
  .views((self) => ({
    get didIntroduction(): boolean {
      return self.user?.user_questionnaire.status !== UserQuestionnaireStatus.Pending;
    },
  }));

export interface EmployeeStoreInstance extends Instance<typeof EmployeeStore> {}

export default EmployeeStore;
