export enum Platform {
  NONE = "NONE",
  LNI = "LNI",
  OOM = "OOM",
  PNI = "PNI",
}

export enum FileType {
  PREVENTIVE = "PREVENTIVE",
  ABSENCE = "ABSENCE",
}

export enum AccountType {
  User = "App\\Models\\User",
  Admin = "App\\Models\\Admin",
  Pob = "App\\Models\\Pob",
  Advisor = "App\\Models\\Advisor",
  CompanyDoctor = "App\\Models\\CompanyDoctor",
  AccountManager = "App\\Models\\AccountManager",
  HR = "App\\Models\\HumanResources",
}

export enum FileStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  CLOSED_AND_ANONYMIZED = "CLOSED_AND_ANONYMIZED",
}

export enum FileGuidelineType {
  PDF = "PDF",
  LINK = "Link",
  TEXT = "Text",
}

export enum FileRecommendedAbsenceType {
  SICK_LEAVE = "SICK_LEAVE",
  MATERNITY_LEAVE = "MATERNITY_LEAVE",
  CHILDBIRTH_LEAVE = "CHILDBIRTH_LEAVE",
  PARENTAL_LEAVE = "PARENTAL_LEAVE",
  ADOPTION_FOSTER_LEAVE = "ADOPTION_FOSTER_LEAVE",
  CARE_LEAVE = "CARE_LEAVE",
  EMERGENCY_LEAVE = "EMERGENCY_LEAVE",
  SPECIAL_LEAVE = "SPECIAL_LEAVE",
  NONE = "NONE",
}

export enum FileStepType {
  EMPLOYEE_QUESTIONNAIRE = "EMPLOYEE_QUESTIONNAIRE",
  SUPERVISOR_QUESTIONNAIRE = "SUPERVISOR_QUESTIONNAIRE",
  QUESTIONNAIRE = "QUESTIONNAIRE",
  STEP = "STEP",
}

export enum QuestionnaireFor {
  EMPLOYEE = "EMPLOYEE",
  SUPERVISOR = "SUPERVISOR",
  FILE_MANAGER = "FILE_MANAGER",
}

export enum UserQuestionnaireStatus {
  Open = "OPEN",
  Pending = "PENDING",
  InProgress = "IN_PROGRESS",
  PendingCompletion = "PENDING_COMPLETION",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED",
}

export enum UserQuestionnaireStepType {
  QUESTION = "QUESTION",
  SCORE = "SCORE",
  CONTENT = "CONTENT",
}

export enum UserQuestionnaireQuestionType {
  Single = "single",
  SingleDropdown = "single_dropdown",
  Multiple = "multiple",
  Open = "open",
  OpenShort = "open_short",
  Number = "number",
  Date = "date",
  Statement = "statement",
  Scale = "scale",
  ScaleEmoji = "scale_emoji",
  ScaleSlider = "scale_slider",
}

export enum PreprLabel {
  TEXT = "Text",
  QUOTE = "Quote",
  ASSET = "Asset",
  PUBLICATIONELEMENT = "PublicationElement",
  PUBLICATION = "Publication",
  SPOTIFY = "SpotifyPlaylist",
}

export enum CompanySearchType {
  EMPLOYEE = "EMPLOYEE",
  SUPERVISOR = "SUPERVISOR",
}

export enum DataLayer {
  ADVISOR = "Contactpersoon",
  INBOX = "Berichtenbox",
  ARTICLE_READ = "Artikel gelezen",
  THEME_READ = "Thema gelezen",
  KEYWORD_READ = "Onderwerp gelezen",
  THEME_SEARCH = "Thema gezocht",
  KEYWORD_SEARCH = "Onderwerp gezocht",
}

export enum AppError {
  ARTICLE_NOT_FOUND = "ARTICLE_NOT_FOUND",
  REGISTRATION_TOKEN_EXPIRED = "REGISTRATION_TOKEN_EXPIRED",
  REGISTRATION_TOKEN_INVALID = "REGISTRATION_TOKEN_INVALID",
}

export enum ExportType {
  FILES = "files",
  VOS = "vos",
}

export enum ApiError {
  VERIFICATION_CODE_COOLDOWN = "VERIFICATION_CODE_COOLDOWN",
}
