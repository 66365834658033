import Card from "./Card/Card";
import Date from "./Date/Date";
import Multiple from "./Multiple/Multiple";
import Number from "./Number/Number";
import Open from "./Open/Open";
import OpenShort from "./OpenShort/OpenShort";
import Question from "./Question/Question";
import Scale from "./Scale/Scale";
import ScaleEmoji from "./ScaleEmoji/ScaleEmoji";
import ScaleSlider from "./ScaleSlider/ScaleSlider";
import Single from "./Single/Single";
import SingleDropdown from "./SingleDropdown/SingleDropdown";
import Statement from "./Statement/Statement";
import Tag from "./Tag/Tag";

export default {
  Multiple,
  Open,
  OpenShort,
  Number,
  Scale,
  ScaleSlider,
  ScaleEmoji,
  Single,
  SingleDropdown,
  Question,
  Card,
  Tag,
  Date,
  Statement,
};
