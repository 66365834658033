import cn from "classnames";
import React, { ReactNode } from "react";

import { Modal } from "@Core/components/Layouts";
import { useDialog } from "@Core/hooks";

import styles from "./InfoModal.module.scss";

interface InfoModalProps {
  variant?: "primary" | "danger";
  icon?: "info-circle" | string;
  title: string;
  className?: string;
  children: ReactNode;
}

const InfoModal = ({ className, children, variant = "primary", icon = "info-circle", title }: InfoModalProps) => {
  const { isVisible, toggle } = useDialog();

  return (
    <>
      <i
        className={cn(styles.InfoModal, `fas fa-${icon}`, className, {
          [styles[`InfoModal--variant-${variant}`]]: !!variant,
        })}
        onClick={toggle}
      />

      <Modal title={title} isVisible={isVisible} hide={toggle}>
        {children}
      </Modal>
    </>
  );
};

export default InfoModal;
