import { addDays } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next/";
import { useParams } from "react-router";

import Form from "@Core/components/Form";
import { Block, Circle } from "@Core/components/UI";
import { useCoreStores } from "@Core/stores";

import { Page } from "@OOM/components/Layouts";
import ExportService from "@OOM/services/ExportService";
import { useOOMStores } from "@OOM/stores";

import styles from "./ScorecardPage.module.scss";

type Params = {
  companyRoleId: string | undefined;
};

interface ExportCompanyRoleFormValues {
  companyRole: string;
}

const ScorecardPage = () => {
  const { t } = useTranslation();
  const { UserStore } = useCoreStores();
  const { RoleStore } = useOOMStores();
  const { companyRoleId } = useParams<Params>();

  const getCompanyRoleOptions = useCallback(() => {
    return RoleStore.companyRoles.reduce(
      (total: { [key: number]: string }, role) => {
        total[role.id] = role.role.name;

        return total;
      },
      { 0: t("QUICKSCAN.SCORECARD_EXPORT_ALL") },
    );
  }, [RoleStore.companyRoles, t]);

  const handleExportClick = async (data: ExportCompanyRoleFormValues) => {
    const from = new Date(0);
    const to = addDays(new Date(), 1);

    const companyRole = Number(data.companyRole);

    await ExportService.getCSV(UserStore.getCompanyId, from, to, companyRole === 0 ? undefined : Number(companyRole));
  };

  return (
    <Page>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="mb-sm d-flex">
              <h3 className="color-primary ">{t("QUICKSCAN.TITLE")}</h3>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                <Block name={t("QUICKSCAN.SCORECARD")}>
                  <Circle quaternary dashed r={100} x={-3} y={50} />
                  <Circle primary dashed r={40} x={60} y={90} />
                  <h4>{t("QUICKSCAN.SCORECARD_DESCRIPTION_TITLE")}</h4>
                  <text>{t("QUICKSCAN.SCORECARD_DESCRIPTION")}</text>
                </Block>
              </div>
              <div className="col-12 col-sm-6">
                <Block name={t("QUICKSCAN.SCORECARD")}>
                  <Circle quaternary dashed r={100} x={-3} y={50} />
                  <Circle primary dashed r={40} x={60} y={90} />
                  <h4>{t("QUICKSCAN.SCORECARD_EXPORT_TITLE")}</h4>
                  <text>{t("QUICKSCAN.SCORECARD_EXPORT_DESCRIPTION")}</text>
                  <Form.Form
                    identifier="EXPORT_COMPANY_ROLE_FORM"
                    onSubmit={handleExportClick}
                    initialValues={{ companyRole: companyRoleId }}
                    submit={t("QUICKSCAN.SCORECARD_EXPORT")}
                    className={styles.SelectRoleForm}
                  >
                    <Form.Item
                      id="companyRole"
                      render={(props) => (
                        <Form.Select
                          {...props}
                          placeholder={t("QUICKSCAN.ROLE_SELECT")}
                          options={getCompanyRoleOptions()}
                        />
                      )}
                    />
                  </Form.Form>
                </Block>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default observer(ScorecardPage);
