import React from "react";
import { useTranslation } from "react-i18next/";

import { UserQuestionnaireThemeScoreInstance } from "@Core/models/index";

import styles from "./QuestionnaireScore.module.scss";

type Props = {
  scores: UserQuestionnaireThemeScoreInstance[];
};

const QuestionnaireScore = ({ scores }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.QuestionnaireScore}>
      {scores.map((score: UserQuestionnaireThemeScoreInstance) => {
        return (
          <div key={score.theme.id} className={styles.Score}>
            <div className={styles.Score__Theme}>
              <div className={styles.Score__ThemeScore}>{score.score}</div>

              <div className={styles.Score__ThemeName}>
                {t("FORMS.QUESTIONNAIRE.SCORES.THEME", { name: score.theme.name })}
              </div>
            </div>

            {score.threshold_description && (
              <div
                className={styles.Score__Description}
                dangerouslySetInnerHTML={{ __html: score.threshold_description }}
              />
            )}
          </div>
        );
      })}

      {scores.length === 0 && (
        <div className={styles.Score}>
          <div className={styles.Score__Theme}>
            <div className={styles.Score__ThemeScore}>?</div>

            <div className={styles.Score__ThemeName}>{t("FORMS.QUESTIONNAIRE.SCORES.NO_THEME")}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionnaireScore;
