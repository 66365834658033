import axios from "@Core/axios";
import { EmployeeInstance } from "@Core/models/File/EmployeeModel";

class EmployeeService {
  resend = async (token: string): Promise<any> => {
    const data = {
      params: {
        token: token,
      },
    };

    const response = await axios.post(`aom/employees/resend`, {}, data);

    if (response && response.data) {
      return response;
    }

    return null;
  };

  update = async (id: number, values: EmployeeInstance): Promise<any | null> => {
    const response = await axios.put(`aom/employees/${id}`, values);

    if (response) {
      return response.data;
    }
  };

  debug = async (): Promise<void> => {
    await axios.get(`aom/employees/debug`);
  };
}

export default new EmployeeService();
