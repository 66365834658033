import cn from "classnames";
import React from "react";

import { Tag, TagProps } from "@Core/components/UI";

import styles from "./StepRowTag.module.scss";

export interface StepRowTagProps extends TagProps {}

export const StepRowTag = ({ className, ...props }: StepRowTagProps) => {
  return <Tag className={cn(styles.StepRowTag, className)} {...props} />;
};
