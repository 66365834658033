import cn from "classnames";
import React, { ReactNode, useCallback, useState } from "react";

import { Modal } from "@Core/components/Layouts";
import { useDialog } from "@Core/hooks";

import styles from "./Block.module.scss";

type Props = {
  name?: string | undefined;
  success?: boolean;
  warning?: boolean;
  primary?: boolean;
  primaryLight?: boolean;
  small?: boolean;
  stretch?: boolean;
  center?: boolean;
  info?: JSX.Element;
  titleElement?: JSX.Element;
  overflow?: boolean;
  scroll?: boolean;
  className?: string;
  children: ReactNode;
  card?: boolean;
  isCollapsed?: boolean;
};

const Block = ({
  name,
  success,
  warning,
  primary,
  primaryLight,
  small,
  center,
  className,
  scroll,
  children,
  info,
  stretch,
  titleElement,
  overflow,
  card,
  isCollapsed,
}: Props) => {
  const [isHidden, setIsHidden] = useState(isCollapsed);

  const handleCollapse = useCallback(() => {
    setIsHidden((oldIsHidden) => !oldIsHidden);
  }, []);

  const { isVisible, toggle } = useDialog();

  const classes = cn([styles.Block], {
    [styles.Primary]: primary,
    [styles.PrimaryLight]: primaryLight,
    [styles.Success]: success,
    [styles.Warning]: warning,
    [styles.Small]: small,
    [styles.Visible]: overflow,
    [styles.Card]: card,
    [styles.Center]: center,
    [styles.Scroll]: scroll,
    [styles.Hidden]: isHidden,
  });

  const getBody = () => (
    <div className={`${classes} ${className ? className : ""}`}>
      {name && (
        <div className={styles.Title}>
          {name}

          {info && (
            <div className={styles.Info} onClick={toggle}>
              <i className="far fa-info-circle"></i>
            </div>
          )}

          {titleElement && titleElement}

          {isCollapsed && (
            <div className={styles.Toggle} onClick={handleCollapse}>
              <i className="fa fa-chevron-up" />
            </div>
          )}
        </div>
      )}

      {info && (
        <Modal title={"" + name} isVisible={isVisible} hide={toggle}>
          {info}
        </Modal>
      )}

      <div className={styles.Body}>{children}</div>
    </div>
  );

  if (stretch) {
    return <div className={styles.Wrapper}>{getBody()}</div>;
  }

  return getBody();
};

export default Block;
