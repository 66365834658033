import { MODEL, PREPR_MODEL, PREPR_TAG } from "@PNI/services";
import { getCollectionQuery, getSingleQuery } from "@PNI/utilities/PreprUtility";

export const FIELD_ARTICLES = `
    title
	intro
	theme_pni ${PREPR_TAG}
	keywords_pni ${PREPR_TAG}
	module ${PREPR_TAG}
`;

export const FIELD_ARTICLE = `
    ${FIELD_ARTICLES}
	_read_time

	download {
		name
		cdn_files {
			_id
			url
		}
	}

	activation_title
	activation_intro
	activation_tips
	activation_tips_title
	activation_tips_icon
	activation_tips_links
	activation_steps
	activation_steps_title
	activation_steps_icon
	activation_steps_links

	content {
		... on Text {
			_id
			_type
			body
			format
		}
		... on SpotifyPlaylist {
			_id
			_type
			spotifyUrl: url
		}
		... on TextMetBestand {
			_id
			_type
			text
			url {
				_id
				_type
				body
				url
				type
			}
		}
		... on Assets {
			_type
			items {
				_id
				_type
				name
				cdn_files {
					_id
					url: url(width: 690)
				}
			}
		}
		... on Quote {
			_id
			_type
			body
			author
		}
	}

	related {
		${PREPR_MODEL}
		${FIELD_ARTICLES}
	}
`;

export const GET_ARTICLE = (filter?: Record<string, unknown>) => getSingleQuery(MODEL.ARTICLE, FIELD_ARTICLE, filter);

export const GET_ARTICLES = (filter?: Record<string, unknown>) =>
  getCollectionQuery(MODEL.ARTICLES, FIELD_ARTICLES, filter);
