import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { MomentStatus } from "@LNI/constants/ENUMS";
import { AssignmentModel } from "@LNI/models";

export const MomentModel = AssignmentModel.props({
  next_moment_date: types.maybeNull(types.string),
  pivot: types.model({
    created_at: types.maybeNull(types.string),
    status: types.enumeration<MomentStatus>(Object.values(MomentStatus)),
  }),
}).views((self) => ({
  get isPivotExpired(): boolean {
    return self.pivot.status === MomentStatus.EXPIRED;
  },

  get isPivotDone(): boolean {
    return self.pivot.status === MomentStatus.DONE;
  },

  get isPivotReceived(): boolean {
    return self.pivot.status === MomentStatus.RECEIVED;
  },
}));

export interface MomentInstance extends Instance<typeof MomentModel> {}
export type MomentSnapshot = SnapshotIn<typeof MomentModel>;
