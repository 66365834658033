import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const AssetModel = types.model({
  id: types.identifier,
  label: types.string,
  name: types.string,
  url: types.string,
});

export interface AssetInstance extends Instance<typeof AssetModel> {}
export type AssetSnapshot = SnapshotIn<typeof AssetModel>;
