import React from "react";

import { Button } from "@Core/components/UI";
import { withMatomo } from "@Core/hooks/withMatomo";
import { MatomoProps } from "@Core/interfaces";

import styles from "./Info.module.scss";

type Props = {
  name: string;
  button: string;
  onClick: () => void;
} & MatomoProps;

const Info = ({ name, button, onClick, ...props }: Props) => {
  return (
    <div className={styles.Item} onClick={onClick} {...props}>
      <div className={styles.Name}>{name}</div>

      <Button onClick={onClick} secondary link className={styles.Button}>
        {button}
        <i className="far fa-long-arrow-right"></i>
      </Button>
    </div>
  );
};

export default withMatomo(Info);
