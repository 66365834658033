import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import { Modal } from "@Core/components/Layouts";
import { Block, Button, CardRow } from "@Core/components/UI";
import { useDialog } from "@Core/hooks";
import { UserService } from "@Core/services";

import { TeamSignupForm } from "@LNI/forms";
import { TeamSignupFormFormValues } from "@LNI/forms/TeamSignupForm/TeamSignupForm.props";
import { CourseInstance } from "@LNI/models";
import { useLNIStores } from "@LNI/stores";

type Props = {
  courses: CourseInstance[];
};

const CourseList = ({ courses }: Props) => {
  const { t } = useTranslation();
  const { CourseStore } = useLNIStores();
  const { isVisible, toggle } = useDialog();

  const onSubmit = async (values: TeamSignupFormFormValues) => {
    await UserService.team(values.licence_code);
    await CourseStore.load();
  };

  const onSuccess = () => {
    toggle();
    toast.success(t("COURSES.TEAM_SIGNUP.SUCCESS"));
  };

  return (
    <Block name={t("COURSES.MY_COURSES")}>
      {courses.length > 0 && (
        <>
          {courses.map((course: CourseInstance, key: number) => (
            <CardRow
              key={key}
              largeIcon
              icon={course.icon}
              title={course.title}
              step={{ done: course.progress.done, total: course.progress.total }}
              to={`/cursussen/${course.id}`}
              finished={course.progress.total === course.progress.done}
            />
          ))}

          <Button primary onClick={toggle}>
            {t("COURSES.TEAM_SIGNUP.SIGNUP_BUTTON_EXTRA")}
          </Button>
        </>
      )}

      {courses.length === 0 && (
        <CardRow
          icon="fa-file"
          small
          description={String(t("COURSES.TEAM_SIGNUP.SIGNUP"))}
          dotted
          buttonPrimary={{
            title: t("COURSES.TEAM_SIGNUP.SIGNUP_BUTTON"),
            onClick: () => toggle(),
          }}
          onClick={() => toggle()}
        />
      )}

      <Modal title={t("COURSES.TEAM_SIGNUP.POPUP_TITLE")} isVisible={isVisible} hide={toggle}>
        <TeamSignupForm submit={t("COURSES.TEAM_SIGNUP.SUBMIT")} onSubmit={onSubmit} onSuccess={onSuccess} />
      </Modal>
    </Block>
  );
};

export default observer(CourseList);
