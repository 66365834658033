import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Modal } from "@Core/components/Layouts";
import { CompanySearchType } from "@Core/constants/ENUMS";
import { useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";
import { useCoreStores } from "@Core/stores";

import { GenerateFileFormUser } from "@PNI/forms/GenerateFileForm/internal/components";
import { usePNIStores } from "@PNI/stores";

import { FilePageHeaderEmployeeModalProps } from "./FilePageHeaderEmployeeModal.props";

export const FilePageHeaderEmployeeModal = ({ isVisible, toggle }: FilePageHeaderEmployeeModalProps) => {
  const { t } = useTranslation();
  const { UserStore } = useCoreStores();
  const { FileStore } = usePNIStores();
  const trackEvent = useMatomo();

  if (FileStore.file && FileStore.file.employee) {
    const handleSubmit = async (values: any) => {
      try {
        await FileStore.updateEmployee(values);
        await Promise.all([UserStore.load(), FileStore.load()]);

        trackEvent(MatomoCategory.File, t("FILES.MODALS.EDIT_EMPLOYEE.SUCCESS"));

        toggle();

        toast.success(t("FILES.MODALS.EDIT_EMPLOYEE.SUCCESS"));
      } catch (e) {
        toast.error(t("FILES.MODALS.EDIT_EMPLOYEE.ERROR"));
      }
    };

    return (
      <Modal
        title={t("FILES.MODALS.EDIT_EMPLOYEE.POPUP_TITLE")}
        isVisible={isVisible}
        hide={toggle}
        data-matomo-category={MatomoCategory.File}
      >
        <GenerateFileFormUser
          type={CompanySearchType.EMPLOYEE}
          submit={t("FILES.MODALS.EDIT_EMPLOYEE.SUBMIT")}
          initialValues={FileStore.file.employee}
          onSubmit={handleSubmit}
          isDisabled={FileStore.file?.isClosed}
        />
      </Modal>
    );
  }

  return <></>;
};
