import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import LazyLoad from "react-lazyload";
import { toast } from "react-toastify";

import { Button, Circle } from "@Core/components/UI";
import { getImage, nl2br } from "@Core/utilities";

import { AssignmentInstance } from "@LNI/models";

import styles from "./Text.module.scss";

type Props = {
  assignment: AssignmentInstance;
};

const Text = ({ assignment }: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    assignment.setCanComplete();
  }, [assignment]);

  const onSubmit = () => {
    toast.success(t(`ASSIGNMENT.${assignment.category}.COMPLETED`));
    assignment.setCompleted();
  };

  if (assignment) {
    return (
      <div className={cn([styles.Text], { [styles.TextImage]: assignment.image })}>
        <div className={styles.Wrapper}>
          {assignment.image && (
            <div className={styles.Image}>
              <LazyLoad>
                <img src={getImage(assignment.image.path)} alt={assignment.title} />
              </LazyLoad>
            </div>
          )}

          <div>
            <h5>{assignment.title}</h5>
            {assignment.contents && nl2br(assignment.contents)}
          </div>
        </div>

        {!assignment.image && (
          <>
            <Circle primary dashed x={85} y={30} r={90} />
            <Circle secondary dashed x={95} y={50} r={50} />
          </>
        )}

        <div className="btn-row">
          <Button onClick={onSubmit} tertiary disabled={assignment.canFinish}>
            {t(`ASSIGNMENT.${assignment.category}.DONE`)}
          </Button>
        </div>
      </div>
    );
  }

  return <></>;
};

export default observer(Text);
