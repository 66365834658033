import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import { Frame } from "@Core/components/UI";

import { RecommendedAbsenceTypeForm, RecommendedAbsenceTypeFormValues } from "@PNI/forms";
import { usePNIStores } from "@PNI/stores";

import { RecommendedAbsenceTypeProps } from "./RecommendedAbsenceType.props";

export const RecommendedAbsenceType = observer(({ className }: RecommendedAbsenceTypeProps) => {
  const { FileStore } = usePNIStores();
  const { t } = useTranslation();

  const handleSubmit = async (values: RecommendedAbsenceTypeFormValues) => {
    try {
      if (!values?.recommended_absence_type) {
        throw new Error();
      }

      await FileStore.file?.setAbsenceType(values.recommended_absence_type);

      toast.success(t("FILES.RECOMMENDED_ABSENCE_TYPE.FORM.SUCCESS"));
    } catch {
      toast.error(t("FILES.RECOMMENDED_ABSENCE_TYPE.FORM.ERROR"));
    }
  };

  return (
    <Frame title={t("FILES.RECOMMENDED_ABSENCE_TYPE.TITLE")} className={className}>
      <Frame.Body padding="3">
        {FileStore.file && (
          <RecommendedAbsenceTypeForm
            isDisabled={FileStore.file.isClosed}
            onSubmit={handleSubmit}
            initialValues={{
              recommended_absence_type: FileStore.file.recommended_absence_type || undefined,
            }}
          />
        )}
      </Frame.Body>
    </Frame>
  );
});
