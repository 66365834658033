import cn from "classnames";
import React from "react";
import { useEffect, useState } from "react";

import styles from "./Circle.module.scss";

type Props = {
  dark?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  quaternary?: boolean;
  dashed?: boolean;
  opacity?: number;

  x?: number;
  y?: number;
  r?: number;

  className?: string;
};

const Circle = ({
  dark,
  primary,
  secondary,
  tertiary,
  quaternary,
  opacity = 0.3,
  dashed = false,
  x = 0,
  y = 0,
  r = 50,
  className,
}: Props) => {
  const [background, setBackground] = useState("#69548d");

  useEffect(() => {
    if (dark) {
      setBackground("#2b4982");
    }

    if (primary) {
      setBackground("#56bbad");
    }

    if (secondary) {
      setBackground("#fed385");
    }

    if (tertiary) {
      setBackground("#d7e2f3");

      if (dark) {
        setBackground("#2856ab");
      }
    }

    if (quaternary) {
      setBackground("#ffe4b3");
    }
  }, [primary, secondary, tertiary, quaternary]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn([styles.Circle, className])}
      viewBox="0 0 188 188"
      preserveAspectRatio="none"
      style={{
        width: `${r}px`,
        height: `${r}px`,
        minWidth: `${r}px`,
        minHeight: `${r}px`,
        left: `${x}%`,
        top: `${y}%`,
      }}
    >
      {!dashed && <circle fill={background} opacity={opacity} cx="94" cy="94" r="94" />}

      {dashed && (
        <path
          fill={background}
          opacity={opacity}
          d="M173 45L12 138l-5-10 160-93 6 10zm3 5l5 9-161 93-5-9zm7 15l2 10-153 89-8-8zm-20-34L5 122l-3-10 153-89 8 8zm23 50l1 13-140 81-10-7zm-36-62L1 105 0 94l141-81 9 6zm-15-9L0 87l2-13L123 5l12 5zm52 91l-2 13-119 69-13-5zM115 2L4 66c2-6 4-12 8-17L96 0l19 2zm68 120l-7 16-85 49-17-2zm-14 27a93 93 0 01-65 37zM83 1L18 38A93 93 0 0183 1z"
        />
      )}
    </svg>
  );
};

export default Circle;
