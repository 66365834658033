import { useCallback, useMemo, useState } from "react";

export const useBoolean = (initialValue?: boolean) => {
  const [value, setValue] = useState(!!initialValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((oldValue) => !oldValue), []);

  return useMemo(() => {
    return {
      value,
      setValue,
      setTrue,
      setFalse,
      toggle,
    };
  }, [value, setValue, setTrue, setFalse, toggle]);
};
