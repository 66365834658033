import { observer } from "mobx-react-lite";
import React from "react";

import { QuestionnaireFor } from "@Core/constants/ENUMS";

import { DigitalQuestionnaire } from "@PNI/components/Containers";

import { Introduction } from "./internal/components/Introduction/Introduction";

const EmployeeQuestionnarePage = () => {
  return (
    <DigitalQuestionnaire type={QuestionnaireFor.EMPLOYEE}>
      {({ onComplete }) => <Introduction type={QuestionnaireFor.EMPLOYEE} onComplete={onComplete} />}
    </DigitalQuestionnaire>
  );
};

export default observer(EmployeeQuestionnarePage);
