import cn from "classnames";
import React from "react";

import styles from "./PreprRendererQuote.module.scss";
import { PreprRendererQuoteProps } from "./PreprRendererQuote.props";

export const PreprRendererQuote = ({ element }: PreprRendererQuoteProps) => {
  return (
    <div className={cn(styles.PreprRendererQuote, "row")}>
      <div className="col-xl-2 hidden-lg-down"></div>

      <div className="col-xl-7 col-lg-9 col-md-12">
        <blockquote className={styles.Quote}>
          <p>{element.body}</p>
          {element.author && <cite>{element.author}</cite>}
        </blockquote>
      </div>
    </div>
  );
};
