import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import { Button, StepRow } from "@Core/components/UI";
import { FileStepType, QuestionnaireFor, UserQuestionnaireStatus } from "@Core/constants/ENUMS";
import { useDialog } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";
import { formatDate } from "@Core/utilities";

import { usePNIStores } from "@PNI/stores";

import { PhoneModal, ReminderModal } from "./internal/components";
import { StepProps } from "./Step.props";

export const Step = observer(({ step, hasToggle }: StepProps) => {
  const { t } = useTranslation();
  const { FileStore } = usePNIStores();
  const dialogPhone = useDialog();
  const dialogReminder = useDialog();

  const handleToggle = async () => {
    try {
      await step.toggle();
    } catch (e) {
      toast.error(t("SHARED.ERROR"));
    }
  };

  const handleOpen = () => {
    window.open(
      `/dossiers/${FileStore.file?.id}/vragenlijst/${step.file_questionnaire?.user_questionnaire?.id}`,
      "_blank",
      "noreferrer",
    );
  };

  const isStepDisabled =
    FileStore.file?.isClosed || step?.file_questionnaire?.status === UserQuestionnaireStatus.Cancelled;

  return (
    <>
      <StepRow
        {...step}
        isChecked={step.selected}
        onToggle={hasToggle ? () => handleToggle() : undefined}
        isDisabled={FileStore.file?.isClosed}
      >
        {step.file_questionnaire && step.file_questionnaire.questionnaire && (
          <>
            {step.file_questionnaire.isCompleted && step.file_questionnaire.user_questionnaire?.completed_at && (
              <StepRow.Tag
                name={formatDate(step.file_questionnaire.user_questionnaire?.completed_at)}
                icon="folder-open"
                variant="light"
              />
            )}

            {!step.file_questionnaire.isCompleted &&
              !!step.file_questionnaire.last_reminder_at &&
              step.file_questionnaire.questionnaire.for !== QuestionnaireFor.FILE_MANAGER && (
                <StepRow.Tag
                  name={formatDate(step.file_questionnaire.last_reminder_at)}
                  icon={step.file_questionnaire.mail_received ? "mailbox" : "paper-plane"}
                  variant="light"
                />
              )}

            <StepRow.Buttons>
              {step.file_questionnaire.isCompleted ? (
                <Button tertiary link onClick={handleOpen}>
                  {t("FILES.QUESTIONNAIRE_OPEN")}
                </Button>
              ) : (
                <>
                  {step.file_questionnaire.questionnaire.for === QuestionnaireFor.EMPLOYEE && (
                    <>
                      <ReminderModal step={step} {...dialogReminder} />

                      <PhoneModal step={step} {...dialogPhone} />

                      <Button
                        tertiary
                        onClick={dialogPhone.toggle}
                        block
                        disabled={isStepDisabled}
                        data-matomo-category={MatomoCategory.FileStep}
                        data-matomo-name={step.title}
                      >
                        {t("FILES.QUESTIONNAIRE_BY_PHONE")}
                      </Button>

                      <Button
                        primary
                        onClick={dialogReminder.toggle}
                        block
                        disabled={isStepDisabled}
                        data-matomo-category={MatomoCategory.FileStep}
                        data-matomo-name={step.title}
                      >
                        {t("FILES.QUESTIONNAIRE_SEND_REMINDER")}
                      </Button>
                    </>
                  )}

                  {step.file_questionnaire.questionnaire.for === QuestionnaireFor.SUPERVISOR && (
                    <>
                      <ReminderModal step={step} {...dialogReminder} />

                      <PhoneModal step={step} {...dialogPhone} />

                      <Button
                        tertiary
                        onClick={dialogPhone.toggle}
                        block
                        disabled={isStepDisabled}
                        data-matomo-category={MatomoCategory.FileStep}
                        data-matomo-name={step.title}
                      >
                        {t("FILES.QUESTIONNAIRE_BY_PHONE")}
                      </Button>

                      <Button
                        primary
                        onClick={dialogReminder.toggle}
                        block
                        disabled={isStepDisabled}
                        data-matomo-category={MatomoCategory.FileStep}
                        data-matomo-name={step.title}
                      >
                        {t("FILES.QUESTIONNAIRE_SEND_REMINDER")}
                      </Button>
                    </>
                  )}

                  {step.file_questionnaire.questionnaire.for === QuestionnaireFor.FILE_MANAGER && (
                    <>
                      <PhoneModal step={step} {...dialogPhone} />

                      <Button
                        tertiary
                        onClick={dialogPhone.toggle}
                        block
                        disabled={isStepDisabled}
                        data-matomo-category={MatomoCategory.FileStep}
                        data-matomo-name={step.title}
                      >
                        {t("FILES.QUESTIONNAIRE_BY_PHONE")}
                      </Button>
                    </>
                  )}
                </>
              )}
            </StepRow.Buttons>
          </>
        )}

        {step.type === FileStepType.STEP && step.selected && !!step.selected_at && (
          <StepRow.Tag name={formatDate(step.selected_at)} icon="check" variant="light" />
        )}
      </StepRow>
    </>
  );
});
