import cn from "classnames";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import styles from "./Card.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  to?: string;
  target?: string;
  onClick?: () => void;
};

const Card = ({ children, className, target, onClick, disabled, to }: Props) => {
  const classes = cn([styles.Wrapper, className], { [styles.Disabled]: disabled });

  if (to && !disabled) {
    return (
      <Link to={to} target={target} className={classes}>
        {children}
      </Link>
    );
  }
  return (
    <div onClick={onClick} className={classes}>
      {children}
    </div>
  );
};

export default Card;
