import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import { Modal } from "@Core/components/Layouts";
import { Button } from "@Core/components/UI";
import { useDialog, useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";

import { GenerateFileForm, GenerateFileFormValues } from "@PNI/forms";
import { usePNIStores } from "@PNI/stores";

import styles from "./DashboardPageGenerate.module.scss";

export const DashboardPageGenerate = observer(() => {
  const { t } = useTranslation();
  const { isVisible, toggle } = useDialog();
  const history = useHistory();
  const trackEvent = useMatomo();

  const { FileStore } = usePNIStores();

  const onSubmit = async (values: GenerateFileFormValues) => {
    try {
      const response = await FileStore.createFile(values);
      await FileStore.load();

      trackEvent(MatomoCategory.Dashboard, t("FILES.GENERATE.SUCCESS"), values.type);

      toggle();

      toast.success(t("FILES.GENERATE.SUCCESS"));
      history.push(`/dossiers/${response.id}`);
    } catch (e) {
      toast.error(t("FILES.GENERATE.ERROR"));
    }
  };

  return (
    <div>
      <div className="d-flex">
        <Button data-matomo-category={MatomoCategory.Dashboard} tertiary className={styles.Button} onClick={toggle}>
          {t("FILES.GENERATE.BUTTON")}
        </Button>
      </div>

      <Modal
        title={t("FILES.GENERATE.POPUP_TITLE")}
        isVisible={isVisible}
        hide={toggle}
        data-matomo-category={MatomoCategory.Dashboard}
      >
        <GenerateFileForm onSubmit={onSubmit} />
      </Modal>
    </div>
  );
});
