import React from "react";
import { useTranslation } from "react-i18next/";

import Form from "@Core/components/Form";
import Yup from "@Core/validation";

import Props from "./TagsForm.props";

const TagsForm = ({ initialValues, onSubmit, onSuccess, tags }: Props) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    tags: Yup.array()
      /* eslint-disable no-template-curly-in-string */
      .min(1, 'MIN_SUBJECTS={"min":${min}}')
      .required(),
  });

  return (
    <Form.Form
      identifier="TAGS_FORM"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      submit={t("PROFILE.FORMS.TAGS.CHANGE_SUBJECTS")}
    >
      <Form.Item
        id="tags"
        name={t("PROFILE.FORMS.TAGS.SELECTED_SUBJECTS")}
        render={(props) => <Form.Checkbox {...props} options={tags} />}
      />
    </Form.Form>
  );
};

export default TagsForm;
