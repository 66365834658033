import { useField } from "formik";
import RCSlider from "rc-slider";
import React, { useEffect, useState } from "react";

import { IFieldProps } from "@Core/interfaces";
import { UserQuestionnaireQuestionInstance } from "@Core/models";

import styles from "./ScaleSlider.module.scss";

type Props = {
  id: string;
  name: string;

  disabled?: boolean;
  question: UserQuestionnaireQuestionInstance;
  field: IFieldProps;
};

const ScaleSlider = ({ id, name, disabled, question, field }: Props) => {
  const [value, setValue] = useState(-10);
  const [marks, setMarks] = useState({});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [none, meta, helpers] = useField(id);

  // Renders the marks & sets the default value
  useEffect(() => {
    const count = Object.entries(question.answers).length - 1;

    setMarks(
      Object.entries(question.answers).reduce((total: Record<string, string>, option: any, index: number) => {
        if (field.value === option[0] || option[1].selected) {
          setValue((100 / count) * index);
        }

        total[(100 / count) * index] = option[1].text;
        return total;
      }, {}) || {},
    );
  }, [field.value, question.answers]);

  const getOptionId = (percentage: number) => {
    const count = Object.entries(question.answers).length - 1;

    const found = Object.entries(question.answers).find(
      (option: any, index: number) => (100 / count) * index === percentage,
    );

    if (found) {
      return {
        id: found[1].id,
        name: found[1].text,
      };
    }

    return null;
  };

  const onSliderChange = (id: number) => {
    setValue(id);

    const option = getOptionId(id);

    if (option) {
      helpers.setValue(option.id.toString());
    }
  };

  return (
    <div className={styles.Inputs}>
      <RCSlider
        value={value}
        onChange={onSliderChange}
        startPoint={-10}
        disabled={disabled}
        min={0}
        max={100}
        marks={marks}
        step={null}
      />

      <input id={id} type="hidden" className={styles.Input} {...field} />
    </div>
  );
};

export default ScaleSlider;
