import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { Loader } from "@Core/components/UI";
import { useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";

import { Page } from "@PNI/components/Layouts";
import { IArticle, PreprArticleService } from "@PNI/services";

import { ArticlesDetailBody, ArticlesDetailFooter, ArticlesDetailHeader } from "./internal/components";

const ArticlesDetailPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [article, setArticle] = useState<IArticle>();
  const trackEvent = useMatomo();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        setArticle(undefined);

        const response = await PreprArticleService.getArticle(id);

        if (response && response.Artikel) {
          setArticle(response.Artikel);

          trackEvent(MatomoCategory.Article, "Artikel lezen", response.Artikel.title);
          trackEvent(MatomoCategory.Article, "Artikel lezen (Thema)", response.Artikel.theme_pni[0].body);

          response.Artikel.keywords_pni.map((keyword) => {
            trackEvent(MatomoCategory.Article, "Artikel lezen (Onderwerp)", keyword.body);
          });
        } else {
          history.push("/");
        }
      } catch (e) {
        setError(true);
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  const breadcrumbs = [{ to: `/artikelen`, name: t("ARTICLES.TITLE") }, { name: article?.title }];

  return (
    <Page breadcrumbs={breadcrumbs}>
      {loading && <Loader width={50} color="#56bbad" className="mt-md" />}

      {error && <div className="col-lg-12 mb-md">{t("DASHBOARD.ARTICLES.ERROR")}</div>}

      {article && (
        <>
          <ArticlesDetailHeader article={article} />

          <ArticlesDetailBody article={article} />

          <ArticlesDetailFooter article={article} />
        </>
      )}
    </Page>
  );
};

export default observer(ArticlesDetailPage);
