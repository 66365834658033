import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import { Questionnaire } from "@Core/components/Components";
import { Circle } from "@Core/components/UI";

import { AssignmentInstance } from "@LNI/models";

type Props = {
  assignment: AssignmentInstance;
};

const Question = ({ assignment }: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!assignment.user_questionnaire_id) {
      assignment.startQuestionnaire(assignment.id);
    }
  }, [assignment]);

  const onSubmit = () => {
    toast.success(t(`ASSIGNMENT.${assignment.category}.COMPLETED`));

    assignment.setCanComplete();
    assignment.setCompleted();
  };

  if (assignment && assignment.user_questionnaire_id) {
    return (
      <>
        <Circle secondary dashed x={95} y={15} r={150} />
        <Circle primary x={5} y={65} r={65} />

        <Questionnaire id={assignment.user_questionnaire_id} onCompletion={onSubmit} />
      </>
    );
  }

  return <></>;
};

export default observer(Question);
