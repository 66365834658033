import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";

import { CardRow } from "@Core/components/UI";

import { AssignmentInstance } from "@LNI/models";

type Props = {
  assignments: AssignmentInstance[];
};

const AssignmentList = ({ assignments }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  if (assignments) {
    return (
      <div>
        {assignments.map((assignment: AssignmentInstance, key: number) => {
          const slug = `/${assignment.isChallenge ? "uitdagingen" : "cursussen"}/${assignment.getSlug}`;

          const buttonPrimary = {
            title: assignment.isDone
              ? t(`ASSIGNMENT.${assignment.getType}.AGAIN`)
              : t(`ASSIGNMENT.${assignment.getType}.START`),
            onClick: () => {
              history.push(slug);
            },
          };

          return (
            <CardRow
              large
              disabled={!assignment.isDone && !assignment.canView}
              finished={assignment.isDone}
              key={key}
              icon={t(`ASSIGNMENT.${assignment.getType}.ICON`)}
              type={t(`ASSIGNMENT.${assignment.getType}.NAME`)}
              title={assignment.title}
              description={assignment.description ? assignment.description : ""}
              buttonPrimary={buttonPrimary}
              to={slug}
            />
          );
        })}
      </div>
    );
  }

  return <></>;
};

export default observer(AssignmentList);
