import { Document, Font, Image, Page, StyleSheet, Text } from "@react-pdf/renderer";
import React, { ReactNode } from "react";
import Html from "react-pdf-html";
import { HtmlProps } from "react-pdf-html/dist/Html";

import AzoSansBold from "@Core/assets/fonts/AzoSansBold.ttf";
import AzoSansRegular from "@Core/assets/fonts/AzoSansRegular.ttf";
import { Platform } from "@Core/constants/ENUMS";
import { useCoreStores } from "@Core/stores";

import LNI from "@LNI/assets/images/logo.png";
import OOM from "@OOM/assets/images/logo.png";
import PNI from "@PNI/assets/images/logo-aom.png";

interface PdfProps {
  children: ReactNode;
}

export const PDF = ({ children }: PdfProps) => {
  const { getPlatform } = useCoreStores();

  Font.register({
    family: "Azo Sans",
    fonts: [{ src: AzoSansRegular }, { src: AzoSansBold, fontWeight: 700 }],
  });

  const styles = StyleSheet.create({
    Page: {
      fontFamily: "Azo Sans",
      fontSize: 10,
      paddingVertical: 90,
      paddingHorizontal: 20,
    },

    Header: {
      position: "absolute",
      top: 30,
      left: 20,
      width: "auto",
      height: 35,
    },

    Footer: {
      position: "absolute",
      bottom: "30px",
      left: 0,
      right: 0,
      color: colors.support,
      fontSize: "10px",
      fontWeight: 300,
      textAlign: "center",
    },
  });

  const logo = () => {
    switch (getPlatform) {
      case Platform.PNI:
        return PNI;
      case Platform.LNI:
        return LNI;
      case Platform.OOM:
        return OOM;
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.Page} wrap>
        <Image fixed style={styles.Header} src={logo()} />

        <Text fixed style={styles.Footer} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />

        {children}
      </Page>
    </Document>
  );
};

export const HTML = ({ children }: HtmlProps) => {
  const style = { fontFamily: "Azo Sans", fontSize: 10 };

  const stylesheet = StyleSheet.create({
    p: {
      marginBottom: 8,
    },

    ul: {
      marginBottom: 8,
    },

    ol: {
      marginBottom: 8,
    },

    a: {
      color: colors.primary,
      textDecoration: "none",
    },

    "*:last-child": {
      marginBottom: 0,
    },
  });

  return (
    <Html resetStyles style={style} stylesheet={stylesheet}>
      {children.replaceAll("<em>", "").replaceAll("</em>", "")}
    </Html>
  );
};

export const colors = {
  body: "#2e384d",
  primary: "#2b4982",
  support: "#a6b4c9",
  supportLight: "#e0e7ff",
  supportLightest: "#fafbff",
};
