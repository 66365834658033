import { flow, Instance, types } from "mobx-state-tree";

import { FileModel, FilesModel } from "@Core/models";
import { EmployeeInstance } from "@Core/models/File/EmployeeModel";
import { FileService } from "@Core/services";
import CoreRootStore from "@Core/stores/CoreRootStore";
import { isProduction } from "@Core/utilities";

import { ConnectSupervisorFormValues, GenerateFileFormValues } from "@PNI/forms";
import { EmployeeService } from "@PNI/services";

const FileStore = types
  .model("FileStore", {
    files: types.array(FilesModel),
    archived: types.array(FilesModel),
    file: types.maybeNull(FileModel),
  })
  .actions((self) => ({
    load: flow(function* () {
      const companyId = CoreRootStore.UserStore.getCompanyId;

      const response = yield Promise.all([FileService.getFiles(companyId), FileService.getFiles(companyId, true)]);

      if (response) {
        self.files.clear();
        self.archived.clear();

        if (response[0] && response[0].length > 0) {
          self.files = response[0];
        }

        if (response[1] && response[1].length > 0) {
          self.archived = response[1];
        }
      }
    }),

    createFile: flow(function* (values: GenerateFileFormValues) {
      try {
        const companyId = CoreRootStore.UserStore.getCompanyId;
        return yield FileService.createFile(companyId, values);
      } catch (e) {
        throw new Error(`FILE_NOT_CREATED`);
      }
    }),

    sendQuestionnaire: flow(function* (questionnaireId: number) {
      try {
        if (self.file) {
          return yield FileService.sendQuestionnaire(self.file.id, questionnaireId);
        }
      } catch (e) {
        throw new Error(e);
      }
    }),

    updateEmployee: flow(function* (values: EmployeeInstance) {
      try {
        if (self.file && self.file.employee) {
          const response = yield EmployeeService.update(self.file.employee.id, values);
          self.file.employee = response;
        }
      } catch (e) {
        throw new Error(e);
      }
    }),

    connectSupervisor: flow(function* (values: ConnectSupervisorFormValues) {
      try {
        if (self.file && self.file.employee) {
          const response = yield FileService.connectSupervisor(self.file.id, values);
          self.file = response;
        }
      } catch (e) {
        throw new Error(e);
      }
    }),

    clearFile() {
      self.file = null;
    },

    getFile: flow(function* (file: string, reload = false) {
      if (reload || !self.file || self.file.id !== parseInt(file)) {
        try {
          const response = yield FileService.getFile(parseInt(file));
          self.file = response;
        } catch (e) {
          if (!isProduction()) {
            console.error(e);
          }

          throw new Error(`FILE_NOT_FOUND`);
        }
      }
    }),

    getOmission: flow(function* (file: number) {
      try {
        if (self.file && !self.file.vos) {
          const response = yield FileService.startOmission(file);

          self.file.vos = response;

          return response.id;
        }
      } catch (e) {
        throw new Error(`VOS_NOT_STARTED`);
      }
    }),
  }));

export interface FileStoreInstance extends Instance<typeof FileStore> {}

export default FileStore;
