import React, { useState } from "react";
import { useTranslation } from "react-i18next/";

import { Button } from "@Core/components/UI";
import { CompanySearchType } from "@Core/constants/ENUMS";
import { MatomoCategory } from "@Core/interfaces";

import { GenerateFileFormSearch, GenerateFileFormUser } from "../GenerateFileForm/internal/components";
import ConnectSupervisorFormProps from "./ConnectSupervisorForm.props";

export const ConnectSupervisorForm = ({ initialValues, onSubmit, onSuccess }: ConnectSupervisorFormProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);

  return (
    <>
      {step === 0 && (
        <>
          <GenerateFileFormSearch
            submit={t("FILES.CONNECT.SUBMIT")}
            type={CompanySearchType.SUPERVISOR}
            onSubmit={(id) => onSubmit({ supervisor_id: id })}
            onSuccess={onSuccess}
          />

          <hr />

          <Button
            data-matomo-category={MatomoCategory.Dashboard}
            block
            primary
            className="mt-sm"
            onClick={() => setStep(1)}
          >
            {t("FILES.CONNECT.MANUAL")}
          </Button>
        </>
      )}

      {step === 1 && (
        <GenerateFileFormUser
          initialValues={initialValues}
          submit={t("FILES.CONNECT.SUBMIT")}
          type={CompanySearchType.SUPERVISOR}
          onSubmit={(values) => onSubmit({ supervisor: values })}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};
