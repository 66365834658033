export enum AssignmentCategory {
  CHALLENGE = "CHALLENGE",
  COURSE = "COURSE",
  MOMENT = "MOMENT",
}

export enum AssignmentType {
  VIDEO = "video",
  QUESTIONNAIRE = "questionnaire",
  COMPANY_ASSIGNMENT = "company_assignment",
  ARTICLE = "article",
  TEXT = "text",
  IMAGE = "App\\Models\\Image",
  POLL = "poll",
}

export enum MomentStatus {
  DONE = "done",
  RECEIVED = "received",
  EXPIRED = "expired",
}
