import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import Form from "@Core/components/Form";
import { Modal } from "@Core/components/Layouts";
import { Block, Button, Circle } from "@Core/components/UI";
import { useDialog } from "@Core/hooks";
import Yup from "@Core/validation";

import { Page } from "@OOM/components/Layouts";
import { InviteTokenInstance } from "@OOM/models";
import { RoleService } from "@OOM/services";
import { useOOMStores } from "@OOM/stores";

import styles from "./AnswerOverviewPage.module.scss";
import InvitationList from "./Components/InvitationList";

type Params = {
  companyRoleId: string | undefined;
};

type InviteTokenFormValues = {
  amountOfLicences: number;
  expiryDate: Date;
};

const AnswerPageQuickscan = () => {
  const { RoleStore } = useOOMStores();
  const { t } = useTranslation();
  const createInviteTokenPopup = useDialog();
  const inviteTokenPopup = useDialog();
  const { companyRoleId } = useParams<Params>();
  const [isLoading, setLoading] = useState(false);
  const [selectedToken, setSelectedToken] = useState<InviteTokenInstance | null>(null);
  const { selectedRole } = RoleStore;

  useEffect(() => {
    (async () => {
      if (companyRoleId) {
        const companyRoleInt = parseInt(companyRoleId);
        const role = RoleStore.getById(companyRoleInt);
        const hasData = role?.role_questionnaires && role.role_questionnaires.length > 0;

        setLoading(!hasData);
        await RoleStore.loadRole(companyRoleInt);
        setLoading(false);
      }
    })();
  }, [companyRoleId, RoleStore]);

  const handleInviteTokenCreate = async (data: InviteTokenFormValues) => {
    if (companyRoleId) {
      const response = await RoleService.createInviteToken(
        parseInt(companyRoleId),
        data.amountOfLicences,
        data.expiryDate,
      );

      const tokenInstance = selectedRole?.addInviteToken(response.data);

      if (tokenInstance) {
        setSelectedToken(tokenInstance);
        createInviteTokenPopup.toggle();
        inviteTokenPopup.toggle();
      }

      return response;
    }
  };

  const handleInviteTokenOpen = (token: InviteTokenInstance) => {
    setSelectedToken(token);
    inviteTokenPopup.toggle();
  };

  const handleInviteTokenCopy = () => {
    if (selectedToken) {
      navigator.clipboard.writeText(selectedToken.getLink);
      toast.success(t("QUICKSCAN.INVITE_TOKEN.COPIED"));
    }
  };

  const handleInviteTokenRemove = (id: number) => {
    console.log(id);
  };

  const inviteTokenInitialValues = {
    amountOfLicences: 1,
    expiryDate: new Date(),
  };

  const inviteTokenValidationScheme = Yup.object().shape({
    amountOfLicences: Yup.number().min(1).required(),
    expiryDate: Yup.date().required(),
  });

  return (
    <Page>
      <div className="container">
        <Modal
          title={t("QUICKSCAN.INVITE_TOKEN.CREATE.MODAL.TITLE")}
          isVisible={createInviteTokenPopup.isVisible}
          hide={() => {
            createInviteTokenPopup.toggle();
          }}
        >
          <h4>{t("QUICKSCAN.INVITE_TOKEN.CREATE.MODAL.SUBTITLE", { role: selectedRole?.role.name })}</h4>
          <Form.Form
            identifier="CREATE_INVITE_TOKEN"
            initialValues={inviteTokenInitialValues}
            validationSchema={inviteTokenValidationScheme}
            onSubmit={handleInviteTokenCreate}
            submit={t("QUICKSCAN.INVITE_TOKEN.CREATE.FORM.SUBMIT")}
          >
            <Form.Item
              id="amountOfLicences"
              name={t("QUICKSCAN.INVITE_TOKEN.CREATE.FORM.AMOUNT_OF_LICENCES")}
              render={(props) => <Form.Number min={1} {...props} />}
            />

            <Form.Item
              id="expiryDate"
              name={t("QUICKSCAN.INVITE_TOKEN.CREATE.FORM.EXPIRY_DATE")}
              render={(props) => <Form.Date minDate={new Date()} {...props} />}
            />
          </Form.Form>
        </Modal>

        <Modal
          title={t("QUICKSCAN.INVITE_TOKEN.MODAL.TITLE", { token: selectedToken?.token })}
          isVisible={inviteTokenPopup.isVisible}
          hide={() => {
            inviteTokenPopup.toggle();
          }}
        >
          <p>{t("QUICKSCAN.INVITE_TOKEN.MODAL.DESCRIPTION")}</p>

          <Form.Form
            identifier="inviteToken"
            initialValues={{
              inviteToken: selectedToken?.getLink,
            }}
            onSubmit={() => null}
          >
            <div onClick={handleInviteTokenCopy} className={styles.copyToken}>
              <Form.Item id="inviteToken" render={(props) => <Form.Text {...props} disabled />} />
            </div>
          </Form.Form>
        </Modal>

        <div className="row">
          <div className="col-lg-12">
            <div className="mb-sm d-flex">
              <h3 className="color-primary ">{t("QUICKSCAN.TITLE_ANSWERS", { role: selectedRole?.role.name })}</h3>
            </div>

            <Block name={t("QUICKSCAN.SCORECARD")}>
              <Circle quaternary dashed r={100} x={-3} y={50} />
              <Circle primary dashed r={40} x={60} y={90} />
              <div>
                <text>
                  Vivamus laoreet. Nullam tincidunt adipiscing enim. Phasellus tempus. Proin viverra, ligula sit amet
                  ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. Fusce neque.
                  Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus
                  elit id tortor.
                </text>
              </div>
              <Button to={`../scorekaart/${companyRoleId}`} className="mt-sm" tertiary>
                {t("QUICKSCAN.VIEW_SCORECARD")}
              </Button>
            </Block>

            <Block name={t("QUICKSCAN.INVITE_TOKEN.TITLE")}>
              <div className="mb-md">
                <text>
                  Vivamus laoreet. Nullam tincidunt adipiscing enim. Phasellus tempus. Proin viverra, ligula sit amet
                  ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. Fusce neque.
                  Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus
                  elit id tortor.
                </text>
              </div>

              <InvitationList
                inviteTokens={selectedRole?.invite_tokens}
                onClickToken={handleInviteTokenOpen}
                onClickRemove={handleInviteTokenRemove}
                isLoading={isLoading}
              />
              <Button onClick={createInviteTokenPopup.toggle} className="mt-sm" tertiary>
                {t("QUICKSCAN.INVITE_TOKEN.CREATE.TITLE")}
              </Button>
            </Block>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default observer(AnswerPageQuickscan);
