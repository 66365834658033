import { flow, Instance, isValidReference, types } from "mobx-state-tree";

import { AssignmentModel, CourseModel } from "@LNI/models";
import { CourseService } from "@LNI/services";

const CourseStore = types
  .model({
    courses: types.array(CourseModel),
    course: types.maybeNull(types.safeReference(CourseModel)),
    assignment: types.maybeNull(types.safeReference(AssignmentModel)),
  })
  .actions((self) => ({
    load: flow(function* () {
      const response = yield CourseService.getCourses();

      if (response) {
        self.courses = response;
      }
    }),

    getCourse: flow(function* (course: string, assignment?: string) {
      self.assignment = null;

      self.course = course as any;

      if (!isValidReference(() => self.course, true)) {
        self.course = null;
        throw new Error();
      }

      if (self.course?.assignments.length === 0) {
        const response = yield CourseService.getAssignments(self.course.id);
        self.course.assignments = response;
      }

      if (self.course && self.course?.assignments && assignment) {
        self.assignment = assignment as any;

        if (!isValidReference(() => self.assignment, true)) {
          self.assignment = null;
          throw new Error();
        }
      }
    }),
  }))
  .views((self) => ({
    get hasCourses(): boolean {
      return self.courses.length > 0;
    },
  }));

export interface CourseStoreInstance extends Instance<typeof CourseStore> {}

export default CourseStore;
