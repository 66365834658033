import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import { Button, CardArticle, Circle } from "@Core/components/UI";
import { ArticleInstance } from "@Core/models";

import { AssignmentInstance } from "@LNI/models";
import { PollAnswerInstance } from "@LNI/models";
import { useLNIStores } from "@LNI/stores";

import styles from "./Poll.module.scss";

type Props = {
  assignment: AssignmentInstance;
};

const Poll = ({ assignment }: Props) => {
  const { t } = useTranslation();
  const { MomentStore } = useLNIStores();
  const [value, setValue] = useState();

  const [currentAssignment, setCurrentAssignment] = useState<AssignmentInstance>(assignment);

  useEffect(() => {
    currentAssignment.setCanComplete();
  }, [currentAssignment]);

  const onSubmit = async () => {
    await MomentStore.moment.poll.setAnswer(value);
    await currentAssignment.setCompleted();

    toast.success(t(`ASSIGNMENT.${currentAssignment.category}.COMPLETED`));

    await MomentStore.getOldMoment(assignment.id);
    setCurrentAssignment(MomentStore.oldMoment);
  };

  const changeAnswer = (e: any) => {
    setValue(e.currentTarget.value);
  };

  const getChoices = () => {
    return (
      <form>
        <div className={styles.Inputs}>
          {currentAssignment.poll?.answers.map((answer: PollAnswerInstance, key: number) => (
            <div key={key} className={styles.Radio}>
              {currentAssignment.isDone && (
                <>
                  <div className={styles.RadioBackground} style={{ width: `${answer.results}%` }}></div>
                  <div className={styles.RadioPercentage}>{answer.results}%</div>
                </>
              )}

              <input
                id={`A${answer.id}`}
                name="answer"
                disabled={currentAssignment.canFinish}
                type="radio"
                defaultChecked={answer.selected}
                value={answer.id}
                onChange={changeAnswer}
              />
              <label htmlFor={`A${answer.id}`}>{answer.contents}</label>
            </div>
          ))}
        </div>
      </form>
    );
  };

  const getArticles = () => {
    if (currentAssignment.poll?.getAnswer?.articles && currentAssignment.poll.getAnswer.articles.length > 0) {
      return (
        <div className={styles.Articles}>
          <h4>{t("ASSIGNMENT.POLL.RECOMMEND")}</h4>

          <div className="row">
            {currentAssignment.poll?.getAnswer?.articles?.map((article: ArticleInstance) => (
              <div key={article.id} className="col-lg-4 col-md-4 mb-md">
                <CardArticle article={article} />
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  if (assignment && assignment.poll) {
    return (
      <div className={styles.Text}>
        <Circle primary dashed x={85} y={30} r={90} />
        <Circle secondary dashed x={95} y={50} r={50} />

        <div className={styles.Wrapper}>
          <h4>{assignment.poll.question}</h4>

          {getChoices()}
          {getArticles()}
        </div>

        <div className="btn-row">
          <Button onClick={onSubmit} tertiary disabled={assignment.canFinish}>
            {t(`ASSIGNMENT.${assignment.category}.DONE`)}
          </Button>
        </div>
      </div>
    );
  }

  return <></>;
};

export default observer(Poll);
