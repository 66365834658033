import ARTICLES from "./articles.json";
import DASHBOARD from "./dashboard.json";
import DEFAULT from "./default.json";
import DIGITAL_QUESTIONNAIRE from "./digitalQuestionnaire.json";
import FILES from "./files.json";
import LOGIN from "./login.json";
import OMISSION from "./omission.json";
import REGISTER from "./register.json";

const data = {
  translation: {
    DEFAULT,
    LOGIN,
    DASHBOARD,
    ARTICLES,
    OMISSION,
    REGISTER,
    FILES,
    DIGITAL_QUESTIONNAIRE,
  },
};

export default data;
