import cn from "classnames";
import React, { CSSProperties, ReactElement } from "react";

import { StackListItem, StackListItemProps } from "./internal/components";
import styles from "./StackList.module.scss";

interface StackListProps {
  className?: string;
  style?: CSSProperties;
  children: ReactElement<StackListItemProps> | ReactElement<StackListItemProps>[];
}

const StackListRoot = ({ className, ...props }: StackListProps) => {
  return <div className={cn(styles.StackList, className)} {...props} />;
};

export const StackList = Object.assign(StackListRoot, { Item: StackListItem });
