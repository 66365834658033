import { applySnapshot, flow, getSnapshot, Instance, types } from "mobx-state-tree";

import { CoreRootStore } from "@Core/stores";

import RoleStore from "./RoleStore/RoleStore";

let initialState = {};

const OOMRootStoreModel = types
  .model("OOMStoreModel", {
    RoleStore: RoleStore,

    isLoading: false,
    isLoaded: false,
  })
  .actions((self) => ({
    afterCreate() {
      initialState = getSnapshot(self);
    },

    reset() {
      applySnapshot(self, initialState);
    },

    load: flow(function* () {
      if (self.isLoading === false) {
        self.isLoading = true;

        if (CoreRootStore.UserStore.isAuthenticated) {
          yield Promise.all([self.RoleStore.load()]);

          self.isLoading = false;
        }

        self.isLoaded = true;
      }
    }),
  }));

const OOMRootStore = OOMRootStoreModel.create({
  RoleStore: {
    isLoading: false,
    isFinished: false,
  },
});

export interface OOMRootStoreInstance extends Instance<typeof OOMRootStore> {}

export default OOMRootStore;
