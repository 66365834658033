import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Block } from "@Core/components/UI";
import { ProfileForm } from "@Core/forms";
import { ProfileFormValues } from "@Core/forms/ProfileForm/ProfileForm.props";
import { UserService } from "@Core/services";
import { useCoreStores } from "@Core/stores";

import { Page } from "@PNI/components/Layouts";

const ProfilePageProfileForm = () => {
  const { t } = useTranslation();
  const { UserStore } = useCoreStores();

  if (UserStore.user) {
    const initialValues: ProfileFormValues = {
      email: UserStore.user.email,
      first_name: UserStore.user.first_name,
      last_name: UserStore.user.last_name,
      password: "",
      password_confirmation: "",
      phone_number: UserStore.user.phone_number,
    };
    const onSubmit = async (values: ProfileFormValues) => {
      const response = await UserService.save(values);
      UserStore?.update(values);

      return response;
    };

    return (
      <Block name={t("SHARED.MY_PROFILE")}>
        <ProfileForm initialValues={initialValues} onSubmit={onSubmit} />
      </Block>
    );
  }

  return <></>;
};

const ProfilePage = () => {
  const { t } = useTranslation();

  const breadcrumbs = [{ name: t("SHARED.MY_PROFILE") }];

  return (
    <Page breadcrumbs={breadcrumbs}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <ProfilePageProfileForm />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default observer(ProfilePage);
