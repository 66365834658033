import { PDFDownloadLink } from "@react-pdf/renderer";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Button } from "@Core/components/UI";
import { MatomoCategory } from "@Core/interfaces";

import { usePNIStores } from "@PNI/stores";

import { PDF } from "./internal/components";
import { ReportProps } from "./Report.props";

export const Report = observer(({ isDisabled = false, ...props }: ReportProps) => {
  const { t } = useTranslation();
  const { FileStore } = usePNIStores();

  if (isDisabled) {
    return (
      <Button iconLeft="fas fa-download" tertiary disabled={isDisabled} {...props}>
        {t("FILES.REPORT.BUTTON.DOWNLOAD")}
      </Button>
    );
  }

  return (
    <PDFDownloadLink document={<PDF t={t} />} fileName={t("FILES.REPORT.PDF", { id: FileStore.file?.getId })}>
      {({ loading }) => (
        <Button iconLeft="fas fa-download" tertiary {...props} data-matomo-category={MatomoCategory.FileReport}>
          {loading ? "..." : t("FILES.REPORT.BUTTON.DOWNLOAD")}
        </Button>
      )}
    </PDFDownloadLink>
  );
});
