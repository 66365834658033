import axios from "@Core/axios";

export enum TOKEN_STATUS {
	TOKEN_STATUS_VALID = "VALID",
	TOKEN_STATUS_EXPIRED = "EXPIRED",
	TOKEN_STATUS_PROCESSED = "PROCESSED",
	STATUS_HANDLED_BY_PHONE = "STATUS_HANDLED_BY_PHONE",
	STATUS_CANCELLED = "CANCELLED",
}

interface ValidateEmployeeRequest {
	token: string;
	date_of_birth: string;
	zipcode: string;
}

interface ValidateSupervisorRequest {
	token: string;
	sms_token: string;
	code: string;
}

interface ValidateTokenRequest {
	token: string;
}

export type ValidateUserRequest =
	| ValidateEmployeeRequest
	| ValidateSupervisorRequest
	| ValidateTokenRequest;

class QuestionnaireTokenService {
	LOCAL_STORAGE_TOKEN = "REACT_APP_X-TOKEN";

	validateToken = async (token: string): Promise<null> => {
		const data = {
			params: {
				token: token,
			},
		};

		const response = await axios.post(`questionnairetokens/validate`, {}, data);

		if (response && response.data) {
			return response.data;
		}

		return null;
	};

	closeAbsence = async (): Promise<null> => {
		const response = await axios.post(`questionnairetokens/close/absence`);

		if (response && response.data) {
			return response.data;
		}

		return null;
	};

	validateUser = async (request: ValidateUserRequest): Promise<any> => {
		const response = await axios.post(`questionnairetokens/token`, request);

		if (response && response.data) {
			this.setToken(response.data.token);

			return response.data;
		}

		return null;
	};

	sendSmsCode = async (token: string): Promise<any> => {
		const response = await axios.post(`questionnairetokens/verify`, { token });

		return response.data;
	};

	me = async (): Promise<any> => {
		const response = await axios.get("questionnairetokens/me");

		if (response && response.data) {
			return response.data;
		}

		return null;
	};

	setToken = (token: string): void => {
		localStorage.setItem(this.LOCAL_STORAGE_TOKEN, token);
	};

	getToken = (): string | null => {
		return localStorage.getItem(this.LOCAL_STORAGE_TOKEN);
	};

	logout = (): void => {
		localStorage.removeItem(this.LOCAL_STORAGE_TOKEN);
	};
}

export default new QuestionnaireTokenService();
