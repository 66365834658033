import React from "react";

import styles from "./Blockquote.module.scss";

type Props = {
  body: string;
  author?: string;
};

const Blockquote = ({ body, author }: Props) => (
  <div className="row">
    <div className="col-xl-8 col-lg-9 col-md-12">
      <blockquote className={styles.Blockquote}>
        <p>{body}</p>
        {author && <cite>{author}</cite>}
      </blockquote>
    </div>
  </div>
);

export default Blockquote;
