import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Modal } from "@Core/components/Layouts";
import { Button } from "@Core/components/UI";
import { useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";
import { useCoreStores } from "@Core/stores";

import { usePNIStores } from "@PNI/stores";

import { FilePageHeaderCloseFileModalProps } from "./FilePageHeaderCloseFileModal.props";

export const FilePageHeaderCloseFileModal = ({ isVisible, toggle }: FilePageHeaderCloseFileModalProps) => {
  const { t } = useTranslation();
  const { UserStore } = useCoreStores();
  const { FileStore } = usePNIStores();
  const trackEvent = useMatomo();

  const handleSubmit = async () => {
    try {
      if (!FileStore.file?.absence_finished_at) {
        await FileStore.file?.closeAbsence();
      }

      await FileStore.file?.close();

      trackEvent(MatomoCategory.File, t("FILES.MODALS.CLOSE_FILE.SUCCESS"));

      await Promise.all([UserStore.load(), FileStore.load()]);

      toast.success(t("FILES.MODALS.CLOSE_FILE.SUCCESS"));
    } catch (e) {
      toast.error(t("FILES.MODALS.CLOSE_FILE.ERROR"));
    } finally {
      toggle();
    }
  };

  return (
    <Modal
      title={t("FILES.MODALS.CLOSE_FILE.POPUP_TITLE")}
      isVisible={isVisible}
      hide={toggle}
      data-matomo-category={MatomoCategory.File}
    >
      <p>{t("FILES.MODALS.CLOSE_FILE.POPUP_TEXT")}</p>

      <Button primary onClick={handleSubmit}>
        {t("FILES.MODALS.CLOSE_FILE.POPUP_BUTTON")}
      </Button>
    </Modal>
  );
};
