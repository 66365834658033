import cn from "classnames";
import React from "react";

import styles from "./Tag.module.scss";

export interface TagProps {
  name: string;
  slug?: string;
  large?: boolean;
  className?: string;
  active?: boolean;
  onClick?: () => void;
  icon?: string;
  variant?: "light";
}

export const Tag = ({ name, large, active, className, onClick, icon, variant }: TagProps) => (
  <div
    className={cn(styles.Tag, className, {
      [styles.isLarge]: large,
      [styles.isActive]: active,
      [styles.hasIcon]: !!icon,
      [styles.hasOnClick]: !!onClick,
      [styles[`Tag--variant-${variant}`]]: !!variant,
    })}
    onClick={onClick}
  >
    {icon && <i className={`fas fa-${icon}`} />}

    {name}
  </div>
);
