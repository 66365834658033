import cn from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next/";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import { Advisor } from "@Core/components/Components";
import { Button } from "@Core/components/UI";
import { UserInstance } from "@Core/models/BaseUserModel";
import { ContactInstance } from "@Core/models/ContactModel";

import styles from "./ContactList.module.scss";

type Props = {
  contacts?: (UserInstance | ContactInstance)[];
  imageLarge?: boolean;
  card?: boolean;
  items: number;
};

SwiperCore.use([Navigation, Pagination]);

const ContactList = ({ contacts, items, card, imageLarge }: Props) => {
  const { t } = useTranslation();
  const [, setRender] = useState(false);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const paginationRef = useRef(null);

  if (contacts) {
    return (
      <div
        className={cn(styles.Container, {
          [styles["Container--Card"]]: !card,
        })}
      >
        <Swiper
          spaceBetween={20}
          slidesPerView={items}
          allowTouchMove={false}
          translate="no"
          centeredSlides={false}
          navigation={{
            prevEl: prevRef.current || null,
            nextEl: nextRef.current || null,
            disabledClass: styles.ButtonDisabled,
          }}
          pagination={{
            bulletActiveClass: styles.BulletActive,
            bulletClass: styles.Bullet,
            el: paginationRef.current || null,
          }}
          className={styles.Swiper}
          onInit={(swiper) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            swiper.params.navigation.prevEl = prevRef.current;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            swiper.params.navigation.nextEl = nextRef.current;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            swiper.params.pagination.el = paginationRef.current;
            // swiper.navigation.update();
            setRender(true);
          }}
          breakpoints={{
            0: {
              slidesPerView: Math.min(items, 1),
            },
            576: {
              slidesPerView: Math.min(items, 2),
            },
            992: {
              slidesPerView: Math.min(items, 3),
            },
            1200: {
              slidesPerView: Math.min(items, 4),
            },
          }}
        >
          {contacts.map((contact) => (
            <SwiperSlide key={contact.id}>
              <Advisor showBackgroundCircle advisor={contact} imageLarge={imageLarge} card={card} />
            </SwiperSlide>
          ))}
        </Swiper>

        {contacts.length > 1 && (
          <div className={styles.Pagination}>
            <div ref={prevRef}>
              <Button primary link hover iconLeft="fa-long-arrow-left">
                {t("SHARED.PREVIOUS")}
              </Button>
            </div>

            <div ref={nextRef}>
              <Button primary link hover iconRight="fa-long-arrow-right">
                {t("SHARED.NEXT")}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return <></>;
};

export default ContactList;
