import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { QuestionnaireFor } from "@Core/constants/ENUMS";
import { BaseModel } from "@Core/models";

export const QuestionnaireModel = BaseModel.props({
  title: types.string,
  text: types.maybeNull(types.string),
  thanks_text: types.maybeNull(types.string),
  information: types.maybeNull(types.string),
  allow_download: false,
  show_section_progress: false,
  for: types.maybeNull(types.enumeration<QuestionnaireFor>(Object.values(QuestionnaireFor))),
});

export interface QuestionaireInstance extends Instance<typeof QuestionnaireModel> {}
export type QuestionaireSnapshot = SnapshotIn<typeof QuestionnaireModel>;
