import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Loader, Pagination } from "@Core/components/UI";
import { PAGE_SIZE } from "@Core/constants/DEFAULT";

import { PreprArticleCard } from "@PNI/components/Containers";

import { useArticles } from "../../hooks";
import styles from "./ArticlesArchiveList.module.scss";
import { ArticlesArchiveListProps } from "./ArticlesArchiveList.props";

export const ArticlesArchiveList = ({ filter }: ArticlesArchiveListProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { articles, total, loading, loaded, error } = useArticles(page, filter);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  return (
    <div
      className={cn(styles.ArticlesArchiveList, {
        [styles.isLoading]: loading && loaded,
      })}
    >
      <div className="row">
        {loading && !loaded && <Loader width={50} color="#56bbad" className="mt-md" />}

        {error && !loaded && <div className="col-lg-12">{t("ARTICLES.ARCHIVE.ERROR")}</div>}

        {articles && articles.length === 0 && loaded && <div className="col-lg-12">{t("ARTICLES.ARCHIVE.NONE")}</div>}

        {articles && (
          <>
            {articles.map((article) => {
              return (
                <div key={article._id} className="col-xl-4 col-lg-6 col-md-6 mb-md">
                  <PreprArticleCard article={article} />
                </div>
              );
            })}

            <div className="col-12">
              <Pagination pageInitial={page} pageCount={total / PAGE_SIZE} onPageChange={setPage} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
