import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { Assignment } from "@LNI/components/Components";
import { Page } from "@LNI/components/Layouts";
import { AssignmentInstance, MomentInstance } from "@LNI/models";
import { useLNIStores } from "@LNI/stores";

const MomentPage = () => {
  const { t } = useTranslation();
  const breadcrumbs = [{ name: t("MOMENTS.MY_MOMENT") }];
  const { moment } = useParams();
  const history = useHistory();
  const { MomentStore } = useLNIStores();

  const [current, setCurrent] = useState<AssignmentInstance | MomentInstance>();

  useEffect(() => {
    if (moment) {
      (async () => {
        try {
          await MomentStore.getOldMoment(moment);
          setCurrent(MomentStore.oldMoment);
        } catch (e) {
          history.push("");
        }
      })();
    } else {
      setCurrent(MomentStore.moment);
    }
  }, [MomentStore, history, moment]);

  if (current) {
    return (
      <Page breadcrumbs={breadcrumbs}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">{<Assignment assignment={current} />}</div>
          </div>
        </div>
      </Page>
    );
  }

  return <></>;
};

export default observer(MomentPage);
