import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Questionnaire } from "@Core/components/Components";
import { useCoreStores } from "@Core/stores";
import { parseHTML } from "@Core/utilities";

import { Page } from "@OOM/components/Layouts";
import { useOOMStores } from "@OOM/stores";

const QuestionnaireInternalPage = () => {
  const { t } = useTranslation();
  const { questionnaire } = useParams();
  const history = useHistory();

  const { RoleStore } = useOOMStores();
  const { UserStore, QuestionnaireStore } = useCoreStores();

  const [isLoaded, setIsLoaded] = useState(false);

  const breadcrumbs = [
    {
      name: QuestionnaireStore.questionnaire?.questionnaire.title,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        await QuestionnaireStore.load(parseInt(questionnaire));

        setIsLoaded(true);
      } catch (e) {
        history.push("");
      }
    })();
  }, [QuestionnaireStore, history, questionnaire]);

  const onCompletion = () => {
    toast.success(t("FILES.COMPLETED.SUCCESS"));

    RoleStore.load();
  };

  const getQuestionnaire = () => {
    if (QuestionnaireStore.questionnaire && UserStore.user) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="mb-md">
                <h2 className="color-primary">{QuestionnaireStore.questionnaire.questionnaire.title}</h2>

                {parseHTML(QuestionnaireStore.questionnaire.questionnaire.information)}
              </div>

              <Questionnaire id={questionnaire} block onCompletion={() => onCompletion()} />
            </div>
          </div>
        </div>
      );
    }
  };

  return <Page breadcrumbs={breadcrumbs}>{isLoaded && getQuestionnaire()}</Page>;
};

export default observer(QuestionnaireInternalPage);
