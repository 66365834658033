import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { useCoreStores } from "@Core/stores";

import { Page } from "@PNI/components/Layouts";
import { usePNIStores } from "@PNI/stores";

import { FilePageWrapper } from "../internal/components";
import { OtherSteps } from "./internal/components/OtherSteps/OtherSteps";
import { RecommendedAbsenceType } from "./internal/components/RecommendedAbsenceType/RecommendedAbsenceType";
import { RecommendedArticles } from "./internal/components/RecommendedArticles/RecommendedArticles";
import { RecommendedSteps } from "./internal/components/RecommendedSteps/RecommendedSteps";

const FilePageAdvice = () => {
  const { t } = useTranslation();
  const { file } = useParams<{ file: string }>();
  const history = useHistory();

  const { UserStore } = useCoreStores();
  const { FileStore } = usePNIStores();

  const breadcrumbs = [
    {
      name: t("FILES.PAGE_TITLE", {
        id: FileStore.file?.getId,
      }),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        await FileStore.getFile(file, true);
      } catch (e) {
        history.push("");
      }
    })();
  }, [file, FileStore, history]);

  return (
    <Page breadcrumbs={breadcrumbs}>
      {FileStore.file && UserStore.user && (
        <FilePageWrapper>
          <RecommendedSteps className="mb-md" />

          <OtherSteps className="mb-md" />

          <RecommendedAbsenceType className="mb-md" />

          <RecommendedArticles />
        </FilePageWrapper>
      )}
    </Page>
  );
};

export default observer(FilePageAdvice);
