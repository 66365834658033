/* eslint-disable react/display-name */
import React from "react";

import { MatomoProps } from "@Core/interfaces";

export const withMatomo = <T extends any>(Component: React.ComponentType<T>) => {
  return function (props: MatomoProps & any) {
    const handleClick = () => {
      if (props["data-matomo-category"]) {
        if (process.env.NODE_ENV === "development") {
          console.log("Matomo: trackEvent:", {
            category: props["data-matomo-category"],
            action: props["data-matomo-action"] || typeof props.children === "string" ? props.children : undefined,
            name: props["data-matomo-name"],
          });
        }

        window._paq.push([
          "trackEvent",
          props["data-matomo-category"],
          props["data-matomo-action"] || typeof props.children === "string" ? props.children : undefined,
          props["data-matomo-name"],
        ]);
      }

      props.onClick?.();
    };

    return <Component {...props} onClick={handleClick} />;
  };
};
