import React, { useCallback } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Login } from "@Core/components/Layouts";
import { ResendInviteForm, ResendInviteFormValues } from "@Core/forms";
import { RegistrationService } from "@Core/services";

const Resend = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleSubmit = useCallback((values: ResendInviteFormValues) => {
    return RegistrationService.resendInvite(values);
  }, []);

  const handleSuccess = useCallback(() => {
    toast.success(t("LOGIN.RESEND.SUCCESS"));
    history.push("/inloggen");
  }, []);

  return (
    <Login
      title={t("LOGIN.RESEND.TITLE")}
      description={t("LOGIN.RESEND.SUBTITLE", {
        platform: t("DEFAULT.SITE_NAME"),
      })}
    >
      <ResendInviteForm onSubmit={handleSubmit} onSuccess={handleSuccess} />
    </Login>
  );
};

export default Resend;
