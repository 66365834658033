import axios from "@Core/axios";

class BaseService<T> {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  one = async (id: string): Promise<T> => {
    const response = await axios.get(`${this.endpoint}/${id}`);
    return response.data;
  };

  list = async (): Promise<T> => {
    const response = await axios.get(this.endpoint);
    return response.data;
  };

  create = async (model: T): Promise<T> => {
    const response = await axios.post(this.endpoint, model);
    return response.data;
  };

  update = async (id: string, data: Partial<T>): Promise<T> => {
    const response = await axios.post(`${this.endpoint}/${id}`, data);
    return response.data;
  };

  delete = async (id: string): Promise<T> => {
    const response = await axios.delete(`${this.endpoint}/${id}`);
    return response.data;
  };
}

export default BaseService;
