import { format } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next/";

import { Button } from "@Core/components/UI";
import { CompanySearchType } from "@Core/constants/ENUMS";
import { MatomoCategory } from "@Core/interfaces";

import GenerateFileFormProps, { GenerateFileFormValuesUser } from "./GenerateFileForm.props";
import { GenerateFileFormOther, GenerateFileFormSearch, GenerateFileFormUser } from "./internal/components";

export const GenerateFileForm = ({ onSubmit, onSuccess }: GenerateFileFormProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [employee, setEmployee] = useState<GenerateFileFormValuesUser>();
  const [supervisor, setSupervisor] = useState<GenerateFileFormValuesUser>();
  const [employeeId, setEmployeeId] = useState<number>();
  const [supervisorId, setSupervisorId] = useState<number>();

  const handleSubmit = (values: any) => {
    employeeId && (values.employee_id = employeeId);
    employee && (values.employee = employee);

    supervisorId && (values.supervisor_id = supervisorId);
    supervisor && (values.supervisor = supervisor);

    if (values.absence_started_at) {
      values.absence_started_at = format(new Date(values.absence_started_at), "yyyy-MM-dd").toString();
    }

    if (values.employee?.date_of_birth) {
      values.employee.date_of_birth = format(new Date(values.employee.date_of_birth), "yyyy-MM-dd").toString();
    }

    return onSubmit(values);
  };

  return (
    <>
      {step === 0 && (
        <>
          <GenerateFileFormSearch
            type={CompanySearchType.EMPLOYEE}
            onSubmit={setEmployeeId}
            onSuccess={() => setStep(2)}
          />

          <hr />

          <Button
            data-matomo-category={MatomoCategory.Dashboard}
            block
            primary
            className="mt-sm"
            onClick={() => setStep(1)}
          >
            {t("FILES.GENERATE.EMPLOYEE.MANUAL")}
          </Button>
        </>
      )}

      {step === 1 && (
        <GenerateFileFormUser type={CompanySearchType.EMPLOYEE} onSubmit={setEmployee} onSuccess={() => setStep(2)} />
      )}

      {step === 2 && (
        <>
          <GenerateFileFormSearch
            type={CompanySearchType.SUPERVISOR}
            onSubmit={setSupervisorId}
            onSuccess={() => setStep(4)}
          />

          <hr />

          <Button
            data-matomo-category={MatomoCategory.Dashboard}
            block
            primary
            className="mt-sm"
            onClick={() => setStep(3)}
          >
            {t("FILES.GENERATE.SUPERVISOR.MANUAL")}
          </Button>
        </>
      )}

      {step === 3 && (
        <GenerateFileFormUser
          type={CompanySearchType.SUPERVISOR}
          onSubmit={setSupervisor}
          onSuccess={() => setStep(4)}
        />
      )}

      {step === 4 && <GenerateFileFormOther onSubmit={handleSubmit} onSuccess={onSuccess} />}
    </>
  );
};
