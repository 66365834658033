import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";

import styles from "./Countdown.module.scss";

type Props = {
  from: Date;
  to: Date;
  onCompletion?: () => void;
};

const Loader = ({ from, to, onCompletion }: Props) => {
  const [percentage, setPercentage] = useState(0);
  const [count, setCount] = useState(0);
  const [label, setLabel] = useState("");

  const calculate = useCallback(() => {
    const _from = from.getTime();
    const _to = to.getTime();

    const now = new Date().getTime();
    const p = 1 - (now - _from) / (_to - _from);

    const days = differenceInDays(_to, now);

    setCount(days);
    setLabel(days <= 1 ? "dag" : "dagen");

    if (days < 1) {
      const hours = differenceInHours(_to, now);

      if (hours >= 1) {
        setLabel(hours === 1 ? "uur" : "uren");
        setCount(hours);
      } else {
        const minutes = differenceInMinutes(_to, now);

        setLabel("minuten");
        setCount(minutes + 1);

        if (minutes <= 0) {
          const seconds = differenceInSeconds(_to, now);

          setLabel("seconden");
          setCount(seconds);
        }
      }
    }

    setPercentage(p);
  }, [from, to]);

  useEffect(() => {
    calculate();
  }, [calculate]);

  useEffect(() => {
    const timeout = setTimeout(() => calculate(), 1000);

    if (percentage < 0) {
      clearTimeout(timeout);

      if (onCompletion) {
        onCompletion();
      }

      setPercentage(0);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [percentage, calculate, onCompletion]);

  return (
    <div className={styles.Countdown}>
      <svg id="svg" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle r="32" cx="40" cy="40" fill="transparent"></circle>

        <circle
          className={styles.Percentage}
          r="35"
          cx="40"
          cy="40"
          fill="transparent"
          strokeDasharray={2 * Math.PI * 35}
          strokeDashoffset={2 * Math.PI * 35 * percentage}
        ></circle>
      </svg>

      <div className={styles.Center}>
        <i className="far fa-clock color-quaternary"></i>
        <strong className="d-block font-size-sm">{count}</strong>
      </div>

      <div className={styles.Content}>
        <strong>{label}</strong>
        resterend
      </div>
    </div>
  );
};

export default Loader;
