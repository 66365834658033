import cn from "classnames";
import React from "react";

import styles from "./Loader.module.scss";

type Props = {
  width?: number;
  className?: string;
  color?: "#ffffff" | "#56bbad" | "none";
};

const Loader = ({ width, color = "#ffffff", className }: Props) => {
  const style = {
    width: width ? width : 20,
  };

  return (
    <div className={cn(styles.Loader, className)} style={style}>
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20" fill="none" stroke={color} strokeWidth="4" strokeMiterlimit="10" />
      </svg>
    </div>
  );
};

export default Loader;
