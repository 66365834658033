import { format, parseISO } from "date-fns";
import { applySnapshot, flow, Instance, SnapshotIn, types } from "mobx-state-tree";

import {
  UserQuestionnaireQuestionType,
  UserQuestionnaireStatus,
  UserQuestionnaireStepType,
} from "@Core/constants/ENUMS";
import { BaseModel } from "@Core/models";
import { QuestionnaireService } from "@Core/services";

import { QuestionnaireModel } from "../QuestionnaireModel";
import { UserModel } from "../UserModel";
import { UserQuestionnaireSectionsModel } from "./UserQuestionnaireSectionsModel";
import { UserQuestionnaireStepModel } from "./UserQuestionnaireStepModel";
import { UserQuestionnaireThemeScoreModel } from "./UserQuestionnaireThemeScoreModel";

export const UserQuestionnaireModel = BaseModel.props({
  id: types.number,
  assignment_id: types.maybeNull(types.number),
  questionnaire_id: types.number,
  previous_id: types.maybeNull(types.union(types.string, types.number)),
  completed_at: types.maybeNull(types.string),
  status: types.enumeration<UserQuestionnaireStatus>(Object.values(UserQuestionnaireStatus)),
  current_step: types.maybeNull(UserQuestionnaireStepModel),

  progressed_steps: types.array(UserQuestionnaireStepModel),
  questionnaire: QuestionnaireModel,
  sections: types.array(UserQuestionnaireSectionsModel),
  theme_scores: types.maybeNull(types.array(UserQuestionnaireThemeScoreModel)),
  user: types.maybeNull(UserModel),
})
  .actions((self) => ({
    start: flow(function* () {
      const response = yield QuestionnaireService.start(self.id, self.assignment_id ? self.assignment_id : undefined);
      applySnapshot(self, response);
    }),

    finish: flow(function* () {
      const response = yield QuestionnaireService.finish(self.id);

      self.progressed_steps = response.progressed_steps;
      self.status = response.status;
      self.completed_at = response.completed_at;
    }),

    next: flow(function* (answer: any) {
      if (self.current_step) {
        const question = self.current_step?.question;

        if (self.current_step.type === UserQuestionnaireStepType.QUESTION && question) {
          const value = `${answer[`S${self.current_step.id}Q${question.id}`]}`;

          let valueFormatted = null;

          switch (question.type) {
            case UserQuestionnaireQuestionType.Single:
            case UserQuestionnaireQuestionType.Multiple:
            case UserQuestionnaireQuestionType.SingleDropdown:
            case UserQuestionnaireQuestionType.Statement:
            case UserQuestionnaireQuestionType.Scale:
            case UserQuestionnaireQuestionType.ScaleEmoji:
            case UserQuestionnaireQuestionType.ScaleSlider:
              valueFormatted = value.split(",").map((value: any) => {
                const open_input = answer[`Q${question.id}A${value}_OPEN`];

                if (open_input) {
                  return {
                    id: value,
                    open_input: open_input,
                  };
                }

                return {
                  id: value,
                };
              });
              break;
            case UserQuestionnaireQuestionType.Date:
              valueFormatted = format(parseISO(new Date(value).toISOString()), "yyyy-MM-dd");
              break;
            default:
              valueFormatted = Array.isArray(value) ? value.join(",") : value;
              break;
          }

          const response = yield QuestionnaireService.next(self.id, self.current_step.id, valueFormatted);
          applySnapshot(self, response);
        } else if (
          self.current_step.type === UserQuestionnaireStepType.SCORE ||
          self.current_step.type === UserQuestionnaireStepType.CONTENT
        ) {
          const response = yield QuestionnaireService.next(self.id, self.current_step.id);
          applySnapshot(self, response);
        }
      }
    }),

    previous: flow(function* () {
      if (self.current_step) {
        const response = yield QuestionnaireService.previous(self.id, self.previous_id);

        response.step.isCurrentStep = false;

        self.id = response.user_questionnaire_id;
        self.previous_id = response.previous;

        applySnapshot(self.current_step, response.step);
      }
    }),

    load: flow(function* () {
      const response = yield QuestionnaireService.load(self.id);

      applySnapshot(self, response);
    }),
  }))
  .views((self) => ({
    get hasPrevious(): boolean {
      return !(
        self.status === UserQuestionnaireStatus.Pending ||
        (self.previous_id === null && self.current_step?.isCurrentStep === false)
      );
    },

    get isPending(): boolean {
      return self.status === UserQuestionnaireStatus.Pending;
    },

    get isCompleted(): boolean {
      return self.status === UserQuestionnaireStatus.Completed;
    },

    get hasNext(): boolean {
      return true;
    },
  }));

export interface UserQuestionnaireInstance extends Instance<typeof UserQuestionnaireModel> {}
export type UserQuestionnaireSnapshot = SnapshotIn<typeof UserQuestionnaireModel>;
