import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";

import Form from "@Core/components/Form";
import { Button, CardRow } from "@Core/components/UI";
import { useMatomo } from "@Core/hooks";
import { IFieldProps, MatomoCategory } from "@Core/interfaces";
import { FilesInstance } from "@Core/models";
import { useCoreStores } from "@Core/stores";
import { formatDate } from "@Core/utilities";

import styles from "./FileList.module.scss";

type Props = {
  files: FilesInstance[];
  archive?: boolean;
  vos?: boolean;
};

export const FileList = observer(({ files, archive }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const trackEvent = useMatomo();
  const { UserStore } = useCoreStores();

  const itemsPerPage = 6;

  const [filteredFiles, setFilteredFiles] = useState<FilesInstance[]>(files);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(itemsPerPage);

  const field: IFieldProps = {
    name: "search",
    value: search,
    onChange: (value: any) => {
      setSearch(value.target.value);
    },
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (search) {
        trackEvent(MatomoCategory.Dashboard, t("FILES.SEARCH"), search);

        const found = files.filter(
          (file: FilesInstance) =>
            file.getId.includes(search) ||
            file.employee?.getName.toLowerCase().includes(search.toLowerCase()) ||
            file.supervisor?.getName.toLowerCase().includes(search.toLowerCase()),
        );

        setFilteredFiles(found);
      } else {
        setFilteredFiles(files);
      }

      setLimit(itemsPerPage);
    }, 250);

    return () => clearTimeout(debounce);
  }, [search, files]);

  const loadMore = () => {
    setLimit(limit + itemsPerPage);
  };

  if (files) {
    return (
      <div className={styles.Wrapper}>
        <div className={styles.Search}>
          <Form.Text id="search" field={field} icon="search" placeholder={t("FILES.SEARCH")} />
        </div>

        <div className={styles.Items}>
          {filteredFiles.slice(0, limit).map((file: FilesInstance) => {
            let title = `<i class="fas fa-user"></i>${file.employee?.getName || "-"} <br />`;

            if (file.supervisor) {
              title += `<i class="fas fa-user-tie"></i>${file.supervisor.getName}`;
            } else {
              title += `<span class="color-error-light"><i class="fas fa-exclamation-triangle"></i>${t(
                "FILES.EMPTY_SUPERVISOR",
              )}</span>`;
            }
            const data = [
              `#${file.getId}`,
              `<i class="far fa-clock"></i>${t("SHARED.DAYS", { count: file.duration })}`,
              `<i class="far fa-sign-in"></i>${formatDate("" + file.absence_started_at)}`,
            ];

            if (archive) {
              data.push(`<i class="far fa-sign-out"></i>${formatDate("" + file.deleted_at)}`);
            }

            return (
              <CardRow
                key={file.id}
                title={title}
                dataStrings={data}
                to={`/dossiers/${file.id}`}
                buttonPrimary={{
                  onClick: () => history.push(`/dossiers/${file.id}`),
                  title: t("FILES.OPEN_FILE"),
                }}
              />
            );
          })}

          {filteredFiles.length === 0 && (
            <CardRow icon="fa-plus" description={"" + t("FILES.NO_FILES")} dotted disabledLight />
          )}
        </div>

        {limit < filteredFiles.length && (
          <div className={styles.Load}>
            <Button
              data-matomo-category={MatomoCategory.Dashboard}
              primary
              iconRight="fa-long-arrow-down"
              onClick={() => loadMore()}
            >
              {t("FILES.LOAD_MORE")}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return <></>;
});
