import { applySnapshot, flow, getSnapshot, Instance, types } from "mobx-state-tree";

import { Platform } from "@Core/constants/ENUMS";
import QuestionnaireStore from "@Core/stores/QuestionnaireStore/QuestionnaireStore";
import UserStore from "@Core/stores/UserStore/UserStore";
import { getPlatform } from "@Core/utilities";

let initialState = {};

export const CoreRootStoreModel = types
  .model("CoreRootStoreModel", {
    UserStore: UserStore,
    QuestionnaireStore: QuestionnaireStore,

    platform: types.enumeration<Platform>(Object.values(Platform)),
    redirect: types.maybeNull(types.string),

    isLoggingIn: false,
    isLoading: false,
    isLoaded: false,
    isConfetti: false,
  })
  .actions((self) => ({
    load: flow(function* () {
      if (self.isLoading === false) {
        self.isLoading = true;

        yield self.UserStore.load();

        self.platform = getPlatform(self.UserStore.user?.account_type);

        self.isLoaded = true;
      }
    }),

    setIsLoggingIn(value: boolean) {
      self.isLoggingIn = value;
    },

    setConfetti(visible: boolean) {
      self.isConfetti = visible;
    },

    setRedirect(url: string) {
      self.redirect = url;
    },

    afterCreate() {
      initialState = getSnapshot(self);
    },

    reset() {
      applySnapshot(self, initialState);
    },
  }))
  .views((self) => ({
    get getPlatform(): Platform {
      return getPlatform(self.UserStore.user?.account_type);
    },
  }));

const CoreRootStore = CoreRootStoreModel.create({
  UserStore: {},
  QuestionnaireStore: {},

  platform: Platform.LNI,
});

export interface CoreRootStoreInstance extends Instance<typeof CoreRootStoreModel> {}

export default CoreRootStore;
