import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom";

import moment from "@Core/assets/images/illustrations/moment.png";
import onboarding_checkbox from "@Core/assets/images/illustrations/onboarding_checkbox.png";
import profile_complete from "@Core/assets/images/illustrations/profile_complete.png";
import { Questionnaire } from "@Core/components/Components";
import Form from "@Core/components/Form";
import { Block, Button } from "@Core/components/UI";
import { TagSnapshot } from "@Core/models";
import { UserQuestionnaireInstance } from "@Core/models";
import { ArticleService } from "@Core/services";
import { useCoreStores } from "@Core/stores";
import Yup from "@Core/validation";

import { useLNIStores } from "@LNI/stores";

import styles from "./Intake.module.scss";

type IntakeIntroductionProps = {
  proceed: () => void;
};

const IntakeIntroduction = ({ proceed }: IntakeIntroductionProps) => {
  const { t } = useTranslation();
  const { UserStore } = useCoreStores();

  return (
    <>
      <h2>
        {t("CHALLENGES.INTAKE.WELCOME")} {UserStore.user?.first_name}
        {t("CHALLENGES.INTAKE.COMPLETE_YOUR_PROFILE")}
      </h2>

      <p>{t("CHALLENGES.INTAKE.DESCRIPTION")}</p>

      <div className={styles.Buttons}>
        <div></div>
        <Button tertiary onClick={proceed}>
          {t("CHALLENGES.INTAKE.START_INTAKE")}
          <i className="far fa-long-arrow-right"></i>
        </Button>
      </div>
    </>
  );
};

type IntakeFormProps = {
  questionnaire: UserQuestionnaireInstance;
  proceed: () => void;
};

const IntakeForm = ({ questionnaire, proceed }: IntakeFormProps) => {
  const { OnboardingStore } = useLNIStores();

  const onCompletion = () => {
    OnboardingStore.didQuestionnaire();
    proceed();
  };

  return <Questionnaire id={questionnaire.id} onCompletion={onCompletion} />;
};

type IntakeChallengesProps = {
  challenges: any[];

  proceed: () => void;
};

const IntakeChallenges = ({ challenges, proceed }: IntakeChallengesProps) => {
  const { t } = useTranslation();
  const { OnboardingStore } = useLNIStores();

  const validationSchema = Yup.object().shape({
    challenges: Yup.array()
      /* eslint-disable no-template-curly-in-string */
      .min(1, 'MIN_CHALLENGES={"min":${min}}')
      /* eslint-disable no-template-curly-in-string */
      .max(3, 'MAX_CHALLENGES={"max":${max}}')
      .required(),
  });

  const initialValues = {
    challenges: challenges
      .map((challenge: any) => (challenge.pre_selected ? challenge.id.toString() : undefined))
      .filter((value) => value),
  };

  challenges.map((challenge: any) =>
    challenge.pre_selected ? (challenge.selected = true) : (challenge.selected = false),
  );

  const onSubmit = (values: { challenges: number[] }) => {
    OnboardingStore.setChallenges(values.challenges);
  };

  const onSuccess = () => {
    OnboardingStore.didChallenges();
    proceed();
  };

  return (
    <Form.Form
      identifier="INTAKE_CHALLENGES_FORM"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      <Form.Questionnaire.Question
        title={t("CHALLENGES.INTAKE.INTAKE_FORM_TITLE")}
        description={t("CHALLENGES.INTAKE.INTAKE_FORM_DESCRIPTION")}
      >
        <Form.Item
          id="challenges"
          errorOnUntouch
          render={(field) => <Form.Questionnaire.Card {...field} items={challenges} />}
        />
      </Form.Questionnaire.Question>

      <div className={styles.Buttons}>
        <div></div>
        <Button tertiary iconRight="long-arrow-right" type="submit">
          {t("CHALLENGES.INTAKE.CONFIRM")}
        </Button>
      </div>
    </Form.Form>
  );
};

type IntakeTagsProps = {
  tags: TagSnapshot[];

  proceed: () => void;
};

const IntakeTags = ({ tags, proceed }: IntakeTagsProps) => {
  const { t } = useTranslation();
  const { OnboardingStore, ChallengeStore, ArticleStore } = useLNIStores();

  const validationSchema = Yup.object().shape({
    tags: Yup.array().min(1, 'MIN_SUBJECTS={"min":${min}}').required(),
  });

  const onSubmit = async (values: { tags: string[] }) => {
    await ArticleService.setTags(values.tags);
    OnboardingStore.getChallenges();
  };

  const onSuccess = () => {
    OnboardingStore.didTags();
    OnboardingStore.didOnboarding();

    ChallengeStore.load();
    ArticleStore.load();

    proceed();
  };

  return (
    <Form.Form
      identifier="INTAKE_TAGS_FORM"
      initialValues={{}}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      <Form.Questionnaire.Question
        title={t("CHALLENGES.INTAKE.TAGS_TITLE")}
        description={t("CHALLENGES.INTAKE.TAGS_DESCRIPTION")}
      >
        <Form.Item id="tags" errorOnUntouch render={(field) => <Form.Questionnaire.Tag {...field} items={tags} />} />
      </Form.Questionnaire.Question>

      <div className={styles.Buttons}>
        <div></div>
        <Button tertiary iconRight="long-arrow-right" type="submit">
          {t("CHALLENGES.INTAKE.CONFIRM")}
        </Button>
      </div>
    </Form.Form>
  );
};

type IntakeCompleteProps = {
  challenges: {
    id: number;
    icon: string;
    title: string;
  }[];
};

const IntakeComplete = ({ challenges }: IntakeCompleteProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = () => {
    history.push("/");
  };

  return (
    <Form.Form identifier="INTAKE_COMPLETE_FORM" onSubmit={onSubmit}>
      <Form.Questionnaire.Question
        title={t("CHALLENGES.INTAKE.INTAKE_COMPLETE_TITLE")}
        description={t("CHALLENGES.INTAKE.INTAKE_COMPLETE_DESCRIPTION")}
      >
        <Form.Item
          id="challenges"
          errorOnUntouch
          render={(field) => <Form.Questionnaire.Card disabled={true} {...field} items={challenges} />}
        />
      </Form.Questionnaire.Question>

      <div className={styles.Buttons}>
        <div></div>
        <Button tertiary iconRight="long-arrow-right" type="submit">
          {t("CHALLENGES.INTAKE.TO_DASHBOARD")}
        </Button>
      </div>
    </Form.Form>
  );
};

const Intake = () => {
  const { t } = useTranslation();
  const { OnboardingStore, ChallengeStore, ArticleStore } = useLNIStores();
  const [step, setStep] = useState<number>();
  const [image, setImage] = useState<string>(moment);
  const history = useHistory();

  useEffect(() => {
    if (OnboardingStore.progress) {
      if (OnboardingStore.progress.didOnboarding) {
        history.push("/");
      }

      // Load Themes
      if (OnboardingStore.progress.didChallenges) {
        setStep(4);
        return;
      }

      // Load Challenges
      if (OnboardingStore.progress.didQuestionnaire) {
        setStep(3);
        return;
      }

      // Load Questionnaire
      if (!OnboardingStore.progress.didQuestionnaire) {
        setStep(1);
        return;
      }
    }
  }, [ArticleStore, OnboardingStore, ChallengeStore, OnboardingStore.progress, history]);

  useEffect(() => {
    if (step) {
      OnboardingStore.load();

      switch (step) {
        case 3:
        case 4:
          setImage(onboarding_checkbox);
          break;
        case 5:
          setImage(profile_complete);
          break;
        default:
          setImage(moment);
          break;
      }
    }
  }, [step, OnboardingStore]);

  const proceed = () => {
    if (step) {
      setStep(step + 1);
    }
  };

  return (
    <Block name={t("CHALLENGES.INTAKE.NAME")}>
      <div className="row">
        <div className="col-lg-4">
          <LazyLoad>
            <img className={styles.Image} src={image} alt="Afbeelding" />
          </LazyLoad>
        </div>

        <div className="col-lg-8" style={{ position: "static" }}>
          {step === 1 && <IntakeIntroduction proceed={proceed} />}

          {step === 2 && OnboardingStore.user_questionnaire && (
            <IntakeForm questionnaire={OnboardingStore.user_questionnaire} proceed={proceed} />
          )}

          {step === 3 && OnboardingStore.challenges && (
            <IntakeChallenges challenges={OnboardingStore.challenges} proceed={proceed} />
          )}

          {step === 4 && ArticleStore.hasTags && <IntakeTags tags={ArticleStore.tags} proceed={proceed} />}

          {step === 5 && OnboardingStore.challenges && <IntakeComplete challenges={OnboardingStore.challenges} />}
        </div>
      </div>
    </Block>
  );
};

export default observer(Intake);
