/* eslint-disable react/prop-types */
import React from "react";

import { Circle, Tag } from "@Core/components/UI";
import { PreprLabel } from "@Core/constants/ENUMS";
import {
  ArticleSnapshot,
  AssetsInstance,
  PublicationElementInstance,
  QuoteInstance,
  SpotifyInstance,
  TagSnapshot,
  TextInstance,
} from "@Core/models";

import styles from "./ArticlePage.module.scss";
import Banner from "./Banner/Banner";
import Blockquote from "./Blockquote/Blockquote";
import Heading from "./Heading/Heading";
import Image from "./Image/Image";
import Started from "./Started/Started";
import Text from "./Text/Text";

type Props = {
  article: ArticleSnapshot;
};

const ArticlePage = ({ article }: Props) => {
  const getBanner = () => (
    <Banner className={styles.Banner}>
      <Circle secondary dashed x={95} y={15} r={150} />
      <Circle primary x={-5} y={65} r={65} />

      <div className="p-relative">
        <div className={`${styles.Time} hidden-lg-up`}>
          <i className="far fa-clock"></i>
          {article.read_time} {article.read_time === 1 ? "minuut" : "minuten"}
        </div>

        <h1>{article.title}</h1>
        <p>{article.intro}</p>

        <div className={styles.BannerFooter}>
          <div className={styles.Time}>
            <i className="far fa-clock"></i>
            {article.read_time} {article.read_time === 1 ? "minuut" : "minuten"}
          </div>

          <div className={styles.Tags}>
            {article.keywords &&
              article.keywords.map((keyword: TagSnapshot, key: number) => <Tag key={key} name={keyword.body} />)}
          </div>
        </div>
      </div>
    </Banner>
  );

  const getContentSpotify = (item: SpotifyInstance) => {
    return (
      <div className={styles.Podcast}>
        <div key={item.id} className="row">
          <div className="col-12">
            <iframe
              title="SpotifyPodcast"
              src={`https://open.spotify.com/embed-podcast/show/${item.url}`}
              width="100%"
              height="auto"
              frameBorder="0"
              allow="encrypted-media"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const getContentText = (item: TextInstance) => {
    if (item.format) {
      return (
        <Heading key={item.id} size={item.format}>
          {item.body}
        </Heading>
      );
    }

    return (
      <Text key={item.id}>
        <div dangerouslySetInnerHTML={{ __html: getContentAbbreviation(item.body) }}></div>
      </Text>
    );
  };

  const getContentAsset = (item: AssetsInstance) => {
    const first = item.items[0];

    if (first.label === "Photo") {
      return <Image key={first.id} url={first.url} name={first.name} />;
    }
  };

  const getContentQuote = (item: QuoteInstance) => {
    return <Blockquote key={item.id} body={item.body} author={item.author} />;
  };

  const getContentPublicationElement = (item: PublicationElementInstance) => {
    return (
      <Text key={item.id} document={item}>
        <div dangerouslySetInnerHTML={{ __html: getContentAbbreviation(item.body) }}></div>
      </Text>
    );
  };

  const getContentAbbreviation = (content: string) => {
    const shortcodeRegex = /\[info([^\]]*)\]([\s\S]*?)\[\/info\]/g;
    const shortcodeNameRegex = /name="([\s\S]*?)"/g;

    return content.replace(shortcodeRegex, (match, index, body) => {
      if (index.match(shortcodeNameRegex)) {
        const regex = shortcodeNameRegex.exec(index);
        let title = "";

        if (regex) {
          title = regex[1];
        }

        return `
                <abbr>
                    <span>
                        ${title}
                        <span>i</span>
                    </span>

                    <span>
                        <span>
                            <span>i</span>
                            ${title.charAt(0).toUpperCase() + title.slice(1)}
                        </span>

                        <span>${body}</span>
                    </span>
                </abbr>
            `;
      }

      return match;
    });
  };

  const getContent = () => {
    if (article.content) {
      return (
        <div className={styles.Content}>
          {article.content.map((item) => {
            switch (item.label) {
              case PreprLabel.TEXT:
                return getContentText(item as TextInstance);
              case PreprLabel.SPOTIFY:
                return getContentSpotify(item as SpotifyInstance);
              case PreprLabel.ASSET:
                return getContentAsset(item as AssetsInstance);
              case PreprLabel.QUOTE:
                return getContentQuote(item as QuoteInstance);
              case PreprLabel.PUBLICATIONELEMENT:
                return getContentPublicationElement(item as PublicationElementInstance);
              default:
                return <></>;
            }
          })}
        </div>
      );
    }
  };

  const getActivation = () => {
    if (article.activation && (article.activation.tips || article.activation.steps)) {
      return (
        <div className={styles.Activation}>
          <div className="row">
            <div className="col-xl-3 hidden-lg-down"></div>

            <div className="col-xl-6 col-lg-8 col-md-12">
              <h2 className="hero hero--large">{article.activation.title}</h2>

              {article.activation.intro && <p className="light">{article.activation.intro}</p>}
            </div>
          </div>

          <div className="row justify-content-center">
            {getActivationTips()}
            {getActivationSteps()}
          </div>
        </div>
      );
    }
  };

  const getActivationTips = () => {
    if (article?.activation?.tips?.body) {
      return (
        <div className="col-lg-6">
          <Started
            icon={article.activation.tips.icon ? article.activation.tips.icon : "star"}
            title={article.activation.tips.title ? article.activation.tips.title : "Tips"}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: article.activation.tips.body,
              }}
            ></span>

            {article.activation.tips && article.activation.tips.links && (
              <div
                dangerouslySetInnerHTML={{
                  __html: article.activation.tips.links,
                }}
              ></div>
            )}
          </Started>
        </div>
      );
    }
  };

  const getActivationSteps = () => {
    if (article?.activation?.steps?.body) {
      return (
        <div className="col-lg-6">
          <Started
            icon={article.activation.steps.icon ? article.activation.steps.icon : "shoe-prints"}
            title={article.activation.steps.title ? article.activation.steps.title : "Stappenplan"}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: article.activation.steps.body,
              }}
            ></span>

            {article.activation.steps.links && (
              <div
                dangerouslySetInnerHTML={{
                  __html: article.activation.steps.links,
                }}
              ></div>
            )}
          </Started>
        </div>
      );
    }
  };

  if (article) {
    return (
      <div className={styles.Article}>
        {getBanner()}
        {getContent()}
        {getActivation()}
      </div>
    );
  }

  return <></>;
};

export default ArticlePage;
