import { useMatomo as useMatomoTracker } from "@datapunt/matomo-tracker-react";

import { MatomoCategory } from "@Core/interfaces";

export const useMatomo = () => {
  const matomo = useMatomoTracker();

  const trackEvent = (category: MatomoCategory, action: string, name?: string) => {
    console.log("Matomo: trackEvent:", { category, action, name });

    if (process.env.NODE_ENV === "development") {
    }

    matomo.trackEvent({
      category,
      action,
      name,
    });
  };

  return trackEvent;
};
