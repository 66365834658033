import { MODEL, PREPR_MODEL } from "@PNI/services";

export const getSingleQuery = (model: MODEL, fields: string, rules?: Record<string, unknown>): string => {
  const queryString = JSON.stringify(rules)
    .replace(/"([^(")"]+)":/g, "$1:")
    .slice(1, -1);

  return `
			query query${model} {
				${model} (${queryString}) {
					${PREPR_MODEL}
					${fields}
				}    
			}
		`;
};

export const getCollectionQuery = (model: MODEL, fields: string, rules?: Record<string, unknown>): string => {
  const queryString = JSON.stringify(rules)
    .replace(/"([^(")"]+)":/g, "$1:")
    .slice(1, -1);

  return `
		query query${model} {
			${model} (${queryString}) {
				total
				items {
					${PREPR_MODEL}
					${fields}
				}
			}    
		}
	`;
};
