import { flow, getRoot, Instance, types } from "mobx-state-tree";

import { UserQuestionnaireStatus } from "@Core/constants/ENUMS";
import { BaseModel, UserQuestionnaireModel } from "@Core/models";

import { OnboardingService } from "@LNI/services";
import LNIRootStore from "@LNI/stores/LNIRootStore";

const OnboardingStore = types
  .model({
    user_questionnaire: types.maybeNull(UserQuestionnaireModel),
    challenges: types.maybeNull(
      types.array(
        BaseModel.props({
          title: types.string,
          icon: types.string,
          selected: types.boolean,
          pre_selected: types.maybeNull(types.boolean),
        }),
      ),
    ),
    progress: types.maybeNull(
      types.model({
        didQuestionnaire: types.boolean,
        didChallenges: types.boolean,
        didTags: types.boolean,
        didOnboarding: types.boolean,
      }),
    ),
  })
  .actions((self) => ({
    load: flow(function* () {
      if (self.progress) {
        // Load Tags
        if (self.progress.didChallenges) {
          getRoot<typeof LNIRootStore>(self).ArticleStore.getTags();
        }

        // Load Challenges
        if (self.progress.didQuestionnaire && !self.challenges) {
          const challenges = yield OnboardingService.getChallenges();
          self.challenges = challenges.challenges;
        }

        // Load Questionnaire
        if (!self.progress.didQuestionnaire && !self.user_questionnaire) {
        }
      }
    }),

    setResult: flow(function* (questionId: number, answer: string) {
      yield OnboardingService.setResult(questionId, answer);
    }),

    getStatus: flow(function* () {
      const response = yield OnboardingService.getStatus();

      if (response) {
        if (response.user_questionnaire.status !== UserQuestionnaireStatus.Completed) {
          self.user_questionnaire = response.user_questionnaire;
        }

        self.progress = {
          didQuestionnaire: response.user_questionnaire.status === UserQuestionnaireStatus.Completed,
          didChallenges: response.has_challenges,
          didTags: response.has_article_tags,
          didOnboarding: response.isDone,
        };
      }
    }),

    getChallenges: flow(function* () {
      const response = yield OnboardingService.getChallenges();
      self.challenges = response.challenges;
    }),

    setChallenges: flow(function* (challenges: number[]) {
      yield OnboardingService.setChallenges(challenges);
      const response = yield OnboardingService.getChallenges();
      self.challenges = response.challenges;
    }),

    didQuestionnaire: function () {
      if (self.progress) {
        self.progress.didQuestionnaire = true;
      }
    },

    didChallenges: function () {
      if (self.progress) {
        self.progress.didChallenges = true;
      }
    },

    didTags: function () {
      if (self.progress) {
        self.progress.didTags = true;
      }
    },

    didOnboarding: function () {
      if (self.progress) {
        self.progress.didOnboarding = true;
      }
    },
  }));

export interface OnboardingStoreInstance extends Instance<typeof OnboardingStore> {}

export default OnboardingStore;
