import React from "react";
import { useTranslation } from "react-i18next";

import Form from "@Core/components/Form";
import { FileType } from "@Core/constants/ENUMS";
import { useBoolean } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";
import Yup from "@Core/validation";

import { GenerateFileFormOtherProps } from "./GenerateFileFormOther.props";

export const GenerateFileFormOther = ({ onSubmit, onSuccess }: GenerateFileFormOtherProps) => {
  const { t } = useTranslation();
  const { value: showDateInput, setValue: setShowDateInput } = useBoolean(false);

  const handleChange = (value: FileType) => {
    setShowDateInput(value === FileType.ABSENCE);
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(),
    absence_started_at: Yup.string().when("type", {
      is: (value: FileType) => value === FileType.ABSENCE,
      then: Yup.string().required(),
    }),
  });

  const initialValues = {
    absence_started_at: new Date().toDateString(),
  };

  return (
    <Form.Form
      initialValues={initialValues}
      identifier="GENERATE_FILE_FORM_OTHER"
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      submit={t("FILES.GENERATE.FORM.OTHER.SUBMIT")}
    >
      <Form.Item
        id="type"
        name={t("FILES.GENERATE.FORM.OTHER.TYPE")}
        render={(props) => (
          <Form.Select
            {...props}
            defaultValue=""
            onChange={handleChange}
            placeholder={t("FILES.GENERATE.FORM.OTHER.PLACEHOLDER_TYPE")}
            options={{
              [FileType.PREVENTIVE]: t("FILES.TYPE_PREVENTIVE"),
              [FileType.ABSENCE]: t("FILES.TYPE_ABSENCE"),
            }}
          />
        )}
      />

      {showDateInput && (
        <Form.Item
          id="absence_started_at"
          name={t("FILES.GENERATE.FORM.OTHER.ABSENCE_STARTED_AT")}
          render={(field) => <Form.Date maxDate={new Date()} {...field} />}
        />
      )}
    </Form.Form>
  );
};
