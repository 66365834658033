import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Frame } from "@Core/components/UI";

import { GuidelinesOverview } from "@PNI/components/Containers";
import { usePNIStores } from "@PNI/stores";

import { GuidelinesProps } from "./Guidelines.props";

export const Guidelines = observer(({ className }: GuidelinesProps) => {
  const { t } = useTranslation();
  const { FileStore } = usePNIStores();

  const guidelines = FileStore.file?.guidelines ?? [];

  return (
    <Frame
      title={t("FILES.GUIDELINES.TITLE")}
      className={className}
      variant={guidelines.length <= 0 ? "light" : undefined}
    >
      <Frame.Body padding="3">
        <GuidelinesOverview guidelines={guidelines} />
      </Frame.Body>
    </Frame>
  );
});
