import React from "react";

import { IFieldProps } from "@Core/interfaces";

import styles from "./Card.module.scss";

interface Answer {
  id: number;
  icon: string;
  title: string;
  selected?: boolean;
}

type Props = {
  id: string;
  name: string;
  field: IFieldProps;

  disabled?: boolean;
  items: Answer[];
};

const Card = ({ id, name, field, disabled, items }: Props) => {
  return (
    <div className={styles.Inputs}>
      <div className="row">
        {items.map((answer: Answer, key) => (
          <div key={key} className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div className={styles.Card}>
              <input
                disabled={disabled}
                id={`${answer.id}`}
                type="checkbox"
                defaultChecked={answer.selected}
                {...field}
                value={answer.id}
              />
              <label htmlFor={`${answer.id}`}>
                <i className={`far ${answer.icon}`}></i>
                <h5>{answer.title}</h5>
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
