import React from "react";
import { useTranslation } from "react-i18next";

import Form from "@Core/components/Form";
import { CompanySearchType } from "@Core/constants/ENUMS";
import { useCache } from "@Core/hooks";
import Yup, { validateEmail } from "@Core/validation";

import { GenerateFileFormUserProps } from "./GenerateFileFormUser.props";

export const GenerateFileFormUser = ({
  submit,
  onSubmit,
  onSuccess,
  initialValues,
  type,
}: GenerateFileFormUserProps) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object()
    .shape({
      email: Yup.string()
        .required()
        .email()
        .test(
          "unique",
          "STRING_UNIQUE_MAIL",
          useCache((value: string) => validateEmail(value, type, initialValues?.email)),
        ),

      phone_number: Yup.string().phone().required(),
      first_name: Yup.string().required(),
      last_name: Yup.string().required(),
    })
    .when(type, {
      is: CompanySearchType.EMPLOYEE,
      then: Yup.object().shape({
        zipcode: Yup.string().required(),
        date_of_birth: Yup.string().required(),
      }),
    });

  return (
    <Form.Form
      identifier="GENERATE_FILE_FORM_USER"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      submit={submit ?? t("FILES.GENERATE.FORM.USER.SUBMIT")}
    >
      <Form.Item
        id="email"
        name={t("FILES.GENERATE.FORM.USER.EMAIL")}
        render={(props) => <Form.Text {...props} icon="envelope" />}
      />
      <Form.Item
        id="phone_number"
        name={t("FILES.GENERATE.FORM.USER.PHONE")}
        render={(props) => <Form.Text {...props} placeholder="0612345678" icon="phone" />}
      />
      <Form.Item
        id="first_name"
        name={t("FILES.GENERATE.FORM.USER.FIRST_NAME")}
        render={(props) => <Form.Text {...props} icon="user-alt" />}
      />
      <Form.Item
        id="last_name"
        name={t("FILES.GENERATE.FORM.USER.LAST_NAME")}
        render={(props) => <Form.Text {...props} icon="user-alt" />}
      />
      {type === CompanySearchType.EMPLOYEE && (
        <>
          <Form.Item
            id="zipcode"
            name={t("FILES.GENERATE.FORM.USER.ZIPCODE")}
            render={(props) => <Form.Text {...props} icon="location-dot" />}
          />

          <Form.Item
            id="date_of_birth"
            name={t("FILES.GENERATE.FORM.USER.DATE_OF_BIRTH")}
            render={(props) => <Form.Date {...props} icon="calendar" />}
          />
        </>
      )}
    </Form.Form>
  );
};
