import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Page } from "@PNI/components/Layouts";

import { ArticlesArchive, ArticlesIntroduction, ArticlesSearch } from "./internal/components";

const ArticlesPage = () => {
  const { t } = useTranslation();

  const breadcrumbs = [{ name: t("ARTICLES.TITLE") }];

  return (
    <Page breadcrumbs={breadcrumbs}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ArticlesSearch />

            <ArticlesIntroduction
              title={t("ARTICLES.INTRODUCTION.TITLE")}
              description={t("ARTICLES.INTRODUCTION.DESCRIPTION")}
            />
          </div>
        </div>

        <ArticlesArchive />
      </div>
    </Page>
  );
};

export default observer(ArticlesPage);
