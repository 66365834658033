import { flow, Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel } from "@Core/models";

import { PollAnswerInstance, PollAnswerModel } from "@LNI/models";
import { MomentService } from "@LNI/services";

export const PollModel = BaseModel.props({
  question: types.string,
  answers: types.array(PollAnswerModel),
})
  .actions((self) => ({
    setAnswer: flow(function* (a: number) {
      const answer = self.answers.find((answer: PollAnswerInstance) => answer.id + "" === a + "");
      yield MomentService.setCompleted(self.id, answer?.id);

      if (answer) {
        answer.selected = true;
      }
    }),
  }))
  .views((self) => ({
    get getAnswer(): PollAnswerInstance | undefined {
      const answer = self.answers.find((answer: PollAnswerInstance) => answer.selected === true);
      return answer;
    },
  }));

export interface PollInstance extends Instance<typeof PollModel> {}
export type PollSnapshot = SnapshotIn<typeof PollModel>;
