import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { CardRow, Loader } from "@Core/components/UI";
import { formatDate } from "@Core/utilities";

import { InviteTokenInstance } from "@OOM/models";

import styles from "./InvitationList.module.scss";

type Props = {
  inviteTokens?: InviteTokenInstance[];
  onClickToken: (token: InviteTokenInstance) => any;
  onClickRemove: (id: number) => any;
  isLoading?: boolean;
};

const InvitationList = ({ inviteTokens, onClickToken, isLoading }: Props) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={styles.Loader}>
        <Loader width={50} />
      </div>
    );
  }

  if (inviteTokens && inviteTokens.length > 0) {
    return (
      <div>
        {inviteTokens.map((inviteToken) => (
          <CardRow
            largeIcon
            disabledLight={inviteToken.is_expired || inviteToken.is_used}
            key={inviteToken.id}
            icon={"far fa-file-alt"}
            title={t("QUICKSCAN.INVITE_TOKEN.MODAL.TITLE", { token: inviteToken.token })}
            dataStrings={[
              inviteToken.is_expired
                ? t("QUICKSCAN.INVITE_TOKEN.EXPIRED")
                : t("QUICKSCAN.INVITE_TOKEN.EXPIRE_AT", {
                    date: formatDate(inviteToken.expire_date),
                  }),
              `<div>${t("QUICKSCAN.INVITE_TOKEN.X_OF_Y_TIMES_USED", {
                usage: inviteToken.usage,
                limit: inviteToken.limit,
              })} </div> `,
            ]}
            onClick={() => onClickToken(inviteToken)}
          />
        ))}
      </div>
    );
  }

  return <CardRow icon="fa-plus" description={"" + t("QUICKSCAN.NO_FILES_FOUND")} dotted disabledLight />;
};

export default observer(InvitationList);
