import { useField } from "formik";
import React from "react";
import { ReactNode } from "react";

type Props = {
  id: string;
  render: (props: any) => ReactNode;
};

const ItemSimple = ({ id, render }: Props) => {
  const [field] = useField(id);

  return (
    <>
      {render({
        id: id,
        field: field,
      })}
    </>
  );
};

export default ItemSimple;
