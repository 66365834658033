import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import i18next from "i18next";
import merge from "lodash.merge";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { I18nextProvider } from "react-i18next/";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.scss";

import { Platform } from "@Core/constants/ENUMS";
import { useReactron, useSentry } from "@Core/hooks";
import data from "@Core/locales";
import { PrivateRoutes, PublicRoutes } from "@Core/routes";
import { AuthService } from "@Core/services";
import { CoreRootStoreProvider, useCoreStores } from "@Core/stores";
import CoreRootStore from "@Core/stores/CoreRootStore";

import dataLNI from "@LNI/locales";
import { LNIRootStore, LNIRootStoreProvider, useLNIStores } from "@LNI/stores";
import dataOOM from "@OOM/locales";
import { OOMRootStore, OOMRootStoreProvider, useOOMStores } from "@OOM/stores";
import dataPNI from "@PNI/locales";
import { PNIRootStore, PNIRootStoreProvider, usePNIStores } from "@PNI/stores";

i18next.init({
  lng: "nl",
  resources: data,
  debug: false,
  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    nsMode: "default",
  },
});

const App = () => {
  const Core = useCoreStores();

  const LNI = useLNIStores();
  const OOM = useOOMStores();
  const PNI = usePNIStores();

  const siteIds = {
    [Platform.NONE]: 1,
    [Platform.LNI]: 1,
    [Platform.PNI]: 3,
    [Platform.OOM]: 4,
  };

  const instance = createInstance({
    urlBase: "https://leiderschapeninzetbaarheid.matomo.cloud",
    // disabled: process.env.REACT_APP_HOST_ENV !== "master",
    siteId: siteIds[Core.getPlatform],
    linkTracking: true,
  });

  const [routes, setRoutes] = useState<JSX.Element>();

  useSentry();
  useReactron();

  useEffect(() => {
    const getPlatformLocales = (platform: Platform) => {
      switch (platform) {
        case Platform.LNI:
          return dataLNI;
        case Platform.OOM:
          return dataOOM;
        case Platform.PNI:
          return dataPNI;
      }
    };

    i18next.loadResources(() => merge(data, getPlatformLocales(Core.getPlatform)));

    if (Core.isLoggingIn) {
      setRoutes(undefined);
    }

    if (AuthService.isAuthenticated()) {
      if (LNI.isLoaded || OOM.isLoaded || PNI.isLoaded) {
        setRoutes(<PrivateRoutes />);
      } else {
        Core.load().then(() => {
          switch (Core.getPlatform) {
            case Platform.LNI:
              document.title = "Mijn Leiderschap & Inzetbaarheid";
              LNI.load();
              break;
            case Platform.OOM:
              document.title = "Mijn Organisatieadvies op Maat";
              OOM.load();
              break;
            case Platform.PNI:
              document.title = "Mijn Professional & Inzetbaarheid";
              PNI.load();
              break;
          }

          Core.setIsLoggingIn(false);
        });
      }
    } else {
      setRoutes(<PublicRoutes />);
    }
  }, [LNI, LNI.isLoaded, OOM, OOM.isLoaded, PNI, PNI.isLoaded, Core, Core.UserStore.isAuthenticated, Core.isLoggingIn]);

  useEffect(() => {
    const redirect = new URLSearchParams(window.location.search).get("redirect");

    if (redirect) {
      Core.setRedirect(redirect);
    }
  }, [Core]);

  const getConfetti = () => {
    return (
      <div className="Confetti">
        <Confetti
          style={{ position: "fixed", zIndex: 998 }}
          run={Core.isConfetti}
          recycle={false}
          numberOfPieces={500}
          opacity={0.8}
          colors={["#2b4982", "#56bbad", "#69548d", "#fed385"]}
          onConfettiComplete={(confetti) => {
            confetti?.reset();
            Core.setConfetti(false);
          }}
        />
      </div>
    );
  };

  const getBody = () => {
    return (
      <BrowserRouter>
        {routes}

        <ToastContainer hideProgressBar={true} />

        {Core.isConfetti && getConfetti()}
      </BrowserRouter>
    );
  };

  return (
    <I18nextProvider i18n={i18next}>
      <CoreRootStoreProvider value={CoreRootStore}>
        <MatomoProvider value={instance}>
          {Core.platform === Platform.LNI && (
            <LNIRootStoreProvider value={LNIRootStore}>{getBody()}</LNIRootStoreProvider>
          )}

          {Core.platform === Platform.OOM && (
            <OOMRootStoreProvider value={OOMRootStore}>{getBody()}</OOMRootStoreProvider>
          )}

          {Core.platform === Platform.PNI && (
            <PNIRootStoreProvider value={PNIRootStore}>{getBody()}</PNIRootStoreProvider>
          )}
        </MatomoProvider>
      </CoreRootStoreProvider>
    </I18nextProvider>
  );
};

export default observer(App);
