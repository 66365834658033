import React from "react";

import { IFieldProps } from "@Core/interfaces";
import { UserQuestionnaireQuestionInstance } from "@Core/models";

import styles from "./Number.module.scss";

type Props = {
  id: string;
  name: string;
  field: IFieldProps;

  disabled?: boolean;
  question: UserQuestionnaireQuestionInstance;
  placeholder?: string;
};

const Number = ({ id, field, disabled, question }: Props) => {
  return (
    <div className={styles.Inputs}>
      <input
        type="number"
        min={question.min || 0}
        max={question.max || undefined}
        step={question.step || undefined}
        id={id}
        disabled={disabled}
        className={styles.Number}
        {...field}
      />
    </div>
  );
};

export default Number;
