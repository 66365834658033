import { AxiosError, AxiosResponse } from "axios";

import axios from "@Core/axios";

class AuthService {
  LOCAL_STORAGE_TOKEN = "REACT_APP_TOKEN";

  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  /**
   * Logs the user in with a e-mailaddress and password,
   * returns a error when the user not exists
   *
   * @param values object
   */
  login = async (values: { email: string; password: string }): Promise<{ sms_token?: string }> => {
    const response = await axios.post(`${this.endpoint}/login`, values);
    return response.data;
  };

  /**
   * If the user succesfully logs in, we login with 2FA,
   * over here we send the code (retrieven from login)
   * and the sms_token (send by Twillio) so we can succesfully
   * login to the platform
   *
   * @param values object
   */
  token = async (values: { sms_token: string; code: string }): Promise<{ access_token: string; new_user: boolean }> => {
    const response = await axios.post(`${this.endpoint}/token`, values);
    return response.data;
  };

  /**
   * Sends an email to the user with a token and link to reset
   * your password
   *
   * @param values object
   */
  forgotPassword = async (values: { email: string }): Promise<{ success: boolean }> => {
    const response = await axios.post(`${this.endpoint}/forgot_password`, values);
    return response.data;
  };

  resetPassword = async (values: {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
  }): Promise<{ success: boolean }> => {
    const response = await axios.post(`${this.endpoint}/reset_password`, values);
    return response.data;
  };

  resetPasswordValidate = async (values: { token: string; email: string }): Promise<{ is_valid: boolean }> => {
    const response = await axios.post(`${this.endpoint}/reset_password/validate`, values);
    return response.data;
  };

  isAuthenticated = (): boolean => {
    return this.getToken() !== null;
  };

  getHeaders = (): string => {
    return "Bearer " + this.getToken();
  };

  getNewToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.endpoint}/refresh`)
        .then((response: AxiosResponse) => {
          this.setToken(response.data.access_token);

          resolve(response.data.access_token);
        })
        .catch((error: AxiosError) => {
          this.logout();
          reject(error);

          window.location.href = "";
        });
    });
  };

  setToken = (token: string): void => {
    localStorage.setItem(this.LOCAL_STORAGE_TOKEN, token);
  };

  getToken = (): string | null => {
    return localStorage.getItem(this.LOCAL_STORAGE_TOKEN);
  };

  logout = (): void => {
    if (window._paq) {
      window._paq.push(["deleteCustomDimension", 1]);
      window._paq.push(["deleteCustomDimension", 2]);
      window._paq.push(["deleteCustomDimension", 3]);
    }

    localStorage.removeItem(this.LOCAL_STORAGE_TOKEN);
  };

  resend = async (values: { sms_token: string }): Promise<{ sms_token: string }> => {
    const response = await axios.post<{ sms_token: string }>("/auth/resend", values);
    return response.data;
  };
}

export default new AuthService("auth");
