import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next/";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";

import { Footer } from "@Core/components/Layouts";
import { Circle, Logo } from "@Core/components/UI";

import styles from "./Login.module.scss";

type Props = {
  children: ReactNode;
  title: string;
  logo?: string;
  description?: string;
};

const Login = ({ children, title, logo, description }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.Wrapper}>
      <Circle primary x={95} y={15} r={500} />
      <Circle secondary dashed x={180} y={70} r={400} />

      <Circle secondary dashed x={-10} y={25} r={350} />
      <Circle primary x={-30} y={60} r={250} />
      <Circle primary dashed x={0} y={90} r={190} />

      <div className={styles.Block}>
        <div className={styles.BlockInner}>
          <div className={styles.Header}>
            {!logo && <Logo />}

            {logo && (
              <LazyLoad>
                <div className={styles.Logo}>
                  <Link to="">
                    <img src={logo} alt="Logo" />
                  </Link>
                </div>
              </LazyLoad>
            )}
          </div>

          <div className={styles.Body}>
            <div className={styles.BodyHeader}>
              <h1>{title}</h1>
              {description && <p>{description}</p>}
            </div>

            <div className={styles.BodyContent}>{children}</div>

            <div className={styles.BodyFooter}>
              {logo && (
                <div className={styles.BodyFooterLogo}>
                  <Logo />
                </div>
              )}

              <a href={`mailto:${t("DEFAULT.CONTACT_EMAIL")}`}>
                <i className="far fa-envelope"></i>
                {t("DEFAULT.CONTACT_EMAIL")}
              </a>

              <a href={`tel:${t("DEFAULT.CONTACT_PHONE")}`}>
                <i className="far fa-phone"></i>
                {t("DEFAULT.CONTACT_PHONE")}
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
