import * as Yup from "yup";

import { MOBILE, PASSWORD } from "@Core/constants/REGEXP";

import { CompanySearchType } from "./constants/ENUMS";
import { ValidationService } from "./services";

Yup.setLocale({
  mixed: {
    required: "REQUIRED",
  },
  string: {
    email: "STRING_EMAIL",
    min: "STRING_MIN",
    max: "STRING_MAX",
  },
  number: {
    min: "NUMBER_MIN",
    max: "NUMBER_MAX",
  },
});

Yup.addMethod(Yup.string, "phone", () => {
  return Yup.string().test("phone", "", function (value) {
    const { path, createError } = this;
    return (value && value.match(MOBILE)) || createError({ path, message: "STRING_PHONE" });
  });
});

Yup.addMethod(Yup.string, "password", (ref: string) => {
  // Check if password is equal to the reference
  if (ref) {
    return Yup.string().test("password", "", function (value: string) {
      const { path, createError } = this;
      return this.parent[ref] === value || createError({ path, message: "STRING_PASSWORD_MATCH" });
    });
  }

  // Check if password is in correct format
  return Yup.string().test("password", "", function (value) {
    const { path, createError } = this;
    return (value && value.match(PASSWORD)) || createError({ path, message: "STRING_PASSWORD" });
  });
});

let debounce: NodeJS.Timeout;
export const validateEmail = async (value: string, type: CompanySearchType, old?: string): Promise<boolean> => {
  clearTimeout(debounce);

  return new Promise(function (resolve) {
    if (old === value) return resolve(true);

    debounce = setTimeout(async () => {
      if (type === CompanySearchType.EMPLOYEE) {
        const { is_valid } = await ValidationService.validateEmployeeEmail(value);
        return resolve(is_valid);
      }

      if (type === CompanySearchType.SUPERVISOR) {
        const { is_valid } = await ValidationService.validateUserEmail(value);
        return resolve(is_valid);
      }
    }, 250);
  });
};

export default Yup;
