import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Frame } from "@Core/components/UI";

import { MirrorQuestionsOverview } from "@PNI/components/Containers";
import { StepList } from "@PNI/components/HOC";
import { usePNIStores } from "@PNI/stores";

import { MirrorQuestionsProps } from "./MirrorQuestions.props";

export const MirrorQuestions = observer(({ className }: MirrorQuestionsProps) => {
  const { t } = useTranslation();
  const { FileStore } = usePNIStores();

  const questions = FileStore.file?.mirrored_questions ?? [];

  if (!FileStore.file?.hasCompletedEmployeeQuestionnaire || !FileStore.file?.hasCompletedSupervisorQuestionnaire) {
    const steps = [];

    if (FileStore.file?.getEmployeeSteps && !FileStore.file?.hasCompletedEmployeeQuestionnaire) {
      steps.push(...FileStore.file.getEmployeeSteps);
    }

    if (FileStore.file?.getSupervisorSteps && !FileStore.file?.hasCompletedSupervisorQuestionnaire) {
      steps.push(...FileStore.file.getSupervisorSteps);
    }

    return (
      <Frame title={t("FILES.MIRROR_QUESTIONS.TITLE")} className={className} elevated="large">
        <Frame.Body padding="3">
          <h3>{t("FILES.MIRROR_QUESTIONS.EMPTY.SUBTITLE")}</h3>

          <Frame className="my-smd" variant="light">
            <Frame.Body padding="2">
              <p className="small mb-0 color-black-light">{t("FILES.MIRROR_QUESTIONS.EMPTY.MESSAGE")}</p>
            </Frame.Body>
          </Frame>

          {steps.length > 0 && <StepList steps={steps} />}
        </Frame.Body>
      </Frame>
    );
  }
  return (
    <Frame
      title={t("FILES.MIRROR_QUESTIONS.TITLE")}
      className={className}
      variant={questions.length > 0 ? undefined : "light"}
    >
      <Frame.Body padding="3">
        {questions.length ? (
          <MirrorQuestionsOverview mirrorQuestions={questions} />
        ) : (
          <p className="small mb-0 color-support">{t("FILES.MIRROR_QUESTIONS.EMPTY.MESSAGE")}</p>
        )}
      </Frame.Body>
    </Frame>
  );
});
