import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { Login } from "@Core/components/Layouts";
import { Button } from "@Core/components/UI";
import { ResetPasswordForm } from "@Core/forms";
import { ResetPasswordFormValues } from "@Core/forms/ResetPasswordForm/ResetPasswordForm.props";
import { AuthService } from "@Core/services";

const ResetPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [token, setToken] = useState("");
  const [mail, setMail] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params && params.get("token") && params.get("email")) {
      setToken("" + params.get("token"));
      setMail("" + params.get("email"));

      (async () => {
        const valid = await AuthService.resetPasswordValidate({
          email: String(params.get("email")),
          token: String(params.get("token")),
        });

        setIsValid(valid.is_valid);
      })();
    } else {
      history.push("/");
    }
  }, [history, location.search]);

  const initialValues: ResetPasswordFormValues = {
    token: token,
    email: mail,
    password: "",
    password_confirmation: "",
  };

  const onSubmit = (values: ResetPasswordFormValues) => {
    return AuthService.resetPassword(values);
  };

  const onSuccess = () => {
    toast.success(t("LOGIN.CHANGE.PASSWORD_CHANGED"));
    history.push("/inloggen");
  };

  const title = t(`LOGIN.${isValid ? "CHANGE" : "EXPIRED"}.TITLE`);
  const description = t(`LOGIN.${isValid ? "CHANGE" : "EXPIRED"}.SUBTITLE`);
  const form = <ResetPasswordForm initialValues={initialValues} onSubmit={onSubmit} onSuccess={onSuccess} />;

  return (
    <Login title={title} description={description}>
      {token && isValid === true && form}

      {token && isValid === false && (
        <Button block tertiary to="/wachtwoord-vergeten">
          {t("LOGIN.EXPIRED.RESET_PASSWORD")}
        </Button>
      )}
    </Login>
  );
};

export default ResetPage;
