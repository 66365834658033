import axios from "@Core/axios";
import { FileRecommendedAbsenceType } from "@Core/constants/ENUMS";
import { FileSnapshot, UserQuestionnaireSnapshot } from "@Core/models";
import { Prepr } from "@Core/utilities";

import { ConnectSupervisorFormValues, GenerateFileFormValues } from "@PNI/forms";

class FileService {
  getFile = async (id: number): Promise<FileSnapshot[] | undefined> => {
    try {
      const response = await axios.get(`aom/files/${id}`);

      if (response?.data?.article_recommendations) {
        const recommended = response.data.article_recommendations.map((article_recommendation: any) => {
          return Prepr.format(article_recommendation.article, article_recommendation);
        });

        response.data.article_recommendations = recommended;

        return response.data;
      }

      if (response?.data) {
        return response.data;
      }
    } catch (e) {}
  };

  getFiles = async (companyId?: number, archived?: boolean): Promise<FileSnapshot[] | undefined> => {
    try {
      let config = {};

      if (companyId) {
        config = {
          params: { company_id: companyId, archived: archived },
        };
      }

      const response = await axios.get("aom/files", config);
      return response.data;
    } catch (e) {}
  };

  startOmission = async (id: number): Promise<UserQuestionnaireSnapshot | null> => {
    try {
      const response = await axios.post(`aom/files/${id}/vos`);
      return response.data;
    } catch (e) {
      return null;
    }
  };

  startInterventionAdvice = async (id: number): Promise<UserQuestionnaireSnapshot | null> => {
    try {
      const response = await axios.post(`aom/files/${id}/interventionadvice`);
      return response.data;
    } catch (e) {
      return null;
    }
  };

  createFile = async (companyId: number, values: GenerateFileFormValues): Promise<any | null> => {
    values.company_id = companyId;

    const response = await axios.post(`aom/files`, values);

    if (response) {
      return response.data;
    }
  };

  connectSupervisor = async (id: number, values: ConnectSupervisorFormValues): Promise<any | null> => {
    const response = await axios.post(`aom/files/${id}/supervisor`, values);

    if (response) {
      return response.data;
    }
  };

  closeFile = async (id: number): Promise<any | null> => {
    const response = await axios.delete(`aom/files/${id}`);

    if (response) {
      return response.data;
    }
  };

  sendQuestionnaire = async (id: number, questionnaireId: number): Promise<any | null> => {
    const data = {
      questionnaireId,
    };

    const response = await axios.post(`aom/files/${id}/additional`, data);

    if (response) {
      return response.data;
    }
  };

  remindStep = async (id: number, step: number): Promise<null> => {
    return await axios.post(`aom/files/${id}/step/${step}/remind`);
  };

  checkStep = async (id: number, step: number): Promise<null> => {
    return await axios.post(`aom/files/${id}/step/${step}/check`);
  };

  closeAbsence = async (id: number): Promise<null> => {
    return await axios.post(`aom/files/${id}/close/absence`);
  };

  updateNote = async (id: number, note: string): Promise<null> => {
    return await axios.put(`aom/files/${id}/note`, { note });
  };

  setAbsenceType = async (id: number, type: FileRecommendedAbsenceType): Promise<null> => {
    return await axios.post(`aom/files/${id}/absencetype`, { type });
  };
}

export default new FileService();
