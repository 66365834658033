import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { colors, HTML, PDF as Page } from "@Core/components/Components/PDF/PDF";
import { FileStepType, UserQuestionnaireStatus } from "@Core/constants/ENUMS";
import { UserQuestionnaireInstance } from "@Core/models";
import { QuestionnaireService } from "@Core/services";
import { getQuestionnaireAnswers } from "@Core/utilities";

import { usePNIStores } from "@PNI/stores";

export const PDF = observer(({ t }: { t: (key: string) => string }) => {
  const { FileStore } = usePNIStores();
  const [userQuestionnaires, setUserQuestionnaires] = useState<UserQuestionnaireInstance[]>();

  const styles = StyleSheet.create({
    H1: { color: colors.body, fontWeight: "bold", fontSize: 22, marginBottom: 8 },
    H2: { color: colors.primary, fontWeight: "bold", fontSize: 18, marginTop: 16, marginBottom: 4 },
    H3: { color: colors.body, fontWeight: "bold", fontSize: 14, marginBottom: 4 },
    Bold: { fontWeight: "bold" },
    Subtitle: { textTransform: "uppercase", color: colors.support, marginBottom: 8 },
    DomainScore: { height: 20 },

    Block: { borderRadius: 4, border: `1px solid ${colors.supportLight}`, padding: 16, marginBottom: 16 },

    BlockLight: {
      borderRadius: 4,
      border: `1px solid ${colors.supportLight}`,
      padding: 16,
      marginBottom: 16,
      backgroundColor: colors.supportLightest,
    },

    Row: { flexDirection: "row", justifyContent: "space-between", marginHorizontal: -16 },

    Column25: { width: "25%", marginHorizontal: 16 },
    Column50: { width: "50%", marginHorizontal: 16 },
    Column75: { width: "75%", marginHorizontal: 16 },
    Column100: { width: "100%", marginHorizontal: 16 },

    Seperator: { marginVertical: 8, height: 1, backgroundColor: colors.supportLight },
  });

  const chosenSteps =
    FileStore.file?.steps?.filter((step) => step.selected === true && step.type === FileStepType.STEP) || [];

  const chosenQuestionnaires =
    FileStore.file?.steps?.filter(
      (step) =>
        step.selected === true &&
        step.type !== FileStepType.STEP &&
        step.file_questionnaire?.status === UserQuestionnaireStatus.Completed,
    ) || [];

  const matrixColors = {
    health: "#FED385",
    work: "#2B4982",
    motivation: "#69548D",
    private: "#56BBAD",
  } as any;

  useEffect(() => {
    const fetchUserQuestionnaires = async () => {
      const userQuestionnaires = await Promise.all(
        chosenQuestionnaires.map(async (q) =>
          QuestionnaireService.load(q.file_questionnaire?.user_questionnaire?.id || 0),
        ),
      );

      setUserQuestionnaires(userQuestionnaires || []);
    };

    fetchUserQuestionnaires();
  }, []);

  if (FileStore.file && userQuestionnaires) {
    return (
      <Page>
        <Text style={styles.H1}>{t("FILES.REPORT.TITLE")}</Text>
        <Text style={styles.H2}>{t("FILES.NAVIGATION.INSIGHT")}</Text>

        {/* Domains */}
        <View style={styles.Row}>
          <View style={styles.Column100}>
            <Text style={styles.H3}>{t("FILES.DOMAINS.TITLE")}</Text>

            <View style={styles.BlockLight}>
              {FileStore.file.domains ? (
                <>
                  {Object.entries(FileStore.file.domains).map(([key, domain]) => {
                    console.log(key, domain, (100 / 5) * domain.score);
                    return (
                      <View
                        key={key}
                        style={{
                          ...styles.Row,
                          alignItems: "center",
                          height: 20,
                          marginVertical: 2,
                        }}
                      >
                        <Text style={{ ...styles.Column25, ...styles.Bold }}>
                          {t(`FILES.DOMAINS.${key.toUpperCase()}`)}
                        </Text>

                        <View
                          style={{
                            ...styles.Column75,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <View
                            style={{
                              ...styles.DomainScore,
                              width: `${(100 / 5) * domain.score}%`,
                              backgroundColor: matrixColors[key] || colors.body,
                            }}
                          />
                        </View>
                      </View>
                    );
                  })}
                </>
              ) : (
                <Text>{t("FILES.DOMAINS.EMPTY.DESCRIPTION")}</Text>
              )}
            </View>
          </View>
        </View>

        {/* Resources & Stressors */}
        <View style={styles.Row}>
          <View style={styles.Column50}>
            <Text style={styles.H3}>{t("FILES.RESOURCES.TITLE")}</Text>

            {FileStore.file.resources && FileStore.file.resources.length > 0 ? (
              <View style={styles.Block}>
                {FileStore.file.resources.map((value, idx) => (
                  <View key={idx}>
                    <Text>{value}</Text>

                    {idx !== (FileStore.file?.resources || []).length - 1 && <View style={styles.Seperator} />}
                  </View>
                ))}
              </View>
            ) : (
              <View style={styles.BlockLight}>
                <Text>{t("FILES.RESOURCES.EMPTY")}</Text>
              </View>
            )}
          </View>

          <View style={styles.Column50}>
            <Text style={styles.H3}>{t("FILES.STRESSORS.TITLE")}</Text>

            {FileStore.file.stressors && FileStore.file.stressors.length > 0 ? (
              <View style={styles.Block}>
                {FileStore.file.stressors.map((value, idx) => (
                  <View key={idx}>
                    <Text>{value}</Text>

                    {idx !== (FileStore.file?.stressors || []).length - 1 && <View style={styles.Seperator} />}
                  </View>
                ))}
              </View>
            ) : (
              <View style={styles.BlockLight}>
                <Text>{t("FILES.STRESSORS.EMPTY")}</Text>
              </View>
            )}
          </View>
        </View>

        {/* Mirrored Questions */}
        <View style={styles.Row}>
          <View style={styles.Column100}>
            <Text style={styles.H3}>{t("FILES.MIRROR_QUESTIONS.TITLE")}</Text>

            {FileStore.file.mirrored_questions && FileStore.file.mirrored_questions.length > 0 ? (
              <View style={{ ...styles.Block, marginBottom: 0 }}>
                {FileStore.file.mirrored_questions.map((value, idx) => (
                  <View key={idx}>
                    <Text>{value}</Text>

                    {idx !== (FileStore.file?.mirrored_questions || []).length - 1 && <View style={styles.Seperator} />}
                  </View>
                ))}
              </View>
            ) : (
              <View style={{ ...styles.BlockLight, marginBottom: 0 }}>
                <Text>{t("FILES.MIRROR_QUESTIONS.EMPTY.MESSAGE")}</Text>
              </View>
            )}
          </View>
        </View>

        <Text break style={styles.H2}>
          {t("FILES.NAVIGATION.ADVICE")}
        </Text>

        {/* Recommended absence type */}
        <View style={styles.Row}>
          <View style={styles.Column100}>
            <Text style={styles.H3}>{t("FILES.RECOMMENDED_ABSENCE_TYPE.TITLE")}</Text>

            {FileStore.file.recommended_absence_type ? (
              <View style={{ ...styles.Block, marginBottom: 0 }}>
                <Text style={styles.Bold}>{t("FILES.RECOMMENDED_ABSENCE_TYPE.FORM.FIELDS.ABSENCE_TYPE.LABEL")}</Text>

                <Text>
                  {t(
                    `FILES.RECOMMENDED_ABSENCE_TYPE.FORM.FIELDS.ABSENCE_TYPE.OPTIONS.${FileStore.file.recommended_absence_type}`,
                  )}
                </Text>
              </View>
            ) : (
              <View style={{ ...styles.BlockLight, marginBottom: 0 }}>
                <Text>{t("FILES.RECOMMENDED_ABSENCE_TYPE.EMPTY")}</Text>
              </View>
            )}
          </View>
        </View>

        <Text break style={styles.H2}>
          {t("FILES.NAVIGATION.REPORT")}
        </Text>

        {/* Chosen questionnaires */}
        <View style={styles.Row}>
          <View style={styles.Column100}>
            <Text style={styles.H3}>{t("FILES.CHOSEN_QUESTIONNAIRES.TITLE")}</Text>

            {chosenQuestionnaires.length > 0 ? (
              <>
                {chosenQuestionnaires.map((value, idx) => {
                  const steps = userQuestionnaires.find(
                    (userQuestionnaire) => userQuestionnaire.id === value.file_questionnaire?.user_questionnaire?.id,
                  )?.progressed_steps;

                  if (steps) {
                    return (
                      <View key={idx} style={styles.Block}>
                        <Text style={styles.Subtitle}>{value.title}</Text>

                        {steps.map((step, stepIdx: number) => {
                          const answers = getQuestionnaireAnswers(step.question);

                          if (answers.length > 0) {
                            return (
                              <View wrap={false} key={stepIdx}>
                                <Text style={styles.Bold}>{step.question?.title.replace(/(\*\*)/g, "") || ""}</Text>

                                {answers.map((answer, answerIdx: number) => {
                                  return <Text key={answerIdx}>{`${answers.length > 1 ? "• " : ""} ${answer}`}</Text>;
                                })}

                                {stepIdx !== steps.length - 1 && <View style={styles.Seperator} />}
                              </View>
                            );
                          }
                        })}
                      </View>
                    );
                  }
                })}
              </>
            ) : (
              <View style={styles.BlockLight}>
                <Text>{t("FILES.CHOSEN_QUESTIONNAIRES.EMPTY")}</Text>
              </View>
            )}
          </View>
        </View>

        {/* Chosen steps */}
        <View style={styles.Row}>
          <View style={styles.Column100}>
            <Text style={styles.H3}>{t("FILES.CHOSEN_STEPS.TITLE")}</Text>

            {chosenSteps.length > 0 ? (
              <View style={styles.Block}>
                {chosenSteps.map((value, idx) => (
                  <View key={idx}>
                    <Text>{value.title}</Text>

                    {idx !== chosenSteps.length - 1 && <View style={styles.Seperator} />}
                  </View>
                ))}
              </View>
            ) : (
              <View style={styles.BlockLight}>
                <Text>{t("FILES.CHOSEN_STEPS.EMPTY")}</Text>
              </View>
            )}
          </View>
        </View>

        {/* Notes */}
        <View style={styles.Row}>
          <View style={styles.Column100}>
            <Text style={styles.H3}>{t("FILES.NOTES.TITLE")}</Text>

            {FileStore.file.note ? (
              <View style={{ ...styles.Block, marginBottom: 0 }}>
                <HTML>{FileStore.file.note}</HTML>
              </View>
            ) : (
              <View style={{ ...styles.BlockLight, marginBottom: 0 }}>
                <Text>{t("FILES.NOTES.EMPTY")}</Text>
              </View>
            )}
          </View>
        </View>
      </Page>
    );
  }

  return <></>;
});
