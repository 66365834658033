import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Questionnaire } from "@Core/components/Components";
import { ContactList } from "@Core/components/HOC";
import { Block } from "@Core/components/UI";
import { FileQuestionnairesInstance } from "@Core/models";
import { UserInstance } from "@Core/models/BaseUserModel";
import { useCoreStores } from "@Core/stores";
import { parseHTML } from "@Core/utilities";

import { Page } from "@LNI/components/Layouts";
import { useLNIStores } from "@LNI/stores";

const FilePage = () => {
  const { t } = useTranslation();
  const { file } = useParams();
  const history = useHistory();
  const { UserStore } = useCoreStores();
  const { FileStore } = useLNIStores();
  const [advisor, setAdvisor] = useState<UserInstance>();
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState<FileQuestionnairesInstance>();

  const breadcrumbs = [
    { to: "/dossiers", name: t("FILES.FILES") },
    { name: `${FileStore.file?.employee?.getName || "-"} - ${currentQuestionnaire?.questionnaire?.title}` },
  ];

  useEffect(() => {
    const pob = UserStore.user?.companies[0].pob;

    if (pob) {
      setAdvisor(pob);
    }
  }, [UserStore]);

  useEffect(() => {
    (async () => {
      try {
        await FileStore.getFile(file);

        if (FileStore.file) {
          if (FileStore.file.supervisor_questionnaire) {
            setCurrentQuestionnaire(FileStore.file.supervisor_questionnaire);
          } else {
            history.push("/");
          }
        }
      } catch (e) {
        history.push("/");
      }
    })();
  }, [file, FileStore, history]);

  const onCompletion = () => {
    toast.success(t("FILES.COMPLETED.SUCCESS"));
    FileStore.load();

    history.push("/");
  };

  if (FileStore.file && currentQuestionnaire) {
    return (
      <Page breadcrumbs={breadcrumbs}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mt-sm mb-md">
                <h2 className="color-tertiary">
                  {`${FileStore.file.employee?.getName || "-"} - ${currentQuestionnaire?.questionnaire?.title}`}
                </h2>

                {t("FILES.DURATION")}
                {t("SHARED.DAYS", { count: FileStore.file.duration })}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              {currentQuestionnaire.questionnaire?.information && (
                <Block name={t("SHARED.INFORMATION")}>
                  {parseHTML(currentQuestionnaire.questionnaire?.information)}
                </Block>
              )}

              {advisor && (
                <div className="hidden-md-down">
                  <ContactList items={1} contacts={UserStore.user?.companies[0].getContacts} imageLarge />
                </div>
              )}
            </div>

            <div className="col-lg-8">
              {currentQuestionnaire.user_questionnaire?.id && (
                <Questionnaire
                  id={currentQuestionnaire.user_questionnaire.id}
                  onCompletion={() => onCompletion()}
                  block={true}
                />
              )}

              {advisor && (
                <div className="hidden-lg-up">
                  <ContactList items={2.5} contacts={UserStore.user?.companies[0].getContacts} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Page>
    );
  }

  return <></>;
};

export default observer(FilePage);
