import React from "react";

import { Sidebar } from "@Core/components/Layouts";
import { useDialog, useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";

import { FileQuestions } from "@PNI/components/Containers";

import styles from "./Segment.module.scss";
import { SegmentProps } from "./Segment.props";

export const Segment = ({ title, score = 5, questions }: SegmentProps) => {
  const { isVisible, toggle } = useDialog();
  const trackEvent = useMatomo();

  return (
    <>
      <div
        className={styles.Segment}
        onClick={() => {
          trackEvent(MatomoCategory.FileInsight, title);
          toggle();
        }}
      >
        <div className={styles.Segment__Title}>
          <i className="fas fa-info-circle color-primary" />

          <h3 className={styles.Segment__Title}>{title}</h3>
        </div>

        <div className={styles.Segment__Bars}>
          {[...Array(score)].map((_, index) => {
            return <div key={index} className={styles.Segment__Bar} />;
          })}
        </div>
      </div>

      <Sidebar title={title} isVisible={isVisible} onClose={toggle}>
        <hr className="mb-4" />

        <FileQuestions value={questions} />
      </Sidebar>
    </>
  );
};
