import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Button, Frame } from "@Core/components/UI";
import { FileStepType } from "@Core/constants/ENUMS";

import { StepList } from "@PNI/components/HOC";
import { usePNIStores } from "@PNI/stores";

import { ChosenQuestionnairesProps } from "./ChosenQuestionnaires.props";

export const ChosenQuestionnaires = observer(({ className }: ChosenQuestionnairesProps) => {
  const { t } = useTranslation();
  const { FileStore } = usePNIStores();

  const steps =
    FileStore.file?.steps?.filter(
      (step) =>
        (step.selected === true && step.type !== FileStepType.STEP) ||
        step.type === FileStepType.EMPLOYEE_QUESTIONNAIRE ||
        step.type === FileStepType.SUPERVISOR_QUESTIONNAIRE,
    ) || [];

  return (
    <Frame title={t("FILES.CHOSEN_QUESTIONNAIRES.TITLE")} className={className} variant="light">
      <Frame.Body padding="3">
        {steps.length === 0 ? (
          <>
            <p className="small mb-sm color-black-light">{t("FILES.CHOSEN_QUESTIONNAIRES.EMPTY")}</p>

            <Button tertiary to={`/dossiers/${FileStore.file?.id}/advies`}>
              {t("FILES.CHOSEN_QUESTIONNAIRES.ADD_BUTTON")}
            </Button>
          </>
        ) : (
          <StepList steps={steps} />
        )}
      </Frame.Body>
    </Frame>
  );
});
