import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Form from "@Core/components/Form";
import { Button } from "@Core/components/UI";
import { FileRecommendedAbsenceType } from "@Core/constants/ENUMS";
import Yup from "@Core/validation";

import { RecommendedAbsenceTypeFormProps, RecommendedAbsenceTypeFormValues } from "./RecommendedAbsenceTypeForm.props";

export const RecommendedAbsenceTypeForm = ({
  initialValues,
  onSubmit,
  className,
  isDisabled = false,
}: RecommendedAbsenceTypeFormProps) => {
  const [value, setValue] = useState(initialValues.recommended_absence_type);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    recommended_absence_type: Yup.string().required(),
  });

  const handleChange = (values: RecommendedAbsenceTypeFormValues) => {
    setValue(values.recommended_absence_type);
  };

  const handleSubmit = (values: RecommendedAbsenceTypeFormValues) => {
    onSubmit(values);
  };

  const typeOptions = Object.keys(FileRecommendedAbsenceType).reduce((acc, key) => {
    return {
      ...acc,
      [key]: t(`FILES.RECOMMENDED_ABSENCE_TYPE.FORM.FIELDS.ABSENCE_TYPE.OPTIONS.${key}`),
    };
  }, {});

  return (
    <Form.Form
      identifier="RECOMMENDED_ABSENCE_TYPE_FORM"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onChange={handleChange}
      onSubmit={handleSubmit}
      className={className}
      disabled={isDisabled}
    >
      <Form.Item
        id="recommended_absence_type"
        name={t("FILES.RECOMMENDED_ABSENCE_TYPE.FORM.FIELDS.ABSENCE_TYPE.LABEL")}
        description={t("FILES.RECOMMENDED_ABSENCE_TYPE.FORM.FIELDS.ABSENCE_TYPE.DESCRIPTION")}
        render={(props) => (
          <Form.Select
            {...props}
            placeholder={t("FILES.RECOMMENDED_ABSENCE_TYPE.FORM.FIELDS.ABSENCE_TYPE.PLACEHOLDER")}
            options={typeOptions}
          />
        )}
      />

      <Button type="submit" tertiary disabled={!value || value === initialValues.recommended_absence_type}>
        {t("FILES.RECOMMENDED_ABSENCE_TYPE.FORM.SUBMIT")}
      </Button>
    </Form.Form>
  );
};
