import cn from "classnames";
import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

import styles from "./Sidebar.module.scss";

interface SidebarProps {
  title?: string;
  isVisible: boolean;
  onClose?: () => void;
  children: ReactNode;
  className?: string;
}

const Sidebar = ({ title, isVisible, onClose, children, className }: SidebarProps) => {
  const { t } = useTranslation();

  return isVisible
    ? ReactDOM.createPortal(
        <>
          <div className={styles.Overlay} onClick={onClose} />

          <aside className={cn(styles.Sidebar, className)}>
            <div className={styles.Header}>
              {onClose && (
                <div className={styles.Close} onClick={onClose}>
                  <i className={cn(styles.Icon, "fas fa-arrow-left")} />

                  <span className={styles.Label}>{t("COMPONENTS.SIDEBAR.CLOSE")}</span>
                </div>
              )}

              {title && <h2 className={styles.Title}>{title}</h2>}
            </div>

            <div className={styles.Content}>{children}</div>
          </aside>
        </>,
        document.body,
      )
    : null;
};

export default Sidebar;
