import React from "react";
import LazyLoad from "react-lazyload";

import styles from "./Image.module.scss";

type Props = {
  name: string;
  url: string;
};

const Image = ({ name, url }: Props) => (
  <div className="row">
    <div className="col-xl-1 hidden-lg-down"></div>

    <div className="col-xl-7 col-lg-8 col-md-12">
      <LazyLoad>
        <img className={styles.Image} src={url} alt={name} />
      </LazyLoad>
    </div>
  </div>
);

export default Image;
