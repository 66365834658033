import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import Form from "@Core/components/Form";
import { Button } from "@Core/components/UI";
import { useBoolean } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";
import Yup from "@Core/validation";

import styles from "./NotesForm.module.scss";
import { NotesFormProps, NotesFormValues } from "./NotesForm.props";

export const NotesForm = observer(({ initialValues, onSubmit, className, isDisabled }: NotesFormProps) => {
  const { t } = useTranslation();
  const { value: isFormEnabled, setTrue: setFormEnabledTrue, setFalse: setFormEnabledFalse } = useBoolean(false);
  const { value: hasNotes, setTrue: setNotesTrue } = useBoolean(!!initialValues?.note);

  const validationSchema = Yup.object().shape({
    note: Yup.string().required(),
  });

  const handleSubmit = (values: NotesFormValues) => {
    setFormEnabledFalse();
    setNotesTrue();

    onSubmit(values);
  };

  return (
    <Form.Form
      identifier="NOTES_FORM"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      className={cn(styles.NotesForm, className)}
      disabled={isDisabled}
    >
      <Form.Item
        id="note"
        hidden
        render={(props) => (
          <Form.RichText
            className={styles.Input}
            placeholder={t("FILES.NOTES.FORM.ADD_NOTES.INPUT.PLACEHOLDER")}
            isDisabled={!isFormEnabled || isDisabled}
            {...props}
          />
        )}
      />

      <div className={styles.Footer}>
        {!isDisabled && isFormEnabled && (
          <Button type="submit" tertiary>
            {t("FILES.NOTES.FORM.SUBMIT")}
          </Button>
        )}

        {!isFormEnabled && (
          <Button
            tertiary
            link={hasNotes}
            onClick={setFormEnabledTrue}
            data-matomo-category={MatomoCategory.FileReport}
          >
            {hasNotes ? t("FILES.NOTES.FORM.EDIT_NOTES.BUTTON") : t("FILES.NOTES.FORM.ADD_NOTES.BUTTON")}
          </Button>
        )}
      </div>
    </Form.Form>
  );
});
