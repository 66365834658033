import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";

import { Button, StackList } from "@Core/components/UI";
import { useDialog, useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";

import { usePNIStores } from "@PNI/stores";

import styles from "./FilePageHeader.module.scss";
import { FilePageHeaderProps } from "./FilePageHeader.props";
import {
  FilePageHeaderCloseFileModal,
  FilePageHeaderCloseOmissionModal,
  FilePageHeaderEmployeeModal,
  FilePageHeaderSupervisorModal,
} from "./internal/components";

export const FilePageHeader = observer(({ title, description, close }: FilePageHeaderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dialogCloseFile = useDialog();
  const dialogCloseOmission = useDialog();
  const dialogEmployee = useDialog();
  const dialogSupervisor = useDialog();
  const trackEvent = useMatomo();
  const { FileStore } = usePNIStores();

  if (FileStore.file) {
    return (
      <div className={cn(styles.FilePageHeader, "container")}>
        <div>
          <h2 className={styles.FilePageHeader__Title}>
            <small>{t("FILES.HEADER.FILE_NUMBER")}</small>#{FileStore.file.getId}
          </h2>

          {(!!title || !!description) && (
            <div className={styles.FilePageHeader__Content}>
              {!!title && <strong className="d-block">{title}</strong>}
              {!!description && <p className="mb-0" dangerouslySetInnerHTML={{ __html: description }} />}
            </div>
          )}

          <StackList style={{ float: "left" }}>
            <StackList.Item
              title={t("FILES.HEADER.EMPLOYEE")}
              icon="user"
              onClick={() => {
                trackEvent(MatomoCategory.File, t("FILES.MODALS.EDIT_EMPLOYEE.POPUP_TITLE"));
                dialogEmployee.toggle();
              }}
            >
              {FileStore.file.employee.getName}
            </StackList.Item>

            {FileStore.file.supervisor ? (
              <StackList.Item title={t("FILES.HEADER.SUPERVISOR")} icon="user">
                {FileStore.file.supervisor.getName}
              </StackList.Item>
            ) : (
              <StackList.Item
                title={t("FILES.HEADER.SUPERVISOR")}
                icon="user"
                isError
                onClick={() => {
                  trackEvent(MatomoCategory.File, t("FILES.MODALS.EDIT_EMPLOYEE.POPUP_TITLE"));
                  dialogSupervisor.toggle();
                }}
              >
                {t("FILES.HEADER.EMPTY_SUPERVISOR")}
              </StackList.Item>
            )}

            <StackList.Item title={t("FILES.HEADER.DURATION")} icon="user">
              {t("SHARED.DAYS", { count: FileStore.file.duration })}
            </StackList.Item>
          </StackList>
        </div>

        {close && (
          <div className={styles.FilePageHeader__Buttons}>
            <div className="d-flex flex-wrap">
              <Button
                data-matomo-category={MatomoCategory.File}
                disabled={FileStore.file.isOmissionClosed}
                danger
                link
                underline
                onClick={dialogCloseOmission.toggle}
              >
                {t(`FILES.HEADER.BUTTONS.${FileStore.file.isOmissionClosed ? "OMISSION_DONE" : "OMISSION"}`)}
              </Button>

              <Button
                data-matomo-category={MatomoCategory.File}
                disabled={FileStore.file.isClosed}
                iconLeft="fa-times"
                danger
                onClick={dialogCloseFile.toggle}
              >
                {t(`FILES.HEADER.BUTTONS.${FileStore.file.isClosed ? "CLOSE_DONE" : "CLOSE"}`)}
              </Button>
            </div>

            <Button
              data-matomo-category={MatomoCategory.File}
              disabled={FileStore.file.isClosed}
              iconLeft={FileStore.file?.vos?.isCompleted ? "fa-eye" : "fa-plus"}
              tertiary
              onClick={async () => {
                const id = await FileStore.getOmission(FileStore.file?.id || 0);
                history.push(`/dossiers/${FileStore.file?.id}/vragenlijst/${id || FileStore.file?.vos?.id}`);
              }}
            >
              {FileStore.file?.vos?.isCompleted ? t("FILES.HEADER.BUTTONS.VOS_DONE") : t("FILES.HEADER.BUTTONS.VOS")}
            </Button>

            {FileStore.file.isClosed === false && <FilePageHeaderCloseFileModal {...dialogCloseFile} />}
            {FileStore.file.isOmissionClosed === false && <FilePageHeaderCloseOmissionModal {...dialogCloseOmission} />}
          </div>
        )}

        <FilePageHeaderEmployeeModal {...dialogEmployee} />
        {!FileStore.file.supervisor && <FilePageHeaderSupervisorModal {...dialogSupervisor} />}
      </div>
    );
  }

  return <></>;
});
