import cn from "classnames";
import React from "react";

import styles from "./PreprRendererSpotify.module.scss";
import { PreprRendererSpotifyProps } from "./PreprRendererSpotify.props";

export const PreprRendererSpotify = ({ element }: PreprRendererSpotifyProps) => {
  return (
    <div className={cn(styles.PreprRendererSpotify, "row")}>
      <div className="col-xl-2 hidden-lg-down"></div>

      <div className="col-xl-7 col-lg-9 col-md-12">
        <iframe
          src={`https://embed.spotify.com/?uri=${element.spotifyUrl}`}
          width="100%"
          height="auto"
          frameBorder="0"
          allow="encrypted-media"
        ></iframe>
      </div>
    </div>
  );
};
