import cn from "classnames";
import CryptoJS from "crypto-js";
import { format } from "date-fns/esm";
import React from "react";

import { AccountType, Platform, PreprLabel, UserQuestionnaireQuestionType } from "@Core/constants/ENUMS";

import { IFiles } from "./interfaces";
import {
  ActivationSnapshot,
  ArticleSnapshot,
  AssetInstance,
  AssetsInstance,
  PublicationElementInstance,
  QuoteInstance,
  SpotifyInstance,
  TagInstance,
  TextInstance,
  UserQuestionnaireQuestionInstance,
} from "./models";

export const getAPIUrl = (): string | undefined => {
  const branch = process.env.REACT_APP_HOST_ENV;

  switch (branch) {
    case "master":
      return process.env.REACT_APP_API_URL_PRODUCTION;
    case "staging":
      return process.env.REACT_APP_API_URL_STAGING;
    case "develop":
      return process.env.REACT_APP_API_URL_DEVELOP;
  }

  return process.env.REACT_APP_API_URL;
};

export const getMediaUrl = (): string | undefined => {
  const branch = process.env.REACT_APP_HOST_ENV;

  switch (branch) {
    case "master":
      return process.env.REACT_APP_MEDIA_URL_PRODUCTION;
    case "staging":
      return process.env.REACT_APP_MEDIA_URL_STAGING;
    case "develop":
      return process.env.REACT_APP_MEDIA_URL_DEVELOP;
  }

  return process.env.REACT_APP_MEDIA_URL;
};

export const getKennisbankToken = (): string => {
  return encodeURIComponent(
    CryptoJS.AES.encrypt(JSON.stringify(new Date().toISOString()), "f1341f1786b365d9996b884f2e6e6bf0").toString(),
  );
};

export const formatDate = (date: string): string => {
  const d = new Date(date);
  return format(d, "dd-LL-yyyy").toString();
};

export const getImage = (uri: string): string => {
  return `${getMediaUrl()}${uri}`;
};

export const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const leadingZero = (value: string, zeros = 5): string => {
  while (value.length < zeros) value = "0" + value;
  return value;
};

export const getPlatform = (role?: AccountType): Platform => {
  const pathname = window.location.hostname;

  const lni = ["mijn.leiderschapeninzetbaarheid.nl", "staging.lni.sbdev.nl", "dev.lni.sbdev.nl", "lni.sbdev.nl"];
  const lniRoles = [AccountType.User];

  const oom = ["mijn.organisatieadviesopmaat.nl", "staging.oom.sbdev.nl", "dev.oom.sbdev.nl", "oom.sbdev.nl"];
  const oomRoles = [AccountType.Advisor];

  const pni = ["professionaleninzetbaarheid.nl", "staging.pni.sbdev.nl", "dev.pni.sbdev.nl", "pni.sbdev.nl"];
  const pniRoles = [AccountType.Pob, AccountType.HR, AccountType.CompanyDoctor, AccountType.AccountManager];

  if (role) {
    switch (true) {
      case lniRoles.includes(role):
        return Platform.LNI;
      case oomRoles.includes(role):
        return Platform.OOM;
      case pniRoles.includes(role):
        return Platform.PNI;
    }
  }

  switch (true) {
    case lni.includes(pathname):
      return Platform.LNI;
    case oom.includes(pathname):
      return Platform.OOM;
    case pni.includes(pathname):
      return Platform.PNI;
  }

  const item = localStorage.getItem("REACT_APP_MODULE") as Platform;

  return item ? Platform[item] : Platform.LNI;
};

export const nl2br = (content: string): JSX.Element => {
  return <>{content.split("\n").map((str, key) => str.trim().length > 0 && <p key={key}>{str}</p>)}</>;
};

export const timeout = (ms: number): Promise<boolean> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isProduction = (): boolean => {
  return process.env.REACT_APP_HOST_ENV === "master";
};

export const parseHTML = (html?: string | null, className?: string): JSX.Element => {
  if (!!html) {
    return (
      <div
        className={cn(["HTML", className])}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    );
  }

  return <></>;
};

export const stripHTML = (html: string) => {
  return html.replace(/(<([^>]+)>)/gi, "");
};

export const openLink = (href: string, target = "_blank"): void => {
  const link = document.createElement("a");
  link.href = href;
  link.target = target;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export class Prepr {
  static getContent(items: any[]): any {
    return items.map((item) => {
      switch (item.label) {
        case PreprLabel.SPOTIFY:
          return item as SpotifyInstance;
        case PreprLabel.TEXT:
          return item as TextInstance;
        case PreprLabel.QUOTE:
          return item as QuoteInstance;
        case PreprLabel.ASSET:
          return this.getAsset(item);
        case PreprLabel.PUBLICATIONELEMENT:
          return this.getPublicationElement(item);
        default:
          return {
            label: item.label,
          };
      }
    });
  }

  static getAsset(item: any): AssetsInstance | null {
    if (item) {
      return {
        label: item.label,
        items: item.items.map((file: IFiles) => {
          switch (file.label) {
            case "Photo":
              return this.getAssetPhoto(file);
            case "Document":
              return this.getAssetDocument(file);
            default:
              return {
                id: file.id,
                label: file.label,
                name: file.name,
              };
          }
        }),
      };
    }

    return null;
  }

  static getAssetPhoto(item: IFiles): AssetInstance {
    return {
      id: item.id,
      label: item.label,
      name: item.name,
      url: item.cdn_files.items[0].resized.thumb,
    };
  }

  static getAssetDocument(item: IFiles): AssetInstance | null {
    if (item.cdn_files && item.cdn_files.items.length > 0 && item.cdn_files.items[0].url) {
      return {
        id: item.id,
        label: item.label,
        name: item.name,
        url: item.cdn_files.items[0].url,
      };
    }

    return null;
  }

  static getPublicationElement(item: any): PublicationElementInstance {
    return {
      id: item.items.text.id,
      label: item.label,
      body: item.items.text.body,
      type: item.items.url.type,
      title: item.items.url.body,
      url: item.items.url.url,
    };
  }

  static format(article: any, recommendation?: any): ArticleSnapshot {
    const items = article.items["nl-NL"];

    const theme: TagInstance = {
      id: items.theme.items[0].id,
      body: items.theme.items[0].body,
      slug: items.theme.items[0].slug,
      selected: false,
    };

    let keywords: TagInstance[] = [];
    if (items.keywords && items.keywords.items.length > 0) {
      keywords = items.keywords.items.map((item: TagInstance) => {
        return item;
      });
    }

    let activation: ActivationSnapshot = {};

    if (items.activation_title) {
      activation = {
        tips: { title: "", icon: "", body: "", links: "" },
        steps: { title: "", icon: "", body: "", links: "" },
        title: items.activation_title.body,
        intro: items.activation_intro?.body ? items.activation_intro?.body : null,
      };

      if (items.activation_tips) {
        activation.tips = {
          title: items.activation_tips_title ? items.activation_tips_title.body : null,
          icon: items.activation_tips_icon ? items.activation_tips_icon.body : null,
          body: items.activation_tips ? items.activation_tips.body : null,
          links: items.activation_tips_links ? items.activation_tips_links.body : null,
        };
      }

      if (items.activation_steps) {
        activation.steps = {
          title: items.activation_steps_title ? items.activation_steps_title.body : null,
          icon: items.activation_steps_icon ? items.activation_steps_icon.body : null,
          body: items.activation_steps ? items.activation_steps.body : null,
          links: items.activation_steps_links ? items.activation_steps_links.body : null,
        };
      }
    }

    const a: ArticleSnapshot = {
      id: article.id,
      created_on: article.created_on,
      slug: article.slug["nl-NL"],
      read_time: article.read_time ? article.read_time["nl-NL"] : 4,
      title: items.title.body,
      intro: items.intro.body,
      exclusive: items.exclusive && items.exclusive.value,
      theme: theme,
      keywords: keywords,
      most_read: items.most_read?.body ? true : false,
      featured: items.featured?.body ? true : false,
      content: items.content ? Prepr.getContent(items.content.items) : null,
      activation: items.activation_title ? activation : null,
    };

    // Set recommendation
    if (recommendation) {
      a.recommendation = {
        message: recommendation.message,
        recommended_by: recommendation.recommended_by,
      };
    }

    return a;
  }
}

export const getQuestionnaireAnswers = (question?: UserQuestionnaireQuestionInstance | null): string[] => {
  if (question) {
    switch (question.type) {
      case UserQuestionnaireQuestionType.Single:
      case UserQuestionnaireQuestionType.Multiple:
      case UserQuestionnaireQuestionType.SingleDropdown:
      case UserQuestionnaireQuestionType.Statement:
      case UserQuestionnaireQuestionType.Scale:
      case UserQuestionnaireQuestionType.ScaleEmoji:
      case UserQuestionnaireQuestionType.ScaleSlider:
        return question.answers
          .filter((answer) => answer.selected === true)
          .map((item) => {
            if (item.open_input && item.user_answer) {
              return `${item.text} "${item.user_answer}"`;
            }

            return item.text || "-";
          });
      default:
        return [question.user_answer || "-"];
    }
  }

  return ["-"];
};

export const setMatomoUser = (companyId: number, userId: number): void => {
  console.log("Matomo: setCustomDimension", { companyId, userId });

  window._paq.push(["setCustomDimension", 1, companyId]);
  window._paq.push(["setCustomDimension", 2, userId]);
};

export const deleteMatomoUser = (): void => {
  console.log("Matomo: deleteCustomDimension");

  window._paq.push(["deleteCustomDimension", 1]);
  window._paq.push(["deleteCustomDimension", 2]);
};
