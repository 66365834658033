import React from "react";
import { useTranslation } from "react-i18next";

import Form from "@Core/components/Form";
import { IFieldProps } from "@Core/interfaces";
import { UserQuestionnaireAnswerInstance, UserQuestionnaireQuestionInstance } from "@Core/models";

import styles from "./Single.module.scss";

type Props = {
  field: IFieldProps;

  disabled?: boolean;
  hide?: boolean;

  question: UserQuestionnaireQuestionInstance;
};

const Single = ({ field, disabled, hide, question }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Inputs}>
      {question.answers.map((answer: UserQuestionnaireAnswerInstance) => {
        if ((hide && answer.selected) || !hide) {
          return (
            <div className={styles.Wrapper} key={`Q${question.id}A${answer.id}`}>
              <div className={styles.Radio}>
                <input
                  disabled={disabled}
                  id={`Q${question.id}A${answer.id}`}
                  type="radio"
                  {...field}
                  defaultChecked={answer.selected}
                  value={answer.id}
                />
                <label htmlFor={`Q${question.id}A${answer.id}`}>{answer.text}</label>
              </div>

              {field.value && field.value.includes("" + answer.id) && answer.open_input && (
                <div className={styles.Other}>
                  <Form.Item
                    id={`Q${question.id}A${answer.id}_OPEN`}
                    render={(props) => (
                      <Form.Questionnaire.OpenShort
                        disabled={disabled}
                        {...props}
                        question={question}
                        placeholder={t("FORMS.QUESTIONNAIRE.EXPLAIN")}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          );
        }

        return <></>;
      })}
    </div>
  );
};

export default Single;
