import axios from "@Core/axios";
import { CompanySearchType } from "@Core/constants/ENUMS";
import { CompanySnapshot } from "@Core/models";

import BaseService from "./BaseService";

class CompanyService extends BaseService<CompanySnapshot> {
  search = async (companyId: number, searchPhrase: string, type: CompanySearchType) => {
    const data = {
      searchPhrase: searchPhrase,
      type: type,
    };

    const response = await axios.post(`companies/${companyId}/search`, data);

    if (response) {
      return response.data;
    }
  };
}

export default new CompanyService("companies");
