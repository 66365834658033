import axios from "@Core/axios";

class VosService {
  start = async (companyId: number): Promise<any> => {
    const data = {
      companyId,
    };

    const response = await axios.post(`vos`, data);

    if (response && response.data) {
      return response.data;
    }

    return null;
  };
}

export default new VosService();
