import { useMatomo } from "@datapunt/matomo-tracker-react";
import cn from "classnames";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { Platform } from "@Core/constants/ENUMS";
import { useCoreStores } from "@Core/stores";

import logoLNI from "@LNI/assets/images/logo.svg";
import logoOOM from "@OOM/assets/images/logo.svg";
import logoPNI from "@PNI/assets/images/logo.svg";

import styles from "./Logo.module.scss";

type Props = {
  copyright?: boolean;
};

const Logo = ({ copyright }: Props) => {
  const { getPlatform } = useCoreStores();
  const { t } = useTranslation();
  const { trackPageView } = useMatomo();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
    trackPageView({});
  }, [pathname]);

  const getLogo = useCallback(() => {
    const logos: { [key: string]: string } = {
      [Platform.LNI]: logoLNI,
      [Platform.OOM]: logoOOM,
      [Platform.PNI]: logoPNI,
    };

    return logos[getPlatform];
  }, [getPlatform]);

  const getPrefixClasses = useCallback(() => {
    const classes: { [key: string]: string } = {
      [Platform.LNI]: cn(styles.Prefix),
      [Platform.OOM]: cn(styles.Prefix, "color-primary"),
      [Platform.PNI]: cn(styles.Prefix, "color-primary"),
    };

    return classes[getPlatform];
  }, [getPlatform]);

  return (
    <Link className={styles.Wrapper} to="/">
      <img className={styles.Logo} src={getLogo()} alt={t("DEFAULT.SITE_NAME")} />

      <div className={styles.Name}>
        <div className={getPrefixClasses()}>{t("DEFAULT.SITE_SUBNAME")}</div>
        {t("DEFAULT.SITE_NAME")}
        {copyright && <div className={styles.Copyright}>{t("DEFAULT.MENSLY_COPYRIGHT")}</div>}
      </div>
    </Link>
  );
};

export default Logo;
