import cn from "classnames";
import React from "react";

import styles from "./StackListItem.module.scss";

export interface StackListItemProps {
  title: string;
  children: string;
  icon?: string;
  isError?: boolean;
  className?: string;
  onClick?: () => void;
}

export const StackListItem = ({ className, title, isError, children, icon, onClick }: StackListItemProps) => {
  return (
    <div
      className={cn(styles.StackListItem, className, {
        [styles.hasIcon]: !!icon,
        [styles.hasOnClick]: !!onClick,
      })}
    >
      <div className={styles.Title}>{title}</div>

      <div onClick={onClick} className={cn(styles.Wrapper, isError && "color-error-light")}>
        {!!icon && (
          <i className={cn(styles.Icon, `fas fa-${isError ? "exclamation-triangle color-error-light" : icon}`)} />
        )}

        {children}
      </div>
    </div>
  );
};
