import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next/";
import { Link } from "react-router-dom";

import { Icon, Logo } from "@Core/components/UI";
import { AuthService } from "@Core/services";
import { useCoreStores } from "@Core/stores";

import styles from "./Header.module.scss";

const Header = () => {
  const { t } = useTranslation();
  const { UserStore } = useCoreStores();
  const [dropdown, setDropdown] = useState(false);

  const toggleDropdown = () => {
    const oldDropdown = dropdown;
    setDropdown(!oldDropdown);
  };

  const logout = () => {
    AuthService.logout();
    window.location.href = "";
  };

  return (
    <header className={styles.Wrapper} id="header">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.Inner}>
              <Logo />

              <div
                className={cn(styles.Profile, {
                  [styles.ProfileActive]: dropdown,
                })}
              >
                <div onClick={toggleDropdown} className={styles.ProfileWrapper}>
                  <div className={styles.ProfileName}>{UserStore?.getName}</div>

                  <Icon icon="fal fa-user" />

                  <div className={styles.ProfileToggle}>
                    <i className="far fa-chevron-down"></i>
                  </div>
                </div>

                <div className={styles.ProfileDropdown}>
                  <Link to="/mijn-profiel">
                    <i className="far fa-user"></i>
                    {t("SHARED.CHANGE_PROFILE")}
                  </Link>

                  <Link to="/" onClick={logout}>
                    <i className="far fa-edit"></i>
                    {t("SHARED.LOGOUT")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default observer(Header);
