import React from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Modal } from "@Core/components/Layouts";
import { Button } from "@Core/components/UI";
import { FileStepType, Platform } from "@Core/constants/ENUMS";
import { useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";
import { FileStepInstance } from "@Core/models/File/FileStepModel";
import { QuestionnaireService } from "@Core/services";

import { usePNIStores } from "@PNI/stores";

interface PhoneModalProps {
  step: FileStepInstance;
  isVisible: boolean;
  toggle: () => void;
}

export const PhoneModal = ({ step, isVisible, toggle }: PhoneModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const trackEvent = useMatomo();
  const { FileStore } = usePNIStores();

  const isSupervisor = step.type === FileStepType.SUPERVISOR_QUESTIONNAIRE;

  const phoneNumber = isSupervisor ? FileStore.file?.supervisor?.phone_number : FileStore.file?.employee?.phone_number;

  const handleOpen = async () => {
    if (FileStore.file && step.file_questionnaire && step.file_questionnaire.questionnaire) {
      try {
        const response = await QuestionnaireService.phone(step.file_questionnaire.id, Platform.PNI);

        FileStore.getFile(String(FileStore.file.id), true);

        trackEvent(MatomoCategory.FileStep, t("FILES.MODALS.QUESTIONNAIRE_BY_PHONE.POPUP_BUTTON"), step.title);

        if (response) {
          history.push(`/dossiers/${FileStore.file.id}/vragenlijst/${response.id}`);
        }
      } catch (e) {
        toast.error(t("SHARED.ERROR"));
      }
    }
  };

  return (
    <Modal
      title={t("FILES.MODALS.QUESTIONNAIRE_BY_PHONE.POPUP_TITLE")}
      isVisible={isVisible}
      hide={toggle}
      data-matomo-category={MatomoCategory.FileStep}
      data-matomo-name={step.title}
    >
      <p>{t(`FILES.MODALS.QUESTIONNAIRE_BY_PHONE.POPUP_DESCRIPTION_${isSupervisor ? "SUPERVISOR" : "EMPLOYEE"}`)}</p>

      <Button
        block
        iconLeft="fa-phone"
        href={`tel:${phoneNumber}`}
        primary
        className="mb-xs"
        data-matomo-category={MatomoCategory.FileStep}
        data-matomo-name={step.title}
      >
        {`${t("ADVISOR.CALL")} ${phoneNumber}`}
      </Button>

      <Button block iconLeft="fa-long-arrow-right" onClick={handleOpen} tertiary>
        {t("FILES.MODALS.QUESTIONNAIRE_BY_PHONE.POPUP_BUTTON")}
      </Button>
    </Modal>
  );
};
