import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Frame } from "@Core/components/UI";

import { StepList } from "@PNI/components/HOC";
import { usePNIStores } from "@PNI/stores";

import { RecommendedStepsProps } from "./RecommendedSteps.props";

export const RecommendedSteps = observer(({ className }: RecommendedStepsProps) => {
  const { t } = useTranslation();
  const { FileStore } = usePNIStores();

  const steps = FileStore.file?.getAdviceSteps.filter((step) => step.recommended === true) || [];
  const hasCompletedEmployeeQuestionnaire = FileStore.file?.hasCompletedEmployeeQuestionnaire;

  if (hasCompletedEmployeeQuestionnaire && !steps.length) {
    return (
      <Frame title={t("FILES.RECOMMENDED_STEPS.TITLE")} className={className} variant="light">
        <Frame.Body padding="3">
          <p className="small mb-0 color-black-light">{t("FILES.RECOMMENDED_STEPS.EMPTY.COMPLETED")}</p>
        </Frame.Body>
      </Frame>
    );
  }

  return (
    <Frame title={t("FILES.RECOMMENDED_STEPS.TITLE")} className={className}>
      <Frame.Body padding="3">
        {hasCompletedEmployeeQuestionnaire ? (
          <StepList steps={steps} hasToggle />
        ) : (
          <>
            <Frame variant="light">
              <Frame.Body padding="2">
                <p className="small mb-0 color-black-light">{t("FILES.RECOMMENDED_STEPS.EMPTY.PENDING")}</p>
              </Frame.Body>
            </Frame>

            {FileStore.file?.getEmployeeSteps && <StepList steps={FileStore.file.getEmployeeSteps} />}
          </>
        )}
      </Frame.Body>
    </Frame>
  );
});
