import axios from "@Core/axios";
import { formatDate } from "@Core/utilities";

class ExportService {
  getCSV = async (companyId: number, from: Date, to: Date, roleId?: number): Promise<null> => {
    const data = {
      params: {
        companyId,
        from: formatDate(from.toISOString()),
        to: formatDate(to.toISOString()),
        roleId,
      },
    };

    const response = await axios.get(`oom/export/csv`, data);

    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `${formatDate(new Date().toISOString())}.csv`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    return null;
  };
}

export default new ExportService();
