export type { default as IFiles } from "./Files";
export type { default as IFieldCheckbox } from "./FieldCheckbox";
export type { default as IFieldProps } from "./FieldProps";

export enum MatomoCategory {
  Authentication = "Authentication",
  Profile = "Profile",
  Header = "Header",
  Dashboard = "Dashboard",
  File = "Dossier",
  FileStep = "Dossier - Inzicht/Advies/Rapportage",
  FileInsight = "Dossier - Inzicht",
  FileReport = "Dossier - Rapportage",
  FileAdvice = "Dossier - Advies",
  FileQuestionnaire = "Dossier - Vragenlijst",
  Article = "Artikelen",
}

export interface MatomoProps {
  ["data-matomo-category"]?: MatomoCategory;
  ["data-matomo-action"]?: string;
  ["data-matomo-name"]?: string;
}
