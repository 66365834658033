import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import { Frame } from "@Core/components/UI";
import { useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";

import { NotesForm, NotesFormValues } from "@PNI/forms";
import { usePNIStores } from "@PNI/stores";

import { NotesProps } from "./Notes.props";

export const Notes = observer(({ className }: NotesProps) => {
  const { t } = useTranslation();
  const { FileStore } = usePNIStores();
  const trackEvent = useMatomo();

  const handleSubmit = async (values: NotesFormValues) => {
    const hasNotes = !!FileStore.file?.note;

    try {
      await FileStore.file?.updateNote(values.note);

      trackEvent(MatomoCategory.FileReport, t("FILES.NOTES.FORM.EDIT_NOTES.SUCCESS"));

      toast.success(t(hasNotes ? "FILES.NOTES.FORM.EDIT_NOTES.SUCCESS" : "FILES.NOTES.FORM.ADD_NOTES.SUCCESS"));
    } catch {
      toast.error(t(hasNotes ? "FILES.NOTES.FORM.EDIT_NOTES.ERROR" : "FILES.NOTES.FORM.ADD_NOTES.ERROR"));
    }
  };

  return (
    <Frame title={t("FILES.NOTES.TITLE")} className={className}>
      <Frame.Body padding="0">
        <NotesForm
          initialValues={{ note: FileStore.file?.note ?? "" }}
          onSubmit={handleSubmit}
          isDisabled={FileStore.file?.isClosed}
        />
      </Frame.Body>
    </Frame>
  );
});
