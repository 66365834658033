import cn from "classnames";
import React from "react";
import ReactDOM from "react-dom";
import LazyLoad from "react-lazyload";

import { Icon } from "@Core/components/UI";
import { useMatomo } from "@Core/hooks";
import { MatomoProps } from "@Core/interfaces";

import styles from "./Modal.module.scss";

type Props = {
  title: string;
  isVisible: boolean;
  hide?: () => void;

  children?: any;
  image?: string;
  large?: boolean;
} & MatomoProps;

const Modal = ({ title, isVisible, hide, children, large, image, ...props }: Props) => {
  const trackEvent = useMatomo();

  const handleHide = () => {
    if (props["data-matomo-category"]) {
      trackEvent(props["data-matomo-category"], `${title} gesloten`, props["data-matomo-name"]);
    }

    hide?.();
  };

  return (
    <>
      {isVisible
        ? ReactDOM.createPortal(
            <React.Fragment>
              <div className={styles.Overlay} onClick={handleHide} />

              <div className={cn([styles.Modal], { [styles.ModalLarge]: large })}>
                <div className={styles.Header}>
                  <h4 className={styles.Title}>{title}</h4>

                  {hide && (
                    <div className={styles.Close} onClick={handleHide}>
                      <Icon icon="far fa-times" />
                    </div>
                  )}
                </div>

                <div className={styles.Body}>
                  <div className={styles.Content}>{children}</div>

                  {image && (
                    <div className={styles.Image}>
                      <LazyLoad>
                        <img src={image} alt={title} />
                      </LazyLoad>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>,
            document.body,
          )
        : null}
    </>
  );
};

export default Modal;
