import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Form from "@Core/components/Form";
import { Button } from "@Core/components/UI";
import { useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";

import styles from "./ArticlesSearch.module.scss";

export const ArticlesSearch = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const trackEvent = useMatomo();

  const handleSubmit = ({ search }: { search: string }) => {
    trackEvent(MatomoCategory.Article, "Artikel filteren (Zoeken)", search);

    history.replace({
      pathname: "/artikelen",
      search: `?search=${search}`,
    });
  };

  return (
    <Form.Form onSubmit={handleSubmit} identifier="SEARCH_FORM">
      <div className={styles.ArticlesSearch}>
        <Form.ItemSimple
          id="search"
          render={(props) => (
            <Form.Text {...props} className={styles.Input} placeholder={t("ARTICLES.FILTER.SEARCH.PLACEHOLDER")} />
          )}
        />

        <Button type="submit" tertiary iconLeft="fa-search" className={styles.Button}>
          {t("ARTICLES.FILTER.SEARCH.BUTTON")}
        </Button>
      </div>
    </Form.Form>
  );
};
