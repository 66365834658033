import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import LazyLoad from "react-lazyload";

import image_question_exclamation from "@Core/assets/images/illustrations/question_exclamation.jpg";
import { Footer } from "@Core/components/Layouts";
import { Block, Loader } from "@Core/components/UI";

import { Header } from "@OOM/components/Layouts";
import { RoleService } from "@OOM/services";

import styles from "./InvitationPage.module.scss";

const InvitationPage = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const token = params.get("token");

    if (token) {
      (async () => {
        try {
          const { url } = await RoleService.redeemInviteToken(token);

          window.location = url;
        } catch {
          setLoading(false);
        }
      })();
    }
  }, []);

  return (
    <div className={styles.Wrapper}>
      <Header />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Block
              name={t("QUICKSCAN.INVITE_TOKEN.PAGE.INVALID.HEADING")}
              overflow
              center
              className={styles.Introduction}
            >
              {isLoading ? (
                <div className={styles.Loader}>
                  <Loader width={50} />
                </div>
              ) : (
                <div className={styles.Step}>
                  <div className={styles.Image}>
                    <LazyLoad>
                      <img src={image_question_exclamation} alt={t("QUICKSCAN.INVITE_TOKEN.PAGE.INVALID")} />
                    </LazyLoad>
                  </div>

                  <h3>{t("QUICKSCAN.INVITE_TOKEN.PAGE.INVALID.TITLE")}</h3>
                  <p>{t("QUICKSCAN.INVITE_TOKEN.PAGE.INVALID.DESCRIPTION")}</p>
                </div>
              )}
            </Block>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default observer(InvitationPage);
