import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Block } from "@Core/components/UI";

import { FileList } from "@LNI/components/HOC";
import { Page } from "@LNI/components/Layouts";
import { useLNIStores } from "@LNI/stores";

const FilesPage = () => {
  const { t } = useTranslation();
  const { FileStore } = useLNIStores();

  const breadcrumbs = [{ to: "/dossiers", name: t("FILES.FILES") }];

  if (FileStore.files) {
    return (
      <Page breadcrumbs={breadcrumbs}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Block name={t("FILES.MY_FILES")}>
                <FileList files={FileStore.files} />
              </Block>
            </div>
          </div>
        </div>
      </Page>
    );
  }

  return <></>;
};

export default observer(FilesPage);
