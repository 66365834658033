import React from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

import styles from "./Pagination.module.scss";
import { PaginationProps } from "./Pagination.props";

export const Pagination = ({ pageInitial = 1, pageCount, onPageChange }: PaginationProps) => {
  const { t } = useTranslation();

  if (pageCount > 1) {
    return (
      <ReactPaginate
        pageCount={Math.ceil(pageCount)}
        forcePage={pageInitial}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        onPageChange={(page) => onPageChange(page.selected)}
        nextLabel={
          <span>
            {t("ARTICLES.ARCHIVE.NEXT")}

            <i className="far fa-long-arrow-right"></i>
          </span>
        }
        previousLabel={
          <span>
            <i className="far fa-long-arrow-left"></i>

            {t("ARTICLES.ARCHIVE.PREVIOUS")}
          </span>
        }
        containerClassName={styles.Pagination}
        previousClassName={styles.PaginationPrevious}
        nextClassName={styles.PaginationNext}
        pageClassName={styles.PaginationNumber}
        activeClassName={styles.PaginationNumberActive}
        breakClassName={styles.PaginationBreak}
      />
    );
  }

  return null;
};
