import { flow, Instance, SnapshotIn, types } from "mobx-state-tree";

import { FileRecommendedAbsenceType, FileStatus, FileStepType, FileType } from "@Core/constants/ENUMS";
import { FileService } from "@Core/services";
import { leadingZero } from "@Core/utilities";

import { ArticleModel } from "../Article/ArticleModel";
import { BaseModel } from "../BaseModel";
import { UserModel } from "../UserModel";
import { UserQuestionnaireModel } from "../UserQuestionnaire/UserQuestionnaireModel";
import { EmployeeModel } from "./EmployeeModel";
import { FileCharacteristicsModel } from "./FileCharacteristicsModel";
import { FileDomainModel } from "./FileDomainModel";
import { FileGuidelinesModel } from "./FileGuidelinesModel";
import { FileQuestionnairesModel } from "./FileQuestionnairesModel";
import { FileStepModel } from "./FileStepModel";

export const FileModel = BaseModel.props({
  status: types.enumeration<FileStatus>(Object.values(FileStatus)),
  company_id: types.number,
  absence_started_at: types.maybeNull(types.string),
  absence_finished_at: types.maybeNull(types.string),
  type: types.enumeration<FileType>(Object.values(FileType)),
  duration: types.number,
  recommended_absence_type: types.maybeNull(
    types.enumeration<FileRecommendedAbsenceType>(Object.values(FileRecommendedAbsenceType)),
  ),
  supervisor_questionnaire: types.maybeNull(FileQuestionnairesModel),
  employee: EmployeeModel,
  supervisor: types.maybeNull(UserModel),
  supervisor_data: types.maybeNull(
    types.model({
      email: types.maybeNull(types.string),
      phone_number: types.maybeNull(types.string),
      first_name: types.maybeNull(types.string),
      last_name: types.maybeNull(types.string),
    }),
  ),
  vos: types.maybeNull(UserQuestionnaireModel),
  article_recommendations: types.array(ArticleModel),
  article_recommendation_enabled: types.boolean,
  domains: types.maybeNull(FileDomainModel),
  characteristics: types.maybeNull(FileCharacteristicsModel),
  resources: types.array(types.string),
  stressors: types.array(types.string),
  mirrored_questions: types.maybeNull(types.array(types.string)),
  steps: types.maybeNull(types.array(FileStepModel)),
  guidelines: types.maybeNull(types.array(FileGuidelinesModel)),
  note: types.maybeNull(types.string),
})
  .actions((self) => ({
    close: flow(function* () {
      yield FileService.closeFile(self.id);

      self.status = FileStatus.CLOSED;
    }),
    updateNote: flow(function* (note: string) {
      yield FileService.updateNote(self.id, note);

      self.note = note;
    }),
    setAbsenceType: flow(function* (type: FileRecommendedAbsenceType) {
      yield FileService.setAbsenceType(self.id, type);

      self.recommended_absence_type = type;
    }),
    closeAbsence: flow(function* () {
      yield FileService.closeAbsence(self.id);

      self.absence_finished_at = new Date().toISOString();
    }),
  }))
  .views((self) => ({
    get getId(): string {
      return leadingZero(self.id.toString(), 5);
    },

    get getAdviceSteps() {
      return (
        (self.steps &&
          self.steps.filter((step) => step.type === FileStepType.QUESTIONNAIRE || step.type === FileStepType.STEP)) ||
        []
      );
    },

    get getEmployeeSteps() {
      return self.steps && self.steps.filter((step) => step.type === FileStepType.EMPLOYEE_QUESTIONNAIRE);
    },

    get getSupervisorSteps() {
      return self.steps && self.steps.filter((step) => step.type === FileStepType.SUPERVISOR_QUESTIONNAIRE);
    },

    get isClosed(): boolean {
      return self.status === FileStatus.CLOSED || self.status === FileStatus.CLOSED_AND_ANONYMIZED;
    },

    get isOmissionClosed(): boolean {
      return !!self.absence_finished_at;
    },

    get hasCompletedEmployeeQuestionnaire(): boolean {
      if (self.steps) {
        const step = self.steps.find((step) => step.type === FileStepType.EMPLOYEE_QUESTIONNAIRE);

        if (step && step.file_questionnaire) {
          return step.file_questionnaire.isCompleted;
        }
      }

      return false;
    },

    get hasCompletedSupervisorQuestionnaire(): boolean {
      if (self.steps) {
        const step = self.steps.find((step) => step.type === FileStepType.SUPERVISOR_QUESTIONNAIRE);

        if (step && step.file_questionnaire) {
          return step.file_questionnaire.isCompleted;
        }
      }

      return false;
    },
  }));

export interface FileInstance extends Instance<typeof FileModel> {}
export type FileSnapshot = SnapshotIn<typeof FileModel>;
