import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseUserModel } from "./BaseUserModel";
import { CompanyModel } from "./CompanyModel";

export const UserModel = BaseUserModel.props({
  companies: types.array(CompanyModel),
  has_account: types.maybeNull(types.boolean),
});

export interface UserInstance extends Instance<typeof UserModel> {}
export type UserSnapshot = SnapshotIn<typeof UserModel>;
