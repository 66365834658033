import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";

import { Intake } from "@LNI/components/Components";
import { Page } from "@LNI/components/Layouts";

const IntakePage = () => {
  const { t } = useTranslation();
  const breadcrumbs = [{ to: "/", name: t("CHALLENGES.CHALLENGES") }];

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Page breadcrumbs={breadcrumbs}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Intake />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default observer(IntakePage);
