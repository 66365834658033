import cn from "classnames";
import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { IFieldCheckbox } from "@Core/interfaces";

import styles from "./Checkbox.module.scss";

type Props = {
  id: string;
  name: string;
  options: IFieldCheckbox[];
  hasSelectAll?: boolean;
};

const Checkbox = ({ id, name, options, hasSelectAll }: Props) => {
  const { t } = useTranslation();
  const [field, _, helpers] = useField(id);
  const { setValue } = helpers;

  const toggleSelectAll = () => {
    if (field.value.length === options.length) {
      setValue([]);
    } else {
      setValue(options.map((option) => option.id));
    }
  };

  return (
    <div className={cn([styles.Field], { [styles.FieldEmpty]: field.value === "" })}>
      {options.map((option: IFieldCheckbox) => {
		let checked = false;

		if (typeof (field.value) === "boolean") {
			checked = field.value;
		}
		else if (Array.isArray(field.value)) {
			checked = field.value.includes(option.id)
		}
		  
        return (
          <div key={option.id}>
            <input
              id={`${id}_${option.id}`.toUpperCase()}
              className={styles.Input}
              defaultChecked={option.checked}
              {...field}
              checked={checked}
              type="checkbox"
              value={option.id}
            />

            <label htmlFor={`${id}_${option.id}`.toUpperCase()} dangerouslySetInnerHTML={{ __html: option.name }} />
          </div>
        );
      })}

      {hasSelectAll && (
        <div>
          <input
            id="SELECT_ALL"
            className={styles.Input}
            onChange={toggleSelectAll}
            type="checkbox"
            checked={field.value.length === options.length}
          />

          <label htmlFor="SELECT_ALL">
            {field.value.length === options.length ? t("FORMS.DESELECT_ALL") : t("FORMS.SELECT_ALL")}
          </label>
        </div>
      )}
    </div>
  );
};

export default Checkbox;
