import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel } from "@Core/models";

import { AssignmentModel, ProgressModel } from "@LNI/models";

export const ChallengeModel = BaseModel.props({
  title: types.string,
  icon: types.string,
  introduction_text: types.string,
  congratulation_text: types.string,
  assignments: types.array(AssignmentModel),
  progress: ProgressModel,
}).actions((self) => ({
  setDone() {
    self.progress.done += 1;
  },
}));

export interface ChallengeInstance extends Instance<typeof ChallengeModel> {}
export type ChallengeSnapshot = SnapshotIn<typeof ChallengeModel>;
