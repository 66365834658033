import { format } from "date-fns";
import React from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import image_deprecated from "@Core/assets/images/illustrations/deprecated.jpg";
import image_question from "@Core/assets/images/illustrations/question.jpg";
import image_question_exclamation from "@Core/assets/images/illustrations/question_exclamation.jpg";
import image_security from "@Core/assets/images/illustrations/security.jpg";
import Form from "@Core/components/Form";
import { QuestionnaireFor } from "@Core/constants/ENUMS";
import { QuestionnaireTokenService } from "@Core/services";
import { TOKEN_STATUS } from "@Core/services/QuestionnaireTokenService";
import Yup from "@Core/validation";

import { DigitalQuestionnaire } from "@PNI/components/Containers";
import { usePNIStores } from "@PNI/stores";

import styles from "./Introduction.module.scss";

interface IntroductionProps {
  type?: QuestionnaireFor;
  onComplete?: () => void;
}

export const Introduction = ({ type, onComplete }: IntroductionProps) => {
  const { EmployeeStore } = usePNIStores();
  const [status, setStatus] = useState<TOKEN_STATUS>();
  const [token, setToken] = useState("");
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    zipcode: Yup.string().required(),
    date_of_birth: Yup.string().required(),
  });

  const handleSubmit = async (values: { zipcode: string; date_of_birth: string }) => {
    if (status === TOKEN_STATUS.TOKEN_STATUS_VALID) {
      await EmployeeStore.login({
        token,
        date_of_birth: format(new Date(values.date_of_birth), "yyyy-MM-dd").toString(),
        zipcode: values.zipcode,
      });
    }
  };

  const handleCloseAbsence = async () => {
    try {
      await QuestionnaireTokenService.closeAbsence();

      EmployeeStore.clear();

      toast.success(t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.CLOSE_ABSENCE`));
    } catch (e) {
      toast.error(t("SHARED.ERROR"));
    }
  };

  const handleValidate = (response: { status: TOKEN_STATUS; token: string }) => {
    setStatus(response.status);
    setToken(response.token);
  };

  return (
    <DigitalQuestionnaire.Introduction type={type} onValidated={handleValidate} status={status} onComplete={onComplete}>
      <DigitalQuestionnaire.Introduction.Step
        image={image_question}
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_1.TITLE`)}
        description={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_1.DESCRIPTION_EMPLOYEE`)}
      >
        {({ goToNextStep }) => {
          return (
            <div className={styles.Form}>
              <Form.Form
                identifier="EMPLOYEE_QUESTIONNAIRE_INTRODUCTION"
                onSuccess={() => {
                  goToNextStep();
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                submit={t("DIGITAL_QUESTIONNAIRE.INTRODUCTION.SUBMIT")}
              >
                <Form.Item
                  id="date_of_birth"
                  name={t("DIGITAL_QUESTIONNAIRE.INTRODUCTION.BIRTHDATE")}
                  render={(props) => <Form.Date {...props} icon="calendar" />}
                />

                <Form.Item
                  id="zipcode"
                  name={t("DIGITAL_QUESTIONNAIRE.INTRODUCTION.ZIPCODE")}
                  render={(props) => <Form.Text {...props} icon="location-dot" />}
                />
              </Form.Form>
            </div>
          );
        }}
      </DigitalQuestionnaire.Introduction.Step>
      <DigitalQuestionnaire.Introduction.Step
        image={image_question_exclamation}
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_2.TITLE`)}
        description={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_2.DESCRIPTION`)}
      />
      <DigitalQuestionnaire.Introduction.Step
        image={image_question}
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_3.TITLE`)}
        description={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_3.DESCRIPTION`)}
      />
      <DigitalQuestionnaire.Introduction.Step
        image={image_security}
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_4.TITLE`)}
        description={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_4.DESCRIPTION`)}
      />
      <DigitalQuestionnaire.Introduction.Step
        image={image_deprecated}
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_5.TITLE`)}
        description={
          <Trans
            i18nKey={`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_5.DESCRIPTION`}
            components={{ button: <a className="pointer" onClick={handleCloseAbsence} /> }}
          />
        }
      />
    </DigitalQuestionnaire.Introduction>
  );
};
