import groupBy from "lodash.groupby";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Form from "@Core/components/Form";
import { IFieldProps } from "@Core/interfaces";
import { UserQuestionnaireAnswerInstance, UserQuestionnaireQuestionInstance } from "@Core/models";

import styles from "./Multiple.module.scss";

type Props = {
  field: IFieldProps;

  disabled?: boolean;
  hide?: boolean;

  question: UserQuestionnaireQuestionInstance;
};

const Multiple = ({ field, disabled, hide, question }: Props): JSX.Element => {
  const { t } = useTranslation();

  const nonGroupedAnswers = useMemo(() => {
    return question.answers.filter((answer) => !answer.group);
  }, [question.answers]);

  const groupedAnswers = useMemo(() => {
    return groupBy(
      question.answers.filter((answer) => !!answer.group),
      "group",
    );
  }, [question.answers]);

  const groupedAnswersGroups = Object.keys(groupedAnswers);

  const getInput = (answer: UserQuestionnaireAnswerInstance) => {
    if ((hide && answer.selected) || !hide) {
      return (
        <div className={styles.Wrapper} key={`Q${question.id}A${answer.id}`}>
          <div className={styles.Checkbox}>
            <input
              disabled={disabled}
              id={`Q${question.id}A${answer.id}`}
              type="checkbox"
              defaultChecked={answer.selected}
              {...field}
              value={answer.id}
            />

            <label htmlFor={`Q${question.id}A${answer.id}`}>{answer.text}</label>
          </div>

          {field.value && field.value.includes("" + answer.id) && answer.open_input && (
            <div className={styles.Other}>
              <Form.Item
                id={`Q${question.id}A${answer.id}_OPEN`}
                render={(props) => (
                  <Form.Questionnaire.OpenShort
                    disabled={disabled}
                    {...props}
                    question={question}
                    placeholder={t("FORMS.QUESTIONNAIRE.EXPLAIN")}
                  />
                )}
              />
            </div>
          )}
        </div>
      );
    }

    return <></>;
  };

  return (
    <div className={styles.Inputs}>
      {nonGroupedAnswers.length > 0 &&
        nonGroupedAnswers.map((answer) => {
          return getInput(answer);
        })}

      {groupedAnswersGroups.length > 0 &&
        groupedAnswersGroups.map((groupedAnswersGroup) => {
          return (
            <div key={groupedAnswersGroup}>
              <h5 className="mt-md">{groupedAnswersGroup}</h5>

              {groupedAnswers[groupedAnswersGroup].map((groupedAnswer) => {
                return getInput(groupedAnswer);
              })}
            </div>
          );
        })}
    </div>
  );
};

export default Multiple;
