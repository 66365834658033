import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import image_deprecated from "@Core/assets/images/illustrations/deprecated.jpg";
import image_question_exclamation from "@Core/assets/images/illustrations/question_exclamation.jpg";
import image_security from "@Core/assets/images/illustrations/security.jpg";
import { Questionnaire, QuestionnaireCompleted, Video } from "@Core/components/Components";
import { Footer } from "@Core/components/Layouts";
import { Block, Button, Step } from "@Core/components/UI";
import { QuestionnaireTokenService } from "@Core/services";
import { TOKEN_STATUS } from "@Core/services/QuestionnaireTokenService";
import { useCoreStores } from "@Core/stores";
import { parseHTML } from "@Core/utilities";

import { Header } from "@OOM/components/Layouts";

import styles from "./QuestionnairePage.module.scss";

interface QuestionnairePageIntroductionStepInterface {
  title: string;
  description: string;
  videoUrl?: string;
  imageUri?: string;
}

type QuestionnairePageIntroductionStep = {
  title: string;
  description: string;
  imageUri?: string;
  videoUrl?: string;
  children?: any;
};

const QuestionnairePageIntroductionStep = ({
  title,
  description,
  imageUri,
  videoUrl,
  children,
}: QuestionnairePageIntroductionStep) => {
  return (
    <div className={styles.Step}>
      {videoUrl && <Video url={videoUrl} />}

      {imageUri && (
        <div className={styles.Image}>
          <LazyLoad>
            <img src={imageUri} alt={title} />
          </LazyLoad>
        </div>
      )}

      <h3>{title}</h3>
      <p>{description}</p>

      {children && children}
    </div>
  );
};

const QuestionnairePageIntroduction = observer(() => {
  const { QuestionnaireStore } = useCoreStores();

  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const history = useHistory();

  const [steps, setSteps] = useState<QuestionnairePageIntroductionStepInterface[]>([]);
  const [status, setStatus] = useState<TOKEN_STATUS>();
  const [token, setToken] = useState("");

  // Check if token is valid, if so store token to the store
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const currentToken = params.get("token");

    if (currentToken) {
      (async () => {
        await QuestionnaireTokenService.validateToken(currentToken)
          .then((response: any) => {
            setStatus(response.status);

            // Set token when response status is valid
            if (response.status === TOKEN_STATUS.TOKEN_STATUS_VALID) {
              setToken(currentToken);
            }
          })
          .catch(() => {
            history.push("/");
          });
      })();
    }
  }, [history]);

  // Set steps if there is a valid token within the store
  useEffect(() => {
    const a: QuestionnairePageIntroductionStepInterface[] = [];

    a.push({
      imageUri: image_question_exclamation,
      title: t("QUESTIONNAIRE.INTRODUCTION.STEP_1.TITLE"),
      description: t("QUESTIONNAIRE.INTRODUCTION.STEP_1.DESCRIPTION_SUPERVISOR"),
    });

    a.push({
      imageUri: image_security,
      title: t("QUESTIONNAIRE.INTRODUCTION.STEP_2.TITLE"),
      description: t("QUESTIONNAIRE.INTRODUCTION.STEP_2.DESCRIPTION"),
    });

    setSteps(a);
  }, [t]);

  const previousStep = () => {
    const old = step;
    setStep(old - 1);
  };

  const nextStep = async () => {
    const old = step;
    setStep(old + 1);

    if (step === steps.length - 1) {
      await QuestionnaireTokenService.validateUser({ token });

      const questionnaire = await QuestionnaireTokenService.me();
      QuestionnaireStore.setQuestionnaire(questionnaire.user_questionnaire);
      QuestionnaireStore.questionnaire?.load();
    }
  };

  const getStep = (step: number) => {
    if (steps[step]) {
      return (
        <>
          <QuestionnairePageIntroductionStep
            title={steps[step].title}
            description={steps[step].description}
            imageUri={steps[step].imageUri ? steps[step].imageUri : undefined}
            videoUrl={steps[step].videoUrl ? steps[step].videoUrl : undefined}
          />
          <div className={styles.Navigation}>
            <Button primary link disabled={step < 1} hover iconLeft="fa-long-arrow-left" onClick={() => previousStep()}>
              {t("SHARED.PREVIOUS")}
            </Button>

            <Button
              primary
              link={step !== steps.length - 1}
              hover
              iconRight="fa-long-arrow-right"
              onClick={() => nextStep()}
            >
              {step !== steps.length - 1 ? (
                <span>{t("SHARED.NEXT")}</span>
              ) : (
                <span>{t("QUESTIONNAIRE.INTRODUCTION.PROCEED")}</span>
              )}
            </Button>
          </div>
        </>
      );
    }
  };

  const getDeprecated = () => {
    return (
      <QuestionnairePageIntroductionStep
        title={t("QUESTIONNAIRE.INTRODUCTION.DEPRECATED.TITLE")}
        description={t("QUESTIONNAIRE.INTRODUCTION.DEPRECATED.DESCRIPTION")}
        imageUri={image_deprecated}
      />
    );
  };

  const getAlreadyCompleted = () => {
    return (
      <QuestionnairePageIntroductionStep
        title={t("QUESTIONNAIRE.INTRODUCTION.COMPLETED.TITLE")}
        description={t("QUESTIONNAIRE.INTRODUCTION.COMPLETED.DESCRIPTION")}
        imageUri={image_question_exclamation}
      />
    );
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <Block name={t("QUESTIONNAIRE.INTRODUCTION.TITLE")} overflow center className={styles.Introduction}>
            {status === TOKEN_STATUS.TOKEN_STATUS_EXPIRED && getDeprecated()}
            {status === TOKEN_STATUS.TOKEN_STATUS_PROCESSED && getAlreadyCompleted()}
            {status === TOKEN_STATUS.TOKEN_STATUS_VALID && (
              <>
                <Step className={styles.Progress} step={step + 1} steps={steps.length} dots hide />
                {getStep(step)}
              </>
            )}
          </Block>
        </div>
      </div>
    </div>
  );
});

const QuestionnairePageQuestionnaire = observer(({ role }: { role: string }) => {
  const { t } = useTranslation();
  const [isCompleted, setIsCompleted] = useState(false);
  const { QuestionnaireStore } = useCoreStores();

  const onCompletion = () => {
    setIsCompleted(true);
    toast.success(t("QUESTIONNAIRE.FILE.SUCCESS"));

    QuestionnaireTokenService.logout();
  };

  if (QuestionnaireStore.questionnaire) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="mt-md mb-md">
              <h2 className="color-primary">{t("QUESTIONNAIRE.FILE.TITLE", { role })}</h2>

              {parseHTML(QuestionnaireStore.questionnaire.questionnaire.information)}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">
            {isCompleted && (
              <>
                <QuestionnaireCompleted title={t("QUESTIONNAIRE.FILE.COMPLETED.INFORMATION")} />
              </>
            )}

            <Questionnaire id={QuestionnaireStore.questionnaire.id} onCompletion={() => onCompletion()} block expire />
          </div>
        </div>
      </div>
    );
  }

  return <></>;
});

const QuestionnairePage = () => {
  const { QuestionnaireStore } = useCoreStores();
  const [hasLoaded, setHasLoaded] = useState(true);
  const [role, setRole] = useState("");

  useEffect(() => {
    const cleanup = () => {
      QuestionnaireTokenService.logout();
    };

    window.addEventListener("beforeunload", cleanup);

    return () => {
      cleanup();
      window.removeEventListener("beforeunload", cleanup);
    };
  }, []);

  useEffect(() => {
    // Check if a token is already set, if so check if token is correct and load user data...
    if (QuestionnaireTokenService.getToken() && !QuestionnaireStore.questionnaire) {
      setHasLoaded(false);

      (async () => {
        try {
          const questionnaire = await QuestionnaireTokenService.me();
          QuestionnaireStore.setQuestionnaire(questionnaire.user_questionnaire);
          await QuestionnaireStore.questionnaire?.load();

          setRole(questionnaire.oom_role.name);
          setHasLoaded(true);
        } catch (e) {
          QuestionnaireTokenService.logout();
          setHasLoaded(true);
        }
      })();
    }
  }, [QuestionnaireStore, QuestionnaireStore.questionnaire]);

  return (
    <div className={styles.Wrapper}>
      <Header />

      {hasLoaded && (
        <>
          {QuestionnaireStore.questionnaire ? (
            <QuestionnairePageQuestionnaire role={role} />
          ) : (
            <QuestionnairePageIntroduction />
          )}
        </>
      )}

      <Footer />
    </div>
  );
};

export default observer(QuestionnairePage);
