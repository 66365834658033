import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";

import { Block, Button } from "@Core/components/UI";

import { AssignmentType } from "@LNI/constants/ENUMS";
import { AssignmentInstance, MomentInstance } from "@LNI/models";

import Article from "./Article/Article";
import styles from "./Assignment.module.scss";
import Movie from "./Movie/Movie";
import Poll from "./Poll/Poll";
import Question from "./Question/Question";
import Text from "./Text/Text";

type Props = {
  assignment: AssignmentInstance | MomentInstance;
};

const Assignment = ({ assignment }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!assignment.canView) {
      history.push("/");
    }
  }, [assignment, history]);

  const getAssignment = () => {
    switch (assignment.type) {
      case AssignmentType.VIDEO:
        return <Movie assignment={assignment} />;
      case AssignmentType.QUESTIONNAIRE:
      case AssignmentType.COMPANY_ASSIGNMENT:
        return <Question assignment={assignment} />;
      case AssignmentType.TEXT:
      case AssignmentType.IMAGE:
        return <Text assignment={assignment} />;
      case AssignmentType.ARTICLE:
        return <Article assignment={assignment} />;
      case AssignmentType.POLL:
        return <Poll assignment={assignment} />;
    }
  };

  if (assignment) {
    let moment = undefined;

    if (assignment.isMoment) {
      moment = assignment as MomentInstance;
    }

    return (
      <>
        <Block
          success={moment?.isPivotDone || assignment.isDone}
          warning={moment?.isPivotExpired}
          name={t(`ASSIGNMENT.${assignment.getType}.NAME`)}
        >
          {getAssignment()}
        </Block>

        <Button
          secondary
          to={t(`ASSIGNMENT.${assignment.category}.ROUTE`).replace(":id", assignment.getParentSlug)}
          className={styles.Back}
        >
          {t(`ASSIGNMENT.${assignment.category}.BACK`)}
        </Button>
      </>
    );
  }

  return <></>;
};

export default observer(Assignment);
