import React from "react";

import { IFieldProps } from "@Core/interfaces";
import { TagSnapshot } from "@Core/models";

import styles from "./Tag.module.scss";

type Props = {
  id: string;
  name: string;
  field: IFieldProps;

  disabled?: boolean;
  items: TagSnapshot[];
};

const Tag = ({ id, name, field, disabled, items }: Props) => {
  return (
    <div className={styles.Inputs}>
      {items.map((answer: TagSnapshot) => (
        <div key={answer.id} className={styles.Tag}>
          <input
            disabled={disabled}
            id={`${answer.id}`}
            type="checkbox"
            defaultChecked={false}
            {...field}
            value={answer.id}
          />

          <label htmlFor={`${answer.id}`}>{answer.body}</label>
        </div>
      ))}
    </div>
  );
};

export default Tag;
