import React from "react";

import { IFieldProps } from "@Core/interfaces";
import { UserQuestionnaireQuestionInstance } from "@Core/models";

import styles from "./OpenShort.module.scss";

type Props = {
  id: string;
  name: string;
  field: IFieldProps;

  disabled?: boolean;
  question: UserQuestionnaireQuestionInstance;
  placeholder?: string;
};

const OpenShort = ({ id, name, field, disabled, question, placeholder }: Props) => {
  return (
    <div className={styles.Inputs}>
      <input
        type="text"
        id={id}
        disabled={disabled}
        className={styles.Textfield}
        {...field}
        placeholder={placeholder}
      />
    </div>
  );
};

export default OpenShort;
