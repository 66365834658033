import React from "react";
import { Route } from "react-router-dom";

import { EmployeeQuestionnarePage, SupervisorQuestionnarePage } from "@PNI/pages";

export const getPublicRoutes = () => {
  return [
    <Route
      key="/digitale-medewerkervragenlijst"
      path="/digitale-medewerkervragenlijst"
      exact
      render={() => <EmployeeQuestionnarePage />}
    />,

    <Route
      key="/digitale-leidinggevendevragenlijst"
      path="/digitale-leidinggevendevragenlijst"
      exact
      render={() => <SupervisorQuestionnarePage />}
    />,
  ];
};
