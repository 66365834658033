import cn from "classnames";
import React, { useCallback } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Tag } from "@Core/components/UI";

import { IPreprRestTag } from "@PNI/services";

import styles from "./ArticlesArchive.module.scss";
import { ArticlesArchiveProps } from "./ArticlesArchive.props";
import { ArticlesArchiveFilter, ArticlesArchiveFilterValues, ArticlesArchiveList } from "./internal/components";

export const ArticlesArchive = ({}: ArticlesArchiveProps) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<ArticlesArchiveFilterValues>();
  const history = useHistory();

  const hasFilter = (filter?.keywords && filter?.keywords?.length > 0) || !!filter?.theme || !!filter?.search;

  const handleSearch = useCallback(() => {
    setFilter((oldFilter) => {
      const newFilter = Object.assign({}, oldFilter);
      newFilter.search = undefined;

      history.replace({
        pathname: "/artikelen",
        search: "",
      });

      return newFilter;
    });
  }, []);

  const handleTheme = useCallback(() => {
    setFilter((oldFilter) => {
      const newFilter = Object.assign({}, oldFilter);
      newFilter.theme = undefined;

      return newFilter;
    });
  }, []);

  const handleKeywords = useCallback(
    (keyword: IPreprRestTag) => () => {
      setFilter((oldFilter) => {
        const newFilter = Object.assign({}, oldFilter);

        if (newFilter.keywords && newFilter.keywords.length > 0) {
          const foundKeywordIndex = newFilter.keywords?.findIndex(
            (newActiveKeyword) => newActiveKeyword.id === keyword.id,
          );

          if (foundKeywordIndex !== -1) {
            newFilter.keywords.splice(foundKeywordIndex, 1);
          } else {
            newFilter.keywords.push(keyword);
          }
        }

        return newFilter;
      });
    },
    [],
  );

  return (
    <div className={styles.ArticlesArchive}>
      <div className="row">
        <div className="col-lg-12">
          <p className="mb-sm caps small color-support">{t("ARTICLES.TITLE")}</p>
        </div>
      </div>

      <div className={cn(styles.Row, "row")}>
        <div className="col-lg-8 col-sm-12">
          {filter && hasFilter && (
            <div className={styles.Filters}>
              <div className={styles.Label}>{t("ARTICLES.FILTER.ACTIVE.ACTIVE_FILTERS")}</div>

              {filter.search && (
                <Tag
                  onClick={handleSearch}
                  name={t("ARTICLES.FILTER.ACTIVE.SEARCH", {
                    search: filter.search,
                  })}
                />
              )}

              {filter.theme && <Tag onClick={handleTheme} key={filter.theme.slug} name={filter.theme.body} />}

              {filter.keywords?.map((keyword) => {
                return <Tag onClick={handleKeywords(keyword)} key={keyword.slug} name={keyword.body} />;
              })}
            </div>
          )}

          <ArticlesArchiveList filter={filter} />
        </div>

        <div className={cn(styles.Sidebar, "col-lg-4 col-sm-12")}>
          <ArticlesArchiveFilter filter={filter} onChange={setFilter} />
        </div>
      </div>
    </div>
  );
};
