import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { FileQuestionModel } from "./FileQuestionModel";

const FileDomainItemModel = types.model("FileDomainItemModel", {
  score: types.number,
  questions: types.array(FileQuestionModel),
});

export const FileDomainModel = types.model("FileDomainModel", {
  health: FileDomainItemModel,
  work: FileDomainItemModel,
  motivation: FileDomainItemModel,
  private: FileDomainItemModel,
});

export interface FileDomainInstance extends Instance<typeof FileDomainModel> {}
export type FileDomainSnapshot = SnapshotIn<typeof FileDomainModel>;
