import axios from "@Core/axios";
import { Platform } from "@Core/constants/ENUMS";
import { UserQuestionnaireInstance } from "@Core/models";

class QuestionnaireService {
  start = async (questionnaireId: number, assignmentId?: number): Promise<void> => {
    try {
      const data = {
        questionnaire_id: questionnaireId,
        assignment_id: assignmentId,
      };

      const response = await axios.post("questionnaires/start", data);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {}
  };

  phone = async (questionnaireId: number, platform: Platform): Promise<UserQuestionnaireInstance | void> => {
    try {
      const urls: { [key: string]: string } = {
        [Platform.PNI]: `aom/questionnaires/${questionnaireId}/phone`,
        [Platform.OOM]: `oom/roles/${questionnaireId}/phone`,
      };

      const response = await axios.post(urls[platform]);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      throw new Error("ERROR_BY_PHONE");
    }
  };

  finish = async (userQuestionnaireId: number): Promise<void> => {
    try {
      const response = await axios.post(`userquestionnaires/${userQuestionnaireId}/finish`);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {}
  };

  next = async (userQuestionnaireId: number, stepId: string | number, answer?: any): Promise<void> => {
    try {
      const data = {
        step_id: stepId,
        answer: answer,
      };

      const response = await axios.post(`userquestionnaires/${userQuestionnaireId}`, data);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {}
  };

  previous = async (userQuestionnaireId: number, previousId?: string | number | null): Promise<void> => {
    try {
      let data = {};

      if (previousId) {
        data = {
          params: {
            progressId: previousId,
          },
        };
      }

      const response = await axios.get(`userquestionnaires/${userQuestionnaireId}/progress`, data);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {}
  };

  restart = async (userQuestionnaireId: number): Promise<void> => {
    try {
      const response = await axios.post(`userquestionnaires/${userQuestionnaireId}/restart`);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {}
  };

  load = async (userQuestionnaireId: number): Promise<any> => {
    try {
      const response = await axios.get(`userquestionnaires/${userQuestionnaireId}`);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {}
  };

  sendable = async (): Promise<any> => {
    try {
      const response = await axios.get("questionnaires/sendable");

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {}
  };
}

export default new QuestionnaireService();
