import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel } from "@Core/models";

import { CompanyRoleQuestionnaireModel } from "./CompanyRoleQuestionnaireModel";
import { InviteTokenModel, InviteTokenSnapshot } from "./InviteTokenModel";

export const CompanyRoleModel = BaseModel.props({
  oom_role_id: types.number,
  company_id: types.number,
  role: types.model({
    id: types.identifierNumber,
    name: types.string,
    slug: types.string,
  }),
  invite_tokens: types.array(InviteTokenModel),
  role_questionnaires: types.array(CompanyRoleQuestionnaireModel),
}).actions((self) => ({
  addInviteToken: (token: InviteTokenSnapshot) => {
    const tokenInstance = InviteTokenModel.create(token);
    self.invite_tokens.push(tokenInstance);

    return tokenInstance;
  },
}));

export interface CompanyRoleInstance extends Instance<typeof CompanyRoleModel> {}
export type CompanyRoleSnapshot = SnapshotIn<typeof CompanyRoleModel>;
