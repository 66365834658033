import React from "react";

import { IFieldProps } from "@Core/interfaces";

import styles from "./Textarea.module.scss";

type Props = {
  id: string;
  field: IFieldProps;

  disabled?: boolean;
  placeholder?: string;
  icon?: string;
};

const Textarea = ({ id, field, disabled, placeholder, icon }: Props) => {
  return (
    <div className={styles.Field}>
      <textarea id={id} className={styles.Input} {...field} disabled={disabled} placeholder={placeholder}>
        {field.value}
      </textarea>

      {icon && <i className={`${styles.Icon} far fa-${icon}`}></i>}
    </div>
  );
};

export default Textarea;
