import cn from "classnames";
import { useField } from "formik";
import React from "react";

import styles from "./Radio.module.scss";

type Props = {
  id: string;
  name: string;
  options: Record<string, string>;
};

const Radio = ({ id, name, options }: Props) => {
  const [field] = useField(id);

  return (
    <div className={cn([styles.Field], { [styles.FieldEmpty]: field.value === "" })}>
      {Object.keys(options).map((key) => (
        <div key={key}>
          <input id={options[key]} className={styles.Input} {...field} type="radio" value={key} />
          <label htmlFor={options[key]} dangerouslySetInnerHTML={{ __html: options[key] }} />
        </div>
      ))}
    </div>
  );
};

export default Radio;
