import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { PreprRenderer } from "@PNI/components/Containers";

import styles from "./ArticlesDetailBody.module.scss";
import { ArticlesDetailBodyProps } from "./ArticlesDetailBody.props";
import { ArticlesDetailBodyActivation } from "./internal/components";

export const ArticlesDetailBody = ({ article, className }: ArticlesDetailBodyProps) => {
  const { t } = useTranslation();

  const getActivation = () => {
    const getActivationTips = () => {
      if (article.activation_tips) {
        return (
          <div className="col-lg-6">
            <ArticlesDetailBodyActivation
              icon={article.activation_tips_icon ? article.activation_tips_icon : "star"}
              title={
                article.activation_tips_title ? article.activation_tips_title : t("ARTICLES.ARTICLE.ACTIVATION.TIPS")
              }
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: article.activation_tips,
                }}
              ></span>

              {article.activation_tips_links && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: article.activation_tips_links,
                  }}
                ></div>
              )}
            </ArticlesDetailBodyActivation>
          </div>
        );
      }
    };

    const getActivationSteps = () => {
      if (article.activation_steps) {
        return (
          <div className="col-lg-6">
            <ArticlesDetailBodyActivation
              icon={article.activation_steps_icon ? article.activation_steps_icon : "shoe-prints"}
              title={
                article.activation_steps_title ? article.activation_steps_title : t("ARTICLES.ARTICLE.ACTIVATION.STEPS")
              }
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: article.activation_steps,
                }}
              ></span>

              {article.activation_steps_links && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: article.activation_steps_links,
                  }}
                ></div>
              )}
            </ArticlesDetailBodyActivation>
          </div>
        );
      }
    };

    if (article.activation_tips || article.activation_steps) {
      return (
        <div className={styles.Activation}>
          <div className="container">
            <div className="row">
              <div className="col-xl-3 hidden-lg-down"></div>

              <div className="col-xl-6 col-lg-8 col-md-12">
                <h2 className="hero hero--large">{article.activation_title}</h2>

                {article.activation_intro && <p className="light">{article.activation_intro}</p>}
              </div>
            </div>

            <div className="row justify-content-center">
              {getActivationTips()}
              {getActivationSteps()}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={cn(styles.ArticlesDetailBody, className)}>
      <PreprRenderer content={article.content} />

      {getActivation()}
    </div>
  );
};
