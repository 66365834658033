import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Block } from "@Core/components/UI";

import { FileList } from "@PNI/components/HOC";
import { usePNIStores } from "@PNI/stores";

import { DashboardPageGenerate } from "../DashboardPageGenerate/DashboardPageGenerate";

export const DashboardFiles = () => {
  const { FileStore } = usePNIStores();
  const { t } = useTranslation();

  useEffect(() => {
    FileStore.clearFile();
  }, []);

  return (
    <>
      <Block name={t("FILES.MY_FILES")}>
        <FileList files={FileStore.files} />

        <div className="mt-md d-flex">
          <DashboardPageGenerate />
        </div>
      </Block>

      <Block name={t("FILES.MY_FILES_ARCHIVE")} isCollapsed>
        <FileList archive files={FileStore.archived} />
      </Block>
    </>
  );
};
