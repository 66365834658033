import { flow, Instance, types } from "mobx-state-tree";

import { UserQuestionnaireModel, UserQuestionnaireSnapshot } from "@Core/models";
import { QuestionnaireService } from "@Core/services";

const QuestionnaireStore = types
  .model({
    questionnaire: types.maybeNull(UserQuestionnaireModel),
  })
  .actions((self) => ({
    load: flow(function* (id: number, reload = false) {
      if (!self.questionnaire || self.questionnaire?.id !== parseInt("" + id) || reload) {
        const response = yield QuestionnaireService.load(id);
        self.questionnaire = response;
      }
    }),

    clear() {
      self.questionnaire = null;
    },

    setQuestionnaire(questionnaire: UserQuestionnaireSnapshot) {
      self.questionnaire = UserQuestionnaireModel.create(questionnaire);
    },
  }));

export interface QuestionnaireStoreInstance extends Instance<typeof QuestionnaireStore> {}

export default QuestionnaireStore;
