import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { Assignment } from "@LNI/components/Components";
import { Page } from "@LNI/components/Layouts";
import { useLNIStores } from "@LNI/stores";

const CourseAssignmentPage = () => {
  const { t } = useTranslation();
  const { course, assignment } = useParams();
  const history = useHistory();
  const { CourseStore } = useLNIStores();

  const breadcrumbs = [
    { to: "/", name: t("COURSES.COURSES") },
    { to: `/cursussen/${CourseStore.course?.id}`, name: CourseStore.course?.title },
    { name: CourseStore.assignment?.title },
  ];

  useEffect(() => {
    (async function load() {
      try {
        if (CourseStore.hasCourses) {
          await CourseStore.getCourse(course, assignment);
        }
      } catch (err) {
        history.push(`/cursussen/${CourseStore.course?.id}`);
      }
    })();
  }, [CourseStore, CourseStore.hasCourses, course, assignment, history]);

  if (CourseStore.course && CourseStore.assignment) {
    return (
      <Page breadcrumbs={breadcrumbs}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Assignment assignment={CourseStore.assignment} />
            </div>
          </div>
        </div>
      </Page>
    );
  }

  return <></>;
};

export default observer(CourseAssignmentPage);
