import { createContext, useContext } from "react";

import CoreRootStore, { CoreRootStoreInstance } from "./CoreRootStore";
/**
 * Create a context we can use to
 * - Provide access to our stores from our root component
 * - Consume stores in our screens (or other components, though it's
 *   preferable to just connect screens)
 */
const CoreRootStoreContext = createContext(CoreRootStore);
/**
 * The provider our root component will use to expose the root store
 */
export const CoreRootStoreProvider = CoreRootStoreContext.Provider;
/**
 * A hook that screens can use to gain access to our stores, with
 * `const { someStore, someOtherStore } = useStores()`,
 * or less likely: `const rootStore = useStores()`
 */
export const useCoreStores = (): CoreRootStoreInstance => useContext(CoreRootStoreContext);

export { default as CoreRootStore } from "./CoreRootStore";
