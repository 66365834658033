import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router";

import { CardRow } from "@Core/components/UI";

export type DashboardPageQuestionListProps = {
  files: { id: string; title: string; onClickSend: () => void; onClickPhone: () => void }[];
};

const DashboardPageQuestionList = ({ files }: DashboardPageQuestionListProps) => {
  const router = useHistory();
  const { t } = useTranslation();

  const handleRowClick = (id: string) => {
    router.push(`/antwoorden/${id}`);
  };

  if (files) {
    return (
      <div>
        {files.map((file) => {
          return (
            <CardRow
              largeIcon
              key={file.id}
              icon={"far fa-file-alt"}
              title={`${file.title}`}
              onClick={() => handleRowClick(file.id)}
              buttonLink={{
                title: t("QUICKSCAN.QUESTION_BY_PHONE"),
                onClick: () => {
                  file.onClickPhone();
                },
              }}
              buttonPrimaryLast={{
                title: t("QUICKSCAN.QUESTION_SEND"),
                onClick: () => {
                  file.onClickSend();
                },
              }}
            />
          );
        })}
      </div>
    );
  }

  return <></>;
};

export default observer(DashboardPageQuestionList);
