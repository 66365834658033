import cn from "classnames";
import React, { useCallback } from "react";

import { Block, Button } from "@Core/components/UI";
import { useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";

import styles from "./Menu.module.scss";
import { MenuProps } from "./Menu.props";

const Menu = ({ menuItems, className }: MenuProps) => {
  const classes = cn(styles.Menu, className);
  const trackEvent = useMatomo();

  const handleScroll = useCallback(
    (to: string, label: string) => () => {
      trackEvent(MatomoCategory.Dashboard, label);

      const elem = document.querySelector(to);

      if (!!elem) {
        window.scrollTo({
          top: elem.getBoundingClientRect().top - 15,
          behavior: "smooth",
        });
      }
    },
    [],
  );

  return (
    <Block className={classes}>
      {menuItems.map((item, idx) => {
        return (
          <Button key={idx} className={styles.Item} onClick={handleScroll(item.to, item.label)}>
            <i className={cn(styles.Icon, "far", `fa-${item.icon}`)}>
              {!!item.count && item.count > 0 && <div className={styles.Count}>{item.count}</div>}
            </i>

            {item.label}
          </Button>
        );
      })}
    </Block>
  );
};

export default Menu;
