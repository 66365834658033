import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { UserQuestionnaireSectionModel } from "./UserQuestionnaireSectionModel";

export const UserQuestionnaireSectionsModel = types.model("UserQuestionnaireSectionsModel", {
  isCurrent: false,
  isDone: false,
  isSkipped: false,
  section: UserQuestionnaireSectionModel,
});

export interface UserQuestionnaireSectionsInstance extends Instance<typeof UserQuestionnaireSectionsModel> {}
export type UserQuestionnaireSectionsSnapshot = SnapshotIn<typeof UserQuestionnaireSectionsModel>;
