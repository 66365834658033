import cn from "classnames";
import React, { ReactNode } from "react";

import Icon from "../Icon/Icon";
import styles from "./Frame.module.scss";

type FrameProps = {
  title?: string;
  icon?: string;
  iconVariant?: "success" | "danger";
  variant?: "light" | "primary";
  elevated?: "small" | "large";
  children: ReactNode;
  className?: string;
};

const Frame = ({ title, icon, iconVariant, children, variant, elevated, className }: FrameProps) => {
  const classes = cn([styles.Frame], className, {
    [styles[`Frame--variant-${variant}`]]: !!variant,
    [styles[`Frame--elevated-${elevated}`]]: !!elevated,
  });

  if (title) {
    return (
      <>
        <h3 className="d-flex align-items-center">
          {!!icon && (
            <Icon success={iconVariant === "success"} danger={iconVariant === "danger"} icon={icon} className="mr-xs" />
          )}

          {title}
        </h3>

        <div className={classes}>{children}</div>
      </>
    );
  }

  return <div className={classes}>{children}</div>;
};

type FrameBodyProps = {
  padding?: "0" | "2" | "3" | "4";
  children: ReactNode;
  className?: string;
};

const FrameBody = ({ padding = "4", children, className }: FrameBodyProps) => {
  return <div className={cn(styles.FrameBody, className, styles[`FrameBody--padding-${padding}`])}>{children}</div>;
};

type FrameExpandProps = {
  onClick?: () => void;
  children: ReactNode;
};

const FrameExpand = ({ onClick, children }: FrameExpandProps) => {
  return (
    <div onClick={onClick} className={cn(styles.FrameExpand)}>
      {children}
    </div>
  );
};

export default Object.assign(Frame, {
  Body: FrameBody,
  Expand: FrameExpand,
});
