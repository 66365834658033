import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel } from "@Core/models";

export const InviteTokenModel = BaseModel.props({
  limit: types.number,
  expire_date: types.string,
  token: types.string,
  usage: types.number,
  is_expired: types.boolean,
  is_used: types.boolean,
}).views((self) => ({
  get getLink() {
    return `${window.location.origin}/uitnodiging?token=${self.token}`;
  },
}));

export interface InviteTokenInstance extends Instance<typeof InviteTokenModel> {}
export type InviteTokenSnapshot = SnapshotIn<typeof InviteTokenModel>;
