import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel } from "./BaseModel";

export const ImageModel = BaseModel.props({
  path: types.string,
});

export interface ImageInstance extends Instance<typeof ImageModel> {}
export type ImageSnapshot = SnapshotIn<typeof ImageModel>;
