import RichText from "../Form/RichText/RichText";
import Checkbox from "./Checkbox/Checkbox";
import Date from "./Date/Date";
import FileInput from "./FileInput/FileInput";
import Form from "./Form/Form";
import Group from "./Group/Group";
import Hidden from "./Hidden/Hidden";
import Info from "./Info/Info";
import Item from "./Item/Item";
import ItemSimple from "./ItemSimple/ItemSimple";
import Number from "./Number/Number";
import Password from "./Password/Password";
import Questionnaire from "./Questionnaire";
import Radio from "./Radio/Radio";
import Select from "./Select/Select";
import Text from "./Text/Text";
import Textarea from "./Textarea/Textarea";

export default {
  Checkbox,
  Form,
  Group,
  Info,
  Item,
  ItemSimple,
  Number,
  Password,
  Select,
  Text,
  Textarea,
  Radio,
  Date,
  Questionnaire,
  FileInput,
  Hidden,
  RichText,
};
