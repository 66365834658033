import React from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";

import Form from "@Core/components/Form";
import { MatomoCategory } from "@Core/interfaces";
import Yup from "@Core/validation";

import Props from "./ForgotPasswordForm.props";

const ForgotPasswordForm = ({ initialValues, onSubmit, onSuccess }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email(),
  });

  return (
    <Form.Form
      identifier="FORGOT_PASSWORD_FORM"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      submit={t("LOGIN.FORGOT.CONFIRM")}
      data-matomo-category={MatomoCategory.Authentication}
    >
      <Form.Item
        id="email"
        name={t("LOGIN.FORGOT.LABEL_EMAIL")}
        render={(props) => <Form.Text {...props} icon="envelope" />}
      />

      <Form.Info
        name={t("LOGIN.FORGOT.REMEMBER_PASSWORD")}
        button={t("LOGIN.FORGOT.REMEMBER_PASSWORD_CONFIRM")}
        onClick={() => history.push("/inloggen")}
        data-matomo-category={MatomoCategory.Authentication}
        data-matomo-action={t("LOGIN.FORGOT.REMEMBER_PASSWORD")}
      />
    </Form.Form>
  );
};

export default ForgotPasswordForm;
