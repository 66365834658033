import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import { Video } from "@Core/components/Components";
import { Button } from "@Core/components/UI";

import { AssignmentInstance } from "@LNI/models";

import styles from "./Movie.module.scss";

type Props = {
  assignment: AssignmentInstance;
};

const Movie = ({ assignment }: Props) => {
  const { t } = useTranslation();

  const onUpdate = ({ played }: { played: number }) => {
    if (played > 0.75) {
      assignment.setCanComplete();
    }
  };

  const onSubmit = () => {
    toast.success(t(`ASSIGNMENT.${assignment.category}.COMPLETED`));
    assignment.setCompleted();
  };

  if (assignment) {
    return (
      <div className={styles.Video}>
        <h5>{assignment.title}</h5>

        {assignment.contents && (
          <Video url={assignment.contents} controls onProgress={(state: any) => onUpdate(state)} />
        )}

        <div className="btn-row">
          <Button onClick={onSubmit} tertiary disabled={assignment.canFinish}>
            {t(`ASSIGNMENT.${assignment.category}.DONE`)}
          </Button>
        </div>
      </div>
    );
  }

  return <></>;
};

export default observer(Movie);
