import React from "react";

import image_articles from "@Core/assets/images/illustrations/articles.png";
import { Block } from "@Core/components/UI";

import styles from "./ArticlesIntroduction.module.scss";
import { ArticlesIntroductionProps } from "./ArticlesIntroduction.props";

export const ArticlesIntroduction = ({ title, description }: ArticlesIntroductionProps) => {
  return (
    <Block className={styles.ArticlesIntroduction}>
      <div className={styles.Introduction}>
        <h1 className="color-primary mb-sm">{title}</h1>
        <p className="intro">{description}</p>
      </div>

      <div className={styles.Image}>
        <img src={image_articles} alt={title} />
      </div>
    </Block>
  );
};
