export const PREPR_MODEL = `
	_id
	_slug
	__typename
	_created_on
`;

export const PREPR_TAG = `{
	_id
	_type
	body
	slug
}`;

export const PREPR_IMAGE = (width?: number, height?: number) => {
  let assetSize = ``;

  if (width && height) {
    assetSize = `(width: ${width}, height: ${height})`;
  } else if (width) {
    assetSize = `(width: ${width})`;
  } else if (height) {
    assetSize = `(height: ${height})`;
  }

  return `{
  		_type
  		name
  		author
  		description
  		cdn_files {
    		_id
    		full: url ${assetSize}
  		}
	}
	`;
};

export const PREPR_DRAG_AND_DROP = `{
	__typename

	... on Text {
		_id
		body
		format
	}
}
`;
