import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Form from "@Core/components/Form";
import { IFieldProps } from "@Core/interfaces";
import { CompanyService } from "@Core/services";
import { useCoreStores } from "@Core/stores";
import Yup from "@Core/validation";

import { GenerateFileFormSearchProps } from "./GenerateFileFormSearch.props";

export const GenerateFileFormSearch = ({
  submit,
  onSubmit,
  onSuccess,
  isDisabled,
  type,
}: GenerateFileFormSearchProps) => {
  const { t } = useTranslation();
  const { UserStore } = useCoreStores();

  const [search, setSearch] = useState("");
  const [hasResults, setHasResults] = useState(0);
  const [users, setUsers] = useState<Record<string, string>>();

  const searchField: IFieldProps = {
    name: "search",
    value: search,
    onChange: (value: any) => {
      setSearch(value.target.value);
    },
  };

  useEffect(() => {
    const debounce = setTimeout(async () => {
      if (search) {
        const response = await CompanyService.search(UserStore.getCompanyId, search, type);

        if (response && response.length > 0) {
          setUsers(
            response.reduce((total: Record<string, string>, supervisor: any) => {
              total[supervisor.id] = `${supervisor.name} (${supervisor.email})`;
              return total;
            }, {}) || {},
          );

          setHasResults(2);
        } else {
          setUsers({});
          setHasResults(1);
        }
      } else {
        setUsers({});
        setHasResults(0);
      }
    }, 250);

    return () => clearTimeout(debounce);
  }, [search, UserStore.getCompanyId]);

  const validationSchema = Yup.object().shape({
    user_id: Yup.string().required(),
  });

  return (
    <Form.Form
      identifier="GENERATE_FILE_FORM_SEARCH_EMPLOYEE"
      validationSchema={validationSchema}
      onSubmit={({ user_id }) => onSubmit(user_id)}
      onSuccess={onSuccess}
      submit={submit || t(`FILES.GENERATE.FORM.${type}_SEARCH.SUBMIT`)}
      disabled={isDisabled}
    >
      <Form.Item
        id="search"
        name={t(`FILES.GENERATE.FORM.${type}_SEARCH.EMPLOYEE`)}
        render={() => (
          <Form.Text
            id="search"
            field={searchField}
            icon="search"
            placeholder={t(`FILES.GENERATE.FORM.${type}_SEARCH.SEARCH`)}
          />
        )}
      />

      {hasResults === 1 && <strong>{t(`FILES.GENERATE.FORM.${type}_SEARCH.SEARCH_NONE`)}</strong>}

      {hasResults === 2 && users && (
        <Form.Item
          id="user_id"
          render={(props) => (
            <Form.Select
              {...props}
              defaultValue=""
              disabled={hasResults !== 2}
              placeholder={t(`FILES.GENERATE.FORM.${type}_SEARCH.PLACEHOLDER`)}
              options={users ? users : {}}
            />
          )}
        />
      )}
    </Form.Form>
  );
};
