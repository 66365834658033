import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const BaseModel = types.model("BaseModel", {
  id: types.identifierNumber,
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
});

export interface BaseInstance extends Instance<typeof BaseModel> {}
export type BaseSnapshot = SnapshotIn<typeof BaseModel>;
