import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { Assignment } from "@LNI/components/Components";
import { Page } from "@LNI/components/Layouts";
import { useLNIStores } from "@LNI/stores";

const ChallengeAssignmentPage = () => {
  const { t } = useTranslation();
  const { challenge, assignment } = useParams();
  const history = useHistory();
  const { ChallengeStore } = useLNIStores();

  const breadcrumbs = [
    { to: "/", name: t("CHALLENGES.CHALLENGES") },
    { to: `/uitdagingen/${ChallengeStore.challenge?.id}`, name: ChallengeStore.challenge?.title },
    { name: ChallengeStore.assignment?.title },
  ];

  useEffect(() => {
    (async function load() {
      try {
        if (ChallengeStore.hasChallenges) {
          await ChallengeStore.getChallenge(challenge, assignment);
        }
      } catch (err) {
        history.push(`/cursussen/${ChallengeStore.challenge?.id}`);
      }
    })();
  }, [ChallengeStore, ChallengeStore.hasChallenges, challenge, assignment, history]);

  if (ChallengeStore.challenge && ChallengeStore.assignment) {
    return (
      <Page breadcrumbs={breadcrumbs}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Assignment assignment={ChallengeStore.assignment} />
            </div>
          </div>
        </div>
      </Page>
    );
  }

  return <></>;
};

export default observer(ChallengeAssignmentPage);
