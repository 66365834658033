import React from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import image_deprecated from "@Core/assets/images/illustrations/deprecated.jpg";
import image_question from "@Core/assets/images/illustrations/question.jpg";
import image_question_exclamation from "@Core/assets/images/illustrations/question_exclamation.jpg";
import image_security from "@Core/assets/images/illustrations/security.jpg";
import Form from "@Core/components/Form";
import { Button } from "@Core/components/UI";
import { ApiError, QuestionnaireFor } from "@Core/constants/ENUMS";
import { QuestionnaireTokenService } from "@Core/services";
import { TOKEN_STATUS } from "@Core/services/QuestionnaireTokenService";
import Yup from "@Core/validation";

import { DigitalQuestionnaire } from "@PNI/components/Containers";
import { usePNIStores } from "@PNI/stores";

import styles from "./Introduction.module.scss";

interface IntroductionProps {
  type?: QuestionnaireFor;
  onComplete?: () => void;
}

export const Introduction = ({ type, onComplete }: IntroductionProps) => {
  const { EmployeeStore } = usePNIStores();

  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [status, setStatus] = useState<TOKEN_STATUS>();
  const [smsToken, setSmsToken] = useState<string>();
  const [token, setToken] = useState("");
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    code: Yup.number().required(),
  });

  const handleValidated = ({
    status,
    phoneNumber,
    token,
  }: {
    status: TOKEN_STATUS;
    phoneNumber?: string;
    token: string;
  }) => {
    setStatus(status);
    setPhoneNumber(phoneNumber);

    // Set token when response status is valid
    if (status === TOKEN_STATUS.TOKEN_STATUS_VALID) {
      setToken(token);
    }
  };

  const handleCloseAbsence = async () => {
    try {
      await QuestionnaireTokenService.closeAbsence();

      EmployeeStore.clear();

      toast.success(t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.CLOSE_ABSENCE`));
    } catch (e) {
      toast.error(t("SHARED.ERROR"));
    }
  };

  const onSubmit = async (values: { code: string }) => {
    if (status === TOKEN_STATUS.TOKEN_STATUS_VALID && smsToken) {
      await EmployeeStore.login({
        code: values.code,
        sms_token: smsToken,
        token: token,
      });
    }
  };

  const onSendSmsCode = async () => {
    try {
      const response = await QuestionnaireTokenService.sendSmsCode(token);

      setSmsToken(response.sms_token);
      toast.success(t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_1.SUCCESS`));
    } catch (e) {
      if ((e as any)?.data.code === ApiError.VERIFICATION_CODE_COOLDOWN) {
        toast.error(t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_1.VERIFICATION_CODE_COOLDOWN`));
      } else {
        toast.error(t("SHARED.ERROR"));
      }
    }
  };

  return (
    <DigitalQuestionnaire.Introduction
      type={type}
      onValidated={handleValidated}
      status={status}
      onComplete={onComplete}
    >
      <DigitalQuestionnaire.Introduction.Step
        image={image_question}
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_1.TITLE`)}
        description={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_1.DESCRIPTION_SUPERVISOR`)}
      >
        {({ goToNextStep }) => {
          return (
            <div className={styles.Form}>
              {!!smsToken ? (
                <Form.Form
                  identifier="VALIDATION_FORM"
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  onSuccess={goToNextStep}
                  submit={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.SUBMIT`)}
                >
                  <Form.Item id="code" render={(props) => <Form.Text icon="key" {...props} />} />

                  <div>
                    <Trans
                      i18nKey={`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_1.RESEND`}
                      values={{ phoneNumber }}
                      components={{
                        button: <a className="pointer" onClick={onSendSmsCode} />,
                      }}
                    />
                  </div>
                </Form.Form>
              ) : (
                <Button tertiary block onClick={onSendSmsCode}>
                  {t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_1.SEND`)}
                </Button>
              )}
            </div>
          );
        }}
      </DigitalQuestionnaire.Introduction.Step>

      <DigitalQuestionnaire.Introduction.Step
        image={image_question_exclamation}
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_2.TITLE`)}
        description={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_2.DESCRIPTION`)}
      />

      <DigitalQuestionnaire.Introduction.Step
        image={image_question}
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_3.TITLE`)}
        description={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_3.DESCRIPTION`)}
      />

      <DigitalQuestionnaire.Introduction.Step
        image={image_security}
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_4.TITLE`)}
        description={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_4.DESCRIPTION`)}
      />

      <DigitalQuestionnaire.Introduction.Step
        image={image_deprecated}
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_5.TITLE`)}
        description={
          <Trans
            i18nKey={`DIGITAL_QUESTIONNAIRE.INTRODUCTION.STEP_5.DESCRIPTION`}
            components={{ button: <a className="pointer" onClick={handleCloseAbsence} /> }}
          />
        }
      />
    </DigitalQuestionnaire.Introduction>
  );
};
