import nl from "date-fns/locale/nl";
import { useFormikContext } from "formik";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { IFieldProps } from "@Core/interfaces";

import styles from "./Date.module.scss";

registerLocale("nl", nl);

type Props = {
  id: string;
  field: IFieldProps;

  disabled?: boolean;
  placeholder?: string;
  icon?: string;
};

const DateInput = ({ id, field, disabled, placeholder, icon }: Props) => {
  const { setFieldValue } = useFormikContext();

  return (
    <div className={styles.Inputs}>
      <DatePicker
        id={id}
        placeholderText={placeholder}
        disabled={disabled}
        locale="nl"
        dateFormat="dd-MM-yyyy"
        autoComplete="false"
        selected={(field.value && new Date(field.value)) || null}
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />

      {icon && <i className={`${styles.Icon} far fa-${icon}`}></i>}
    </div>
  );
};

DateInput.defaultProps = {
  icon: "calendar",
};

export default DateInput;
