import { observer } from "mobx-react-lite";
import React from "react";

import { Step } from "@PNI/components/Containers";

import { StepListProps } from "./StepList.props";

export const StepList = observer(({ steps, hasToggle }: StepListProps) => {
  if (steps) {
    return (
      <>
        {steps.map((step) => {
          return <Step key={step.id} step={step} hasToggle={hasToggle} />;
        })}
      </>
    );
  }

  return <></>;
});
