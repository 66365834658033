import cn from "classnames";
import React, { ReactNode } from "react";

import styles from "./StepRowButtons.module.scss";

export interface StepRowButtonsProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const StepRowButtons = ({ children, className }: StepRowButtonsProps) => {
  return <div className={cn(styles.StepRowButtons, className)}>{children}</div>;
};
