import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Questionnaire, QuestionnaireCompleted, QuestionnaireScore } from "@Core/components/Components";
import { Block, Button } from "@Core/components/UI";
import { useCoreStores } from "@Core/stores";

import { Page } from "@PNI/components/Layouts";
import { usePNIStores } from "@PNI/stores";

import { FilePageHeader } from "./internal/components";

const FileQuestionnairePage = () => {
  const { t } = useTranslation();
  const { file, questionnaire } = useParams();
  const history = useHistory();

  const { UserStore, QuestionnaireStore } = useCoreStores();
  const { FileStore } = usePNIStores();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isScores, setIsScores] = useState(false);

  const breadcrumbs = [
    {
      to: `/dossiers/${FileStore.file?.id}`,
      name: t("FILES.PAGE_TITLE", {
        id: FileStore.file?.getId,
      }),
    },
    {
      name: QuestionnaireStore.questionnaire?.questionnaire.title,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        await FileStore.getFile(file);
        await QuestionnaireStore.load(parseInt(questionnaire));

        if (QuestionnaireStore.questionnaire?.theme_scores) {
          setIsScores(true);
        }

        setIsLoaded(true);
      } catch (e) {
        history.push("");
      }
    })();
  }, [file, FileStore, QuestionnaireStore, history, questionnaire]);

  const onCompletion = () => {
    toast.success(t("FILES.COMPLETED.SUCCESS"));

    FileStore.load();
    FileStore.getFile(file, true);
  };

  const getQuestionnaire = () => {
    if (FileStore.file && QuestionnaireStore.questionnaire && UserStore.user) {
      return (
        <>
          <FilePageHeader
            title={QuestionnaireStore.questionnaire.questionnaire.title}
            description={QuestionnaireStore.questionnaire.questionnaire.information}
          />

          <div className="container">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-lg-8">
                    {QuestionnaireStore.questionnaire.isCompleted ? (
                      <>
                        <QuestionnaireCompleted title={t("FILES.INTERVENTION.COMPLETED")} />

                        {QuestionnaireStore.questionnaire.theme_scores && (
                          <Block name={t("FORMS.QUESTIONNAIRE.SCORES.SCORE")}>
                            <h4 className="mb-md">
                              {t("FORMS.QUESTIONNAIRE.SCORES.RESULTS", {
                                name: QuestionnaireStore.questionnaire.questionnaire.title,
                              })}
                            </h4>

                            <QuestionnaireScore scores={QuestionnaireStore.questionnaire.theme_scores} />

                            {isScores && (
                              <Button className="mt-md" tertiary onClick={() => setIsScores(false)}>
                                {t("FORMS.QUESTIONNAIRE.SCORES.SHOW_QUESTIONNAIRE")}
                              </Button>
                            )}
                          </Block>
                        )}

                        {!isScores && (
                          <Block name={t("FILES.QUESTIONNAIRE.SUBMITTED_ANSWERS")}>
                            <Questionnaire id={questionnaire} />
                          </Block>
                        )}
                      </>
                    ) : (
                      <Questionnaire id={questionnaire} onCompletion={() => onCompletion()} block />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return <Page breadcrumbs={breadcrumbs}>{isLoaded && getQuestionnaire()}</Page>;
};

export default observer(FileQuestionnairePage);
