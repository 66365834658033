import { EditorContentProps } from "@tiptap/react";
import cn from "classnames";
import React from "react";

import { Icon } from "@Core/components/UI";

import { LinkButton } from "./internal/components";
import styles from "./RichTextMenuBar.module.scss";

interface RichTextMenuBarProps extends EditorContentProps {}

const RichTextMenuBar = ({ className, editor }: RichTextMenuBarProps) => {
  if (!editor) {
    return null;
  }

  return (
    <div className={cn(styles.RichTextMenuBar, className)}>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={cn(styles.Button, { [styles.isActive]: editor.isActive("bold") })}
        type="button"
      >
        <Icon icon="far fa-bold" large transparent />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={cn(styles.Button, { [styles.isActive]: editor.isActive("italic") })}
        type="button"
      >
        <Icon icon="far fa-italic" large transparent />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={cn(styles.Button, { [styles.isActive]: editor.isActive("underline") })}
        type="button"
      >
        <Icon icon="far fa-underline" large transparent />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={cn(styles.Button, { [styles.isActive]: editor.isActive("bulletList") })}
        type="button"
      >
        <Icon icon="far fa-list-ul" large transparent />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={cn(styles.Button, { [styles.isActive]: editor.isActive("orderedList") })}
        type="button"
      >
        <Icon icon="far fa-list-ol" large transparent />
      </button>

      <LinkButton editor={editor} className={cn(styles.Button, { [styles.isActive]: editor.isActive("link") })} />

      <div className={styles.Settings}>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          className={styles.Button}
          type="button"
        >
          <Icon icon="far fa-undo" large transparent />
        </button>

        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          className={styles.Button}
          type="button"
        >
          <Icon icon="far fa-redo" large transparent />
        </button>
      </div>
    </div>
  );
};

export default RichTextMenuBar;
