import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Footer } from "@Core/components/Layouts";
import { QuestionnaireFor } from "@Core/constants/ENUMS";
import { QuestionnaireTokenService } from "@Core/services";

import { Header } from "@OOM/components/Layouts";
import { usePNIStores } from "@PNI/stores";

import styles from "./DigitalQuestionnaire.module.scss";
import { DigitalQuestionnaireIntroduction } from "./Introduction/DigitalQuestionnaireIntroduction";
import { DigitalQuestionnaireQuestionnaire } from "./Questionnaire/DigitalQuestionnaireQuestionnaire";

interface DigitalQuestionnaireProps {
  children: (args: { onComplete: () => void }) => React.ReactNode;
  type: QuestionnaireFor;
}

export const DigitalQuestionnaireRoot = observer(({ children, type }: DigitalQuestionnaireProps) => {
  const { EmployeeStore } = usePNIStores();
  const [hasLoaded, setHasLoaded] = useState(true);
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(false);

  useEffect(() => {
    const cleanup = () => {
      QuestionnaireTokenService.logout();
    };

    window.addEventListener("beforeunload", cleanup);

    return () => {
      cleanup();
      window.removeEventListener("beforeunload", cleanup);
    };
  }, []);

  useEffect(() => {
    // Check if a token is already set, if so check if token is correct and load user data...
    if (QuestionnaireTokenService.getToken() && !EmployeeStore.user) {
      setHasLoaded(false);

      (async () => {
        try {
          await EmployeeStore.load();
          setHasLoaded(true);
        } catch (e) {
          QuestionnaireTokenService.logout();
          setHasLoaded(true);
        }
      })();
    }
  }, [EmployeeStore, EmployeeStore.user]);

  return (
    <div className={styles.Wrapper}>
      <Header />

      {hasLoaded && (
        <>
          {hasCompletedOnboarding ? (
            <DigitalQuestionnaireQuestionnaire type={type} />
          ) : (
            children({ onComplete: () => setHasCompletedOnboarding(true) })
          )}
        </>
      )}

      <Footer />
    </div>
  );
});

export const DigitalQuestionnaire = Object.assign(DigitalQuestionnaireRoot, {
  Introduction: DigitalQuestionnaireIntroduction,
});
