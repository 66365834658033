import axios from "@Core/axios";
import { BaseService } from "@Core/services";
import { Prepr } from "@Core/utilities";

import { AssignmentCategory, AssignmentType } from "@LNI/constants/ENUMS";
import { MomentSnapshot, PreferencesSnapshot } from "@LNI/models";

class MomentService extends BaseService<any> {
  getMoment = async (id?: number): Promise<MomentSnapshot | null> => {
    let response = null;

    if (id) {
      response = await this.one(id + "");
    } else {
      response = await this.one("current");
    }

    if (response && !response.error) {
      // Ugly fix for API response
      if (response.type === AssignmentType.POLL) {
        const poll = response.poll;

        poll.answers.map((answer: any) => {
          // Get results
          answer.results = poll.pollResults[answer.id];

          // Get if user selected
          if (poll.userResults && poll.userResults[0]) {
            answer.selected = poll.userResults && poll.userResults[0].poll_answer_id === answer.id;
          } else {
            answer.selected = false;
          }

          // Format Prepr article
          answer.articles = answer.articles.map((article: any) => {
            return Prepr.format(article.contents);
          });

          return answer;
        });
      }

      if (response.type === AssignmentType.ARTICLE) {
        response.article = Prepr.format(response.article.contents);
      }

      return {
        ...response,
        order: 0,
        category: AssignmentCategory.MOMENT,
      };
    }

    return null;
  };

  getPreferences = async (): Promise<PreferencesSnapshot | null> => {
    const response = await this.one("preferences");
    return response;
  };

  setPreferences = async (data: PreferencesSnapshot): Promise<PreferencesSnapshot | null> => {
    const response = await this.update("preferences", data);
    return response;
  };

  setCompleted = async (poll?: number, answer?: number): Promise<void> => {
    let pollData = {};

    if (poll && answer) {
      pollData = {
        poll: poll,
        answer: answer,
      };
    }

    const data = {
      isDone: true,
      ...pollData,
    };

    await axios.post("moments/current", data);
  };
}

export default new MomentService("moments");
