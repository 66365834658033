import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Frame } from "@Core/components/UI";

import { RiskProfileMatrix } from "@PNI/components/Containers";
import { StepList } from "@PNI/components/HOC";
import { usePNIStores } from "@PNI/stores";

import styles from "./Insight.module.scss";
import { InsightProps } from "./Insight.props";

export const Insight = observer(({ className }: InsightProps) => {
  const { t } = useTranslation();
  const { FileStore } = usePNIStores();

  return (
    <Frame variant="light" className={className}>
      <Frame.Body padding="0" className={styles.Insight}>
        {FileStore.file && (
          <>
            <RiskProfileMatrix
              domains={FileStore.file.hasCompletedEmployeeQuestionnaire ? FileStore.file.domains : null}
              characteristics={FileStore.file.characteristics}
              className="p-smd"
            />

            {!FileStore.file.hasCompletedEmployeeQuestionnaire && (
              <div className={styles.Overlay}>
                <Frame elevated="large">
                  <Frame.Body padding="3">
                    <h3>{t("FILES.DOMAINS.EMPTY.TITLE")}</h3>

                    <Frame className="my-smd" variant="light">
                      <Frame.Body padding="2">
                        <p className="small mb-0 color-black-light">{t("FILES.DOMAINS.EMPTY.DESCRIPTION")}</p>
                      </Frame.Body>
                    </Frame>

                    {FileStore.file?.getEmployeeSteps && <StepList steps={FileStore.file.getEmployeeSteps} />}
                  </Frame.Body>
                </Frame>
              </div>
            )}
          </>
        )}
      </Frame.Body>
    </Frame>
  );
});
