import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next/";

import personal from "@Core/assets/images/illustrations/personal.png";
import { Sidebar } from "@Core/components/Layouts";
import { Frame } from "@Core/components/UI";
import { useDialog, useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";

import { FileQuestions } from "@PNI/components/Containers";

import styles from "./Profile.module.scss";
import { ProfileProps } from "./Profile.props";

export const Profile = ({ characteristics }: ProfileProps) => {
  const { isVisible, toggle } = useDialog();
  const { t } = useTranslation();
  const trackEvent = useMatomo();

  return (
    <>
      <div
        className={cn(styles.Profile, { [styles.hasClick]: characteristics })}
        onClick={() => {
          trackEvent(MatomoCategory.FileInsight, t("FILES.DOMAINS.CHARACTERISTICS"));
          toggle();
        }}
      >
        <img src={personal} alt={t("FILES.DOMAINS.CHARACTERISTICS")} />

        <span>{t("FILES.DOMAINS.CHARACTERISTICS")}</span>
      </div>

      {characteristics && (
        <Sidebar title={t("FILES.DOMAINS.CHARACTERISTICS")} isVisible={isVisible} onClose={toggle}>
          {characteristics.risk_profile && (
            <div className="mt-md">
              <h3>{characteristics.risk_profile.title}</h3>

              <p>{characteristics.risk_profile.description}</p>
            </div>
          )}

          <hr className="mb-4" />

          {characteristics.general_questions && (
            <Frame title={t("FILES.DOMAINS.GENERAL_QUESTIONS")} className="mb-md">
              <Frame.Body padding="3">
                <FileQuestions value={characteristics.general_questions} />
              </Frame.Body>
            </Frame>
          )}

          {characteristics.coping_questions && (
            <Frame title={t("FILES.DOMAINS.COPING_QUESTIONS")}>
              <Frame.Body padding="3">
                <FileQuestions value={characteristics.coping_questions} />
              </Frame.Body>
            </Frame>
          )}
        </Sidebar>
      )}
    </>
  );
};
