import React, { ReactNode } from "react";

import styles from "./Heading.module.scss";

type Props = {
  size?: string;
  children: ReactNode;
};

const Heading = ({ size = "H2", children }: Props) => {
  const HTML = `<${size.toLowerCase()} class="${styles.Heading}">${children}</${size.toLowerCase()}>`;

  return (
    <div className="row">
      <div className="col-xl-1 hidden-lg-down"></div>

      <div className="col-xl-7 col-lg-8 col-md-12" dangerouslySetInnerHTML={{ __html: HTML }}></div>
    </div>
  );
};

export default Heading;
