import React, { ReactNode } from "react";

import styles from "./Group.module.scss";

type Props = {
  children: ReactNode;
  name: string;
};

const Group = ({ children, name }: Props) => {
  return (
    <fieldset className={styles.Fieldset}>
      <legend className={styles.Title}>{name}</legend>
      <div className={styles.Inputs}>{children}</div>
    </fieldset>
  );
};

export default Group;
