import React from "react";
import { useTranslation } from "react-i18next/";

import Form from "@Core/components/Form";
import Yup from "@Core/validation";

import Props from "./ChallengesForm.props";

const ChallengesForm = ({ initialValues, onSubmit, onSuccess, challenges }: Props) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    challenges: Yup.array()
      /* eslint-disable no-template-curly-in-string */
      .min(1, 'MIN_CHALLENGES={"min":${min}}')
      /* eslint-disable no-template-curly-in-string */
      .max(3, 'MAX_CHALLENGES={"max":${max}}')
      .required(),
  });

  return (
    <Form.Form
      identifier="CHALLENGES_FORM"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      submit={t("PROFILE.FORMS.CHALLENGES.CHANGE_CHALLENGES")}
    >
      <Form.Item
        id="challenges"
        name={t("PROFILE.FORMS.CHALLENGES.SELECTED_CHALLENGES")}
        render={(props) => <Form.Checkbox {...props} options={challenges} />}
      />
    </Form.Form>
  );
};

export default ChallengesForm;
