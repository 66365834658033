import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const QuoteModel = types.model({
  id: types.identifier,
  label: types.string,
  body: types.string,
  author: types.string,
});

export interface QuoteInstance extends Instance<typeof QuoteModel> {}
export type QuoteSnapshot = SnapshotIn<typeof QuoteModel>;
