export interface IPreprRestTag {
  id: string;
  body: string;
  slug: string;
}

export enum TagGroup {
  KEYWORDS = "0a9b0a15-4dc9-4aef-bf3e-b091d6588cb7",
  THEMES = "1367d2fc-af5d-4592-b901-c4dbe3584829",
}
