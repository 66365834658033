import cn from "classnames";
import { useField } from "formik";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next/";

import styles from "./Item.module.scss";

type Props = {
  id: string;

  errorOnUntouch?: boolean;
  name?: string;
  hidden?: boolean;
  description?: string;
  render: (props: any) => ReactNode;
};

const Item = ({ id, errorOnUntouch, name, hidden, description, render }: Props) => {
  const { t } = useTranslation();
  const [field, meta] = useField(id);
  const hasError = errorOnUntouch ? true : meta.touched && meta.error;

  const errorKey = meta.error?.includes("date") ? "STRING_DATE" : meta.error;
  const errorValues = errorKey?.split("=");

  return (
    <div
      className={cn([styles.Item], {
        [styles.ItemError]: hasError,
      })}
    >
      {!hidden && (
        <label className={styles.Label} htmlFor={id}>
          {name}
        </label>
      )}
      {render({
        id: id,
        field: field,
      })}

      {description && <div className={styles.Description} dangerouslySetInnerHTML={{ __html: description }} />}

      {hasError && typeof errorValues !== "undefined" && (
        <div className={styles.Error}>
          {t(`FORMS.${errorValues[0]}`, errorValues[1] ? JSON.parse(errorValues[1]) : {})}
        </div>
      )}
    </div>
  );
};

export default Item;
