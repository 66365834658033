import { EditorContentProps } from "@tiptap/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "@Core/components/Form/Text/Text";
import { Modal } from "@Core/components/Layouts";
import { Button, Icon } from "@Core/components/UI";
import { useDialog } from "@Core/hooks";

import styles from "./RichTextLinkButton.module.scss";

interface RichTextLinkButtonProps extends EditorContentProps {}

const RichTextLinkButton = ({ className, editor }: RichTextLinkButtonProps) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState("");
  const { isVisible, toggle } = useDialog();

  if (!editor) {
    return null;
  }

  const setLink = (linkInput: string) => {
    if (linkInput) {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: linkInput })
        .command(({ tr }) => {
          const { from, to, empty } = editor.state.selection;
          const selectedText = tr.doc.textBetween(from, to, "");

          tr.insertText(empty ? linkInput : selectedText);

          return true;
        })
        .run();
    }

    toggle();
  };

  const handleRemoveLink = () => {
    setInputText("");
    editor.chain().focus().unsetLink().run();

    toggle();
  };

  return (
    <>
      <button onClick={toggle} className={className} type="button">
        <Icon icon="far fa-link" large transparent />
      </button>

      <Modal
        title={
          editor.isActive("link")
            ? t("COMPONENTS.RICH_TEXT.TITLE.UPDATE_LINK")
            : t("COMPONENTS.RICH_TEXT.TITLE.ADD_LINK")
        }
        isVisible={isVisible}
        hide={handleRemoveLink}
      >
        <Text
          id="search"
          placeholder={t("COMPONENTS.RICH_TEXT.PLACEHOLDER.LINK")}
          field={{
            name: "search",
            onChange: (e) => setInputText(e.target.value),
            value: inputText,
          }}
        />

        <div className={styles.ButtonGroup}>
          {true && (
            <Button
              onClick={handleRemoveLink}
              primary
              iconLeft="fa-times"
              disabled={!inputText && !editor.isActive("link")}
              type="button"
            >
              {t("COMPONENTS.RICH_TEXT.DELETE")}
            </Button>
          )}

          <Button onClick={() => setLink(inputText)} disabled={!inputText} tertiary type="button">
            {t("COMPONENTS.RICH_TEXT.SAVE")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RichTextLinkButton;
