import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import { Modal } from "@Core/components/Layouts";
import { Button } from "@Core/components/UI";
import { useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";
import { FileStepInstance } from "@Core/models/File/FileStepModel";

interface ReminderModalProps {
  step: FileStepInstance;
  isVisible: boolean;
  toggle: () => void;
}

export const ReminderModal = observer(({ step, isVisible, toggle }: ReminderModalProps) => {
  const { t } = useTranslation();
  const trackEvent = useMatomo();

  const handleSend = async () => {
    try {
      await step.remind();

      trackEvent(MatomoCategory.FileStep, t("FILES.MODALS.QUESTIONNAIRE_REMINDER.SUCCESS"), step.title);

      toast.success(t("FILES.MODALS.QUESTIONNAIRE_REMINDER.SUCCESS"));
    } catch (e) {
      toast.error(t("FILES.MODALS.QUESTIONNAIRE_REMINDER.ERROR"));
    } finally {
      toggle();
    }
  };

  return (
    <Modal
      title={t("FILES.MODALS.QUESTIONNAIRE_REMINDER.POPUP_TITLE")}
      isVisible={isVisible}
      hide={toggle}
      data-matomo-category={MatomoCategory.FileStep}
      data-matomo-name={step.title}
    >
      <p>{t(`FILES.MODALS.QUESTIONNAIRE_REMINDER.POPUP_DESCRIPTION.${step.file_questionnaire?.questionnaire?.for}`)}</p>

      <Button block iconLeft="fa-long-arrow-right" onClick={handleSend} tertiary>
        {t("FILES.MODALS.QUESTIONNAIRE_REMINDER.POPUP_BUTTON")}
      </Button>
    </Modal>
  );
});
