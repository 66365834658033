import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { FileQuestionModel } from "./FileQuestionModel";

const FileCharacteristicsRiskProfileModel = types.model("FileCharacteristicsRiskProfileModel", {
  title: types.string,
  description: types.string,
});

export const FileCharacteristicsModel = types.model("FileCharacteristicsModel", {
  risk_profile: types.maybeNull(FileCharacteristicsRiskProfileModel),
  general_questions: types.maybeNull(types.array(FileQuestionModel)),
  coping_questions: types.maybeNull(types.array(FileQuestionModel)),
});

export interface FileCharacteristicsInstance extends Instance<typeof FileCharacteristicsModel> {}
export type FileCharacteristicsSnapshot = SnapshotIn<typeof FileCharacteristicsModel>;
