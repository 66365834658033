import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Button, Step } from "@Core/components/UI";

import { AssignmentInstance } from "@LNI/models";

import styles from "./Article.module.scss";
import ArticlePage from "./ArticlePage/ArticlePage";

type Props = {
  assignment: AssignmentInstance;
};

const Article = ({ assignment }: Props) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [visible, setVisible] = useState(true);
  const { t } = useTranslation();

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;

    const scrollHeight = document.documentElement.scrollHeight;
    const windowHeight = document.documentElement.clientHeight;
    const scrolledHeight = currentScrollPos / (scrollHeight - windowHeight);

    if (isInViewport(document.getElementsByClassName("submit")[0])) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    if (scrolledHeight * 100 > 75 || scrollHeight === windowHeight) {
      assignment.setCanComplete();
    }

    setScrollPercentage(scrolledHeight * 100);
  }, [assignment]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const isInViewport = (element: Element) => {
    if (element) {
      const rect = element.getBoundingClientRect();

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  };

  const onSubmit = () => {
    toast.success(t(`ASSIGNMENT.${assignment.category}.COMPLETED`));
    assignment.setCompleted();
  };

  if (assignment && assignment.article) {
    return (
      <div>
        <ArticlePage article={assignment.article} />

        <div className="btn-row">
          <Button onClick={onSubmit} tertiary disabled={assignment.canFinish} className="submit">
            {t(`ASSIGNMENT.${assignment.category}.DONE`)}
          </Button>
        </div>

        {!assignment.isDone && (
          <div className={cn([styles.ReadAll], { [styles.Visible]: visible, [styles.Hidden]: !visible })}>
            <span className={styles.Title}>{t("ASSIGNMENT.ARTICLE.READ")}</span>

            <Step step={scrollPercentage} steps={100} hide={true} />

            <Button onClick={onSubmit} tertiary disabled={assignment.canFinish}>
              {t(`ASSIGNMENT.${assignment.category}.DONE`)}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return <></>;
};

export default Article;
