import cn from "classnames";
import React from "react";

import styles from "./Checkbox.module.scss";

interface CheckboxProps {
  isChecked?: boolean;
  isDisabled?: boolean;
  onToggle: () => void;
  className?: string;
}

const Checkbox = ({ isChecked = false, className, isDisabled, onToggle }: CheckboxProps) => {
  return (
    <div
      className={cn(styles.Checkbox, className, {
        [styles.isChecked]: isChecked,
        [styles.isDisabled]: isDisabled,
      })}
      onClick={isDisabled ? undefined : onToggle}
    />
  );
};

export default Checkbox;
