import React from "react";
import { Route } from "react-router";

import {
  AnswerDetailPage,
  AnswerOverviewPage,
  DashboardPage,
  ProfilePage,
  QuestionnaireInternalPage,
  QuestionnairePage,
  ScorecardPage,
} from "@OOM/pages";

export const getPrivateRoutes = (permissions: any) => {
  const routes = [
    <Route key="DP" path="/" exact component={DashboardPage} />,
    <Route key="PP" path="/mijn-profiel" exact component={ProfilePage} />,

    <Route key="/digitale-vragenlijst" path="/digitale-vragenlijst" exact component={QuestionnairePage} />,

    <Route key="QP" path="/vragenlijst/:questionnaire" exact component={QuestionnaireInternalPage} />,
    <Route key="AP" path="/antwoorden/:companyRoleId/" exact component={AnswerOverviewPage} />,
    <Route key="ADP" path="/antwoorden/:companyRoleId/vragenlijst/:questionnaire" exact component={AnswerDetailPage} />,
    <Route key="SP" path="/scorekaart/" exact component={ScorecardPage} />,
    <Route key="SP" path="/scorekaart/:companyRoleId" exact component={ScorecardPage} />,
  ];

  return routes;
};
