import merge from "lodash.merge";

import { PAGE_SIZE } from "@Core/constants/DEFAULT";
import { AppError } from "@Core/constants/ENUMS";

import { PreprGraphqlService } from "@PNI/services";

import { ArticleModule, ArticleResponse, FeaturedArticlesResponse } from "./PreprArticleService.props";
import { GET_ARTICLES } from "./PreprArticleService.queries";
import { GET_ARTICLE } from "./PreprArticleService.queries";

class PreprArticleServiceImplementation {
  public getArticle = async (slug: string) => {
    const filter = {
      slug: slug,
    };

    const response = await PreprGraphqlService.query<ArticleResponse>(GET_ARTICLE(filter));

    // Check if article has the PNI module
    if (response.data.data.Artikel.module.find((module) => module.body === ArticleModule.PNI)) {
      // Check if there are any related articles, if so filter them by the PNI module
      if (response.data.data.Artikel.related && response.data.data.Artikel.related.length > 0) {
        const relatedArticles = response.data.data.Artikel.related.filter((article) => {
          return article.module.find((module) => module.body === ArticleModule.PNI);
        });

        response.data.data.Artikel.related = relatedArticles;
      }
      return response.data.data;
    } else {
      throw new Error(AppError.ARTICLE_NOT_FOUND);
    }
  };

  public getArticles = async (customFilter?: any) => {
    const filter = {
      limit: PAGE_SIZE,
      where: {
        _tags_any: [ArticleModule.PNI],
      },
    };

    const response = await PreprGraphqlService.query<FeaturedArticlesResponse>(
      GET_ARTICLES(merge(filter, customFilter)),
    );

    return response.data.data;
  };

  public getPopular = async () => {
    const filter = {
      limit: 4,
      where: {
        most_read: true,
        _tags_any: [ArticleModule.PNI],
      },
    };

    const response = await PreprGraphqlService.query<FeaturedArticlesResponse>(GET_ARTICLES(filter));

    return response.data.data;
  };

  public getFeatured = async () => {
    const filter = {
      limit: 4,
      where: {
        featured: true,
        _tags_any: [ArticleModule.PNI],
      },
    };

    const response = await PreprGraphqlService.query<FeaturedArticlesResponse>(GET_ARTICLES(filter));

    return response.data.data;
  };
}

export const PreprArticleService = new PreprArticleServiceImplementation();
