import React, { ReactNode } from "react";

import { Icon } from "@Core/components/UI";

import styles from "./Started.module.scss";

type Props = {
  icon: string;
  title: string;
  children: ReactNode;
};

const Started = ({ icon, title, children }: Props) => {
  return (
    <div className={styles.Wrapper}>
      <h4>
        <Icon icon={`far fa-${icon}`} />

        {title}
      </h4>

      <div className={styles.Content}>{children}</div>
    </div>
  );
};

export default Started;
