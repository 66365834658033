import cn from "classnames";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, Circle } from "@Core/components/UI";
import { useMatomo } from "@Core/hooks";
import { MatomoCategory } from "@Core/interfaces";
import { formatDate } from "@Core/utilities";

import styles from "./InboxMessage.module.scss";
import { InboxMessageProps } from "./InboxMessage.props";

export const InboxMessage = ({ message, className }: InboxMessageProps) => {
  const [isOpen, setOpen] = useState(false);
  const trackEvent = useMatomo();
  const { t } = useTranslation();

  const handleToggle = useCallback(async () => {
    setOpen((oldOpen) => {
      !oldOpen && trackEvent(MatomoCategory.Dashboard, t("DASHBOARD.INBOX.TITLE"), message.title);
      return !oldOpen;
    });

    if (!message.is_read) {
      await message.read();
    }
  }, []);

  const classes = cn(styles.InboxMessage, className, {
    [styles.isOpen]: isOpen,
    [styles.isRead]: message.is_read,
  });

  return (
    <Card onClick={handleToggle} className={classes}>
      <div className={styles.Notification} />

      <div className={styles.Content}>
        <div className={styles.Heading}>
          <div>
            <h4 className={styles.Title}>{message.title}</h4>
            <div className={styles.Date}>{formatDate(message.created_at || new Date().toISOString())}</div>
          </div>

          <i className={cn(styles.Toggle, "far fa-chevron-down")} />
        </div>

        <div className={styles.Message} dangerouslySetInnerHTML={{ __html: message.message }} />
      </div>

      {isOpen && <Circle quaternary dashed x={100} y={100} r={100} />}
    </Card>
  );
};
